/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { Button, Container, GridContainer, Span } from "@common";
import { BsTrash } from "@icons";
import { useHistory } from "react-router-dom";
import { Category, Measurement } from "@components";
import { useState } from "react";
import { CheckoutModal } from "./Modal/CheckoutModal";

export const Order = () => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  const cartItems: any = useSelector((state: RootState) => state.Cart.items);
  const total: any = useSelector((state: RootState) => state.Cart.total);
  const storeId: any = useSelector(
    (state: RootState) => state.Supplier.storeId
  );

  const handleBacktoShop = () => {
    history.push(`/supplier/choose-supplier/shop/${storeId}`);
  };

  const [checkoutModal, setCheckoutModal] = useState(false);

  const handleEmptyProduct = () => {
    dispatch.Cart.cleanCart(true);
  };

  const removeItem = (product) => {
    dispatch.Cart.removeItem(product);
  };

  function handleRemoveItem(key: any, e: any) {
    e.preventDefault();
    let item = cartItems[key];
    removeItem(item);
  }

  return (
    <>
      <div>
        <Span color="grey">Supplier / Choose Supplier /</Span>
        <Span> Shop / Orders</Span>
      </div>
      <Container padding="p-4">
        {cartItems.length === 0 ? (
          <>
            <div className="m-auto text-center empty-cart">
              <div className="my-2">
                <p>
                  <Span color="orange" size="lg" weight="bold">
                    Empty Cart
                  </Span>
                </p>
                <Span size="base" weight="semi">
                  Looks like you haven't added any item yet
                </Span>
              </div>
              <Button
                width="none"
                bg="orange"
                padding="none"
                margin="none"
                onClick={handleBacktoShop}
              >
                <div className="action-button px-2 py-2 mx-2">
                  <Span margin="mx-1" color="white" weight="semi">
                    Back to Shop
                  </Span>
                </div>
              </Button>
            </div>
          </>
        ) : (
          <GridContainer grid="three">
            <div className="col-span-2">
              <div className="flex justify-between">
                <Span color="orange" size="base" weight="bold">
                  View Cart
                </Span>

                <Button
                  width="none"
                  bg="orange"
                  padding="none"
                  margin="none"
                  onClick={handleEmptyProduct}
                >
                  <div className="flex action-button px-2 py-2 mx-2">
                    <BsTrash className="my-auto text-semibold" />

                    <Span margin="mx-1" color="white" weight="semi">
                      Empty Cart
                    </Span>
                  </div>
                </Button>
              </div>

              <div className="orders-content">
                <table>
                  <thead>
                    <tr>
                      <td> Image</td>
                      <td> Item</td>
                      <td> Measurement</td>
                      <td> Price</td>
                      <td> Quantity</td>
                      <td> Subtotal</td>
                      <td></td>
                    </tr>
                  </thead>

                  <tbody>
                    {cartItems.map((orderItem, key) => (
                      <tr className="card-orders">
                        <td className="td-order-img">
                          <img
                            src={orderItem.image}
                            alt={orderItem.name}
                            className="order-img"
                          />
                        </td>
                        <td>
                          <p className="item capitalize">{orderItem.name}</p>
                          <label className="category capitalize">
                            <Category categoryId={orderItem.categoryId} />
                          </label>
                        </td>

                        <td>
                          <Measurement
                            unitMeasurementId={orderItem?.unitMeasurementId}
                            abbreviation={true}
                          />
                        </td>

                        <td>
                          <div className="flex items-end justify-center">
                            <p className="card-text">
                              {`₱${parseFloat(orderItem.price)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}{" "}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="flex items-end justify-center">
                            <p className="card-text">
                              {orderItem.quantities} pcs
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="flex justify-center">
                            <p className="card-text">
                              ₱{" "}
                              {`${parseFloat(orderItem.subtotal)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}{" "}
                            </p>
                          </div>
                        </td>
                        <td>
                          <a
                            onClick={(e) => handleRemoveItem(key, e)}
                            className="my-auto mx-2"
                          >
                            <BsTrash className="text-lg cursor-pointer" />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="order-summary">
              <p className="text-center pb-2">
                <Span color="orange" size="base" weight="bold">
                  Order Summary
                </Span>
              </p>
              <div>
                <div>
                  <div className="flex justify-between">
                    <Span size="sm" weight="semi">
                      Item
                    </Span>
                    <Span size="sm" weight="semi">
                      Subtotal
                    </Span>
                  </div>
                  {cartItems.map((orderItem, key) => (
                    <>
                      <div className="flex justify-between">
                        <p className="capitalize">
                          <Span size="sm">{orderItem?.name}</Span>
                        </p>
                        <p>
                          <Span size="sm">
                            {`${parseFloat(orderItem.subtotal)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}{" "}
                          </Span>
                        </p>
                      </div>
                    </>
                  ))}
                </div>

                <div className="order-total">
                  <Span size="sm">Total</Span>
                  <Span size="sm">
                    {`${parseFloat(total)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}{" "}
                  </Span>
                </div>

                <Button
                  bg="orange"
                  padding="none"
                  margin="none"
                  // onClick={handleOrder}

                  onClick={() => {
                    setCheckoutModal(true);
                  }}
                >
                  <div className="action-button px-2 py-2 mx-2">
                    <Span margin="mx-1" color="white" weight="semi">
                      Proceed to Checkout
                    </Span>
                  </div>
                </Button>
              </div>
            </div>
          </GridContainer>
        )}
      </Container>

      {checkoutModal && <CheckoutModal setCheckoutModal={setCheckoutModal} />}
    </>
  );
};
