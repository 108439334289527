import { get, post } from "./core";

export const authenticateViaPassword = async ({ email, password }: any) => {
  try { 
    let res = await post(`/merchant-users/sign-in`, { email, password });
    return res.data;
  } catch (err: any) {
    return Promise.reject(err); 
  }
};

export const getUserCredentials = async () => {
  try {
    let res = await get(`/merchant-users/me`);   
    return res.data;

  } catch (err) {
    throw err;
  }
};


