import { MainContainer } from '@common';
import { Navbar, SideMenu} from '@components';
import {Profile} from '@containers';
import { BRAND_IMAGES } from '@constant'

export const ProfilePage = () => {
  return (
    <>
      <Navbar title='Profile' icon={BRAND_IMAGES["HEADER_LOGO"]}/>
      <SideMenu />
      <MainContainer > 
        <Profile />
      </MainContainer>
    </>
  )
}