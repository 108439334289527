/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */
import { useLocation } from "react-router-dom";
import moment from "moment";
import CryptoJS from "crypto-js";
import { AxiosResponse } from "axios"; 
import { saveAs } from "file-saver";

const secretPass = process.env.REACT_APP_SECRET!;

export const validateEmail = (input: string) => {
  const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (re.test(input)) {
    return true;
  }
  return false;
};

export const formatMonthDay = (date) => {
  const d = new Date(date)
  const formattedDate = moment(d).format('MMM-DD')
  return formattedDate;
}

export const useQuery = () => {
  return new URLSearchParams(useLocation()?.search);
}


export const encryptData = (text: string) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    secretPass
  ).toString();

  return data;
};

export const decryptData = (text: string) => {
  const bytes = CryptoJS.AES.decrypt(text, secretPass);
  const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return data;
};


export const formatDecimalPrice = (value = 0) =>
value ? Number(parseFloat(`${value}`).toFixed(2)).toLocaleString('en') : 0;

export const formatDateV2 = (date) => {
	const d = !date?.seconds ? new Date(date) : new Date(date?.seconds * 1000);
	const formattedDate = moment(d).format('MMMM Do YYYY | h:mm:ss a') 
	return formattedDate; 
}

export const downloadExcelFile = ({
  response,
  name,
}: {
  response: AxiosResponse;
  name: string;
}) => {
  const type = response.headers['content-type'];
  const blob = new Blob([response.data], { type: type }); 
  saveAs(blob, name); 
};