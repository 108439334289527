import { useState } from "react";
import { MdVisibility, MdVisibilityOff } from "@icons";
import { Container, Inputs} from "@common" 

type Props = { 
  onChange?: React.FormEventHandler<HTMLInputElement>
  value?: string,
  placeholder?: string
  id?: string,
  maxLength?: number,
  minLength?: number
}

export const PasswordContainer = ({ onChange, value, placeholder, id, maxLength, minLength }: Props) => {
     
  const [showPassword, setshowPassword] = useState(false);

  const togglePassword = (e: any) => {
    e.preventDefault();
    setshowPassword(!showPassword);
  };
  
  return ( 
    <Container className="index" flex='wrap' bg="transparent">    
      <Inputs
        padding="left"
        border="bottom"
        placeholder={placeholder}
        value={value}  
        id={id}  
        type={showPassword ? 'text' : 'password'} 
        required
        onChange={onChange} 
        maxLength={maxLength}
        minLength={minLength}
      /> 
      <span className="bar"></span>
      <span onClick={togglePassword} className='eyes'>
        {showPassword ? <MdVisibility /> : <MdVisibilityOff /> }
      </span>  
    </Container>
  )
}