/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Span, Button, Inputs } from "@common";
import { Loader, NoData, RowSelection, TableWithPagination } from "@components";
import {
  BiSearchAlt2,
  FaShoppingCart,
  MdOutlineBookmarkBorder,
  MdKeyboardArrowRight,
  MdDelete,
} from "@icons";
import { checkSpecialChar, useQuery } from "@utils";
import { Tabs } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import "../Ecom.scss";

import { COLUMNS } from "./Table/column";
import { COLUMNS_WITH_TC } from "./Table/column-with-tc";
import { PickupLocationModal } from "./Modal/PickupLocationModal";
import { DeleteModal } from "./Modal/DeleteModal";
import { CreateModal } from "./Modal/CreateModal";

import { Dispatch } from "@store";
import { useDispatch } from "react-redux";

import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { RootState } from "@store";
import { useSelector } from "react-redux";
import { COMLETED_STATUS, ONGOING_STATUS } from "@constant";
import { syncOrder } from "@api";
import { DetailsModal } from "./Modal/DetailsModal";

let ORDER_UNSUBSCRIBE: any = null;
export const EcomOrder = () => {
  const queries = useQuery();
  const tab: any = queries.get("tab");
  const [activeTab, setActiveTab] = useState(tab);
  const { TabPane } = Tabs;
  const history = useHistory();
  const column = useMemo(() => COLUMNS, []);
  const column_with_tc = useMemo(() => COLUMNS_WITH_TC, []);
  const [pickupLocationModal, setPickupLocationModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const dispatch = useDispatch<Dispatch>();

  const [search, setSearch] = useState("");
  const isDefaultSearchValue = search?.length === 0;
  const [finalData, setFinalData] = useState([] as any);
 
  const handlePickupLocation = (e) => {
    e.preventDefault();
    setPickupLocationModal(true);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setDeleteModal(true);
  };


  const handleCreate = (e) => {
    e.preventDefault();
    setCreateModal(true);
  };

  const [ecomData, setEcomData] = useState([] as any);
  const [data, setData] = useState([] as any);

  const [isLoading, setIsLoading] = useState(false);

  const user: any = useSelector((state: RootState) => state.User.userInfo);
  const {orderData, referenceNumber}: any = useSelector((state: RootState) => state.Ecommerce);
  const db = getFirestore();
  

  const listenToOrder = async () => {
    const q = query(
      collection(db, "shopify_orders"),
      where("userId", "==", user?.userId)
    );

    ORDER_UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || []).map((ref) => {
        const data = ref.data();
        return data;
      }) as any[];
      setEcomData(listData);
    });
  };

  const setTabFilter = () => {
    if (activeTab === "new") {
      const Ecom = ecomData?.filter((s) => s?.status === "new");
      setData(Ecom);

      history.push("/ecommerce/order?tab=new");
    }
    if (activeTab === "quoted") {
      const Ecom = ecomData?.filter((s) => s?.status === "quoted");
      setData(Ecom);
      history.push("/ecommerce/order?tab=quoted");
    }

    if (activeTab === "ongoing") {
      const Ecom = ecomData?.filter((s) => ONGOING_STATUS.includes(s.status));

      setData(Ecom);
      history.push("/ecommerce/order?tab=ongoing");
    }

    if (activeTab === "completed") {
      const Ecom = ecomData?.filter((s) => COMLETED_STATUS.includes(s.status));

      setData(Ecom);
      history.push("/ecommerce/order?tab=completed");
    }
  };

  useEffect(() => {
    setTabFilter();
  }, [activeTab, ecomData]);

  useEffect(() => {
    listenToOrder();

    return function () {
      try {
        if (ORDER_UNSUBSCRIBE) ORDER_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };

  }, []);

  const setTable = () => {
    const filterData = !isDefaultSearchValue
      ? data.filter((d: any) => {
          if (search.length > 0) {
            return (
              JSON.stringify(Object.values(d)).search(new RegExp(search, "i")) >
              -1
            );
          }
        })
      : data;

    setFinalData(filterData);
  };

  useEffect(() => {
    setTable();
  }, [data, search, activeTab]);

  const handleSync = async () => {
    try {
      // setLoading(true);
      const res = await syncOrder();
      if ([200, 201].includes(res?.status)) {
        // getDeliveryData();
        // setLoading(false);
        dispatch.UI.setAlert({
          message: "Order successfully Sync",
          type: "Success",
        });
      }
    } catch (err: any) {
      // setLoading(false);
      const errMsg = err?.response?.data?.message ?? "Unable to resync order!";
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }
  };  
  

  const renderSearch = () => {
    return ( 
      <div>
        <Container className="index" flex="wrap" margin="none">
          <Inputs
            padding="left"
            border="bottom"
            placeholder="Search"
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value);
            }}
            onKeyDown={(e) => checkSpecialChar(e)}
          />
          <span className="bar"></span>

          <span className="pencil">
            <BiSearchAlt2 />
          </span>
        </Container>
      </div>
    );
  };

  return (
    <>
      <div className="flex mb-4">
        <Span>Ecommerce </Span> <MdKeyboardArrowRight />{" "}
        <Span weight="bold">Order </Span>
      </div>

      <Container padding="p-4">
        <div className="flex justify-between">
          <div>
            <Span color="orange" size="lg" weight="bold">
              Orders
            </Span>
          </div>
          <Button
            width="none"
            bg="orange"
            padding="none"
            margin="none"
            onClick={handleSync}
          >
            <div className="flex p-2">
              {/* <MdAddLocationAlt className="my-auto text-semibold" /> */}
              <Span margin="mx-1" color="white" weight="semi">
                Sync Orders
              </Span>
            </div>
          </Button>
        </div>
        <div>
          <Tabs
            defaultActiveKey={activeTab}
            onChange={(key) => setActiveTab(key)}
          >
            <TabPane tab="NEW" key="new" />
            <TabPane tab="QUOTED" key="quoted" />
            <TabPane tab="ONGOING" key="ongoing" />
            <TabPane tab="COMPLETED" key="completed" />
          </Tabs>
        </div>

        {!isLoading ? (
          <>
            {data?.length === 0 ? (
              <NoData />
            ) : (
              <>
                {activeTab === "new" && (
                  <>
                    <div className="flex justify-between">
                      <div className="flex">
                      <Button
                        width="none"
                        bg="orange"
                        padding="none"
                        margin="none"
                        onClick={handlePickupLocation}
                        disabled={
                          orderData?.orderId?.length === 0 ||
                          orderData?.orderId?.length > 5
                        }
                      >
                        <div className="flex p-2 team-addMember">
                          <MdOutlineBookmarkBorder className="my-auto text-semibold" />
                          <Span margin="mx-1" color="white" weight="semi">
                            Get Qoute
                          </Span>
                        </div>
                      </Button>

                      <Button
                        width="none"
                        bg="orange"
                        padding="none"
                        margin="mx-2"
                        onClick={handleDelete}
                        disabled={
                          orderData?.orderId?.length === 0 || orderData?.orderId?.length > 5
                        }
                      >
                        <div className="flex p-2 team-addMember">
                          <MdDelete className="my-auto text-semibold" />
                          <Span margin="mx-1" color="white" weight="semi">
                            Delete  
                          </Span>
                        </div>
                      </Button>
                      </div>

                      {renderSearch()}
                    </div>
                  </>
                )}

                {activeTab === "quoted" && (
                  <div className="flex justify-between">
                    <Button
                      width="none"
                      bg="orange"
                      padding="none"
                      margin="none"
                      onClick={handleCreate}
                      // disabled={!orderData?.orderIdReady}
                      disabled={
                        orderData?.orderId?.length === 0 || orderData?.orderId?.length > 5
                      }
                    >
                      <div className="flex p-2 team-addMember">
                        <FaShoppingCart className="my-auto text-semibold" />
                        <Span margin="mx-1" color="white" weight="semi">
                          Create orders
                        </Span>
                      </div>
                    </Button>

                    
                    {renderSearch()}
                  </div>
                )}

                {activeTab === "ongoing" || activeTab === "completed" ? (
                  <div className="flex justify-end"> 
                  {renderSearch()}
                  </div>
                ): <></>}
 

                {activeTab === "ongoing" || activeTab === "completed" ? (
                  <TableWithPagination
                    columns={column_with_tc}
                    data={finalData}
                    tableClass="table-content"
                    dataType="ecom-order"
                  />
                ) : (
                  <RowSelection
                    columns={activeTab === "new" ? column : column_with_tc}
                    data={finalData}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <div className="flex h-screen justify-center">
            <Loader />
          </div>
        )}
      </Container>

      {pickupLocationModal && (
        <PickupLocationModal setPickupLocationModal={setPickupLocationModal} />
      )}

      {createModal && <CreateModal setCreateModal={setCreateModal} />}

      
      {deleteModal && (
        <DeleteModal setDeleteModal={setDeleteModal} />
        )}


      {referenceNumber && ( 
        <DetailsModal finalData={finalData} />
      )}
    </>
  );
};
