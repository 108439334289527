import { IUserInfo } from "@types";
import { get, patch } from "./core";

export const updateUser = async (user: IUserInfo) => {
    try {
      let res = await patch(`/merchant-users/member/${user?.id}`, user);      
      return res.data; 
    } catch (err) {
      throw err;
    }
  };

  export const getUser = async (user: IUserInfo) => {
    try {
      let res = await get(`/merchant-users/${user?.id}`);   
      return res.data; 
    } catch (err) {
      throw err;
    }
  };
 