/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  getResto,
  getStore,
  getTeamMember,
  removeTeamMember,
  updateTeamMember,
} from "@api";
import { Container, GridContainer, Button, Inputs, Span } from "@common";
import { InputContainerNew, Loader } from "@components";
import { BRAND_IMAGES, STORE } from "@constant";
import { Dispatch, RootState } from "@store";
import { onlyNumberKey } from "@utils";
import { Form, Switch, Select, Input } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "../Team.scss";
import { SelectResto } from "./SelectResto";
import { SelectStore } from "./SelectStore";

export const TeamDetails = ({ id }: any) => {
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();

  const [form]: any = Form.useForm();
  const [team, setTeam] = useState({}) as any;
  const [isLoading, setisloading] = useState(false);

  const handleCancel = (e: any) => {
    e.preventDefault();
    history.push("/team");
  };

  const handleUpdate = async () => {
    setisloading(true);
    try {
      const {
        listChosenRestaurants: restaurantAccessIds,
        listChosenStores: storeAccessIds,
        ...team
      } = await form.validateFields();
      const res = await updateTeamMember(id, {
        ...team,
        contactNumber: `+63${team?.contactNumber}`,
        restaurantAccessIds,
        storeAccessIds,
        accessStatus: team.accessStatus === true ? "active" : "inactive",
      });
      if (res) {
        dispatch.UI.setAlert({
          message: "Team member successfully updated!",
          type: "Success",
        });
      }
      history("/team");
    } catch (err: any) {
      dispatch.UI.setAlert({
        message:
          err?.response?.data?.message ?? "Unable to update team member!",
        type: "Error",
      });
    }
    setisloading(false);
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setTeam({
      ...team,
      [name]: value,
    });
  };

  useEffect(() => {
    const teamdetails = {
      id: team?.id,
      firstName: team?.firstName,
      middleName: team?.middleName,
      lastName: team?.lastName,
      email: team?.email,
      contactNumber: team?.contactNumber?.replace("+63", ""),
      role: team?.role,
      accessStatus: team?.accessStatus,
    };
    setTeam(teamdetails);
    form.setFieldsValue({
      ...teamdetails,
      listChosenRestaurants: (team?.restaurantAccesses || []).map(
        (item) => item.restaurantId
      ),
      listChosenStores: (team?.storeAccesses || []).map((item) => item.storeId),
      contactNumber: team?.contactNumber?.replace("+63", ""),
      accessStatus: team.accessStatus === "active",
    });
  }, [team?.id]);

  const getTeam = async () => {
    setisloading(true);
    try {
      const res = await getTeamMember(id);
      setTeam(res);
    } catch (err) {
      console.log(err);
    }
    setisloading(false);
  };

  useEffect(() => {
    getTeam();
  }, [team?.id]);

  const handleDelete = async () => {
    try {
      const res = await removeTeamMember(id);
      if (res) {
        dispatch.UI.setAlert({
          message: "Team member successfully remove!",
          type: "Success",
        });
      }
      if (res) {
        dispatch.UI.setAlert({
          message: "Team member successfully remove!",
          type: "Success",
        });
      }
      history("/team");
    } catch (err: any) {
      console.log(err);
      dispatch.UI.setAlert({
        message:
          err?.response?.data?.message ?? "Unable to remove team member!",
        type: "Error",
      });
    }
  };

  return (
    <>
      <GridContainer>
        {!isLoading ? (
          <>
            <div>
              <Form onFinish={handleUpdate} layout="vertical" form={form}>
                <Container padding="p-4" margin="none">
                  <Container margin="none">
                    <img
                      src={BRAND_IMAGES["DEFAULT_ICON"]}
                      alt="AEG Logo"
                      className="default-icon"
                    />

                    <InputContainerNew icon={true} label="First Name">
                      <Form.Item
                        name="firstName"
                        rules={[{ required: true }]}
                        className="w-full"
                      >
                        <Inputs
                          padding="left"
                          border="bottom"
                          name="firstName"
                          type="text"
                          transform="capitalize"
                          onChange={onChange}
                        />
                      </Form.Item>
                    </InputContainerNew>

                    <InputContainerNew icon={true} label="Middle Name">
                      <Form.Item
                        name="middleName"
                        rules={[{ required: true }]}
                        className="w-full"
                      >
                        <Inputs
                          padding="left"
                          border="bottom"
                          name="middleName"
                          type="text"
                          transform="capitalize"
                          onChange={onChange}
                        />
                      </Form.Item>
                    </InputContainerNew>

                    <InputContainerNew icon={true} label="Last Name">
                      <Form.Item
                        name="lastName"
                        rules={[{ required: true }]}
                        className="w-full"
                      >
                        <Inputs
                          padding="left"
                          border="bottom"
                          name="lastName"
                          type="text"
                          transform="capitalize"
                          onChange={onChange}
                        />
                      </Form.Item>
                    </InputContainerNew>

                    <InputContainerNew icon={true} label="Mobile">
                      <div className="flex w-full">
                        <span className="absolute border-b border-grey-2 py-2 text-xs">
                          (+63)
                        </span>
                        <Form.Item
                          name="contactNumber"
                          rules={[{ required: true }]}
                          className="w-full"
                        >
                          <Inputs
                            padding="both"
                            border="bottom"
                            name="contactNumber"
                            type="phone"
                            onChange={onChange}
                            maxLength={10}
                            onKeyDown={onlyNumberKey}
                          />
                        </Form.Item>
                      </div>
                    </InputContainerNew>

                    <InputContainerNew icon={false} label="Email">
                      <Form.Item
                        name="email"
                        rules={[{ required: true }]}
                        className="w-full"
                      >
                        <Inputs
                          padding="left"
                          border="bottom"
                          name="email"
                          type="email"
                          disabled
                        />
                      </Form.Item>
                    </InputContainerNew>

                    <InputContainerNew icon={false} label="Role">
                      <Form.Item name="role" className="w-full">
                        <Inputs
                          padding="left"
                          border="bottom"
                          name="role"
                          type="text"
                          transform="capitalize"
                          disabled
                        />
                      </Form.Item>
                    </InputContainerNew>

                    <SelectResto />
                    <SelectStore />

                    <div className="flex justify-between border border-grey-2 my-2 mx-2 p-4 rounded">
                      <Span color="grey" margin="my-auto">
                        Member Status
                      </Span>
                      <Form.Item
                        valuePropName={
                          team?.accessStatus === "active" ? "checked" : ""
                        }
                        name="accessStatus"
                        className="mb-0"
                      >
                        <Switch
                          checkedChildren="Active"
                          unCheckedChildren="Inactive"
                        />
                      </Form.Item>
                    </div>
                  </Container>
                </Container>
              </Form>

              <div className="flex justify-between">
                <Button
                  bg="white"
                  color="grey"
                  width="none"
                  padding="px-6"
                  onClick={handleDelete}
                >
                  Remove Member
                </Button>

                <div className="flex justify-end">
                  <Button
                    bg="white"
                    color="grey"
                    width="none"
                    margin="m-4"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    bg="orange"
                    width="none"
                    padding="px-6"
                    onClick={form.submit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex justify-center bg-white p-44">
            <Loader />
          </div>
        )}
      </GridContainer>
    </>
  );
};
