/* eslint-disable react-hooks/exhaustive-deps */
import { getListPickupLocation, getQoute, getVehicleType } from "@api";
import { Button, ModalContainer, Span } from "@common";
import { InputContainerNew } from "@components";
import { Dispatch, RootState } from "@store";
import { Form, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const { Option } = Select;
type Props = {
  setPickupLocationModal: Function;
};

export const PickupLocationModal = ({ setPickupLocationModal }: Props) => {
  const [form]: any = Form.useForm();
  const dispatch = useDispatch<Dispatch>();

  const [qoutePickup, setQoutePickup] = useState() as any;
  const [addressData, setAddressData] = useState([] as any);

  const user: any = useSelector((state: RootState) => state.User.userInfo);
  const orderData = useSelector((state: RootState) => state.Ecommerce.orderId);

  const handleQoute = async () => {
    try {
      const res = await getQoute({ ...qoutePickup, orderIds: orderData });
      if (res) {
        dispatch.UI.setAlert({
          message: "Order successfuly qouted!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "Unable assign order!",
        type: "Error",
      });
    }
    dispatch.Ecommerce.updateState({ orderId: [], orderIdReady: 0 });
    setPickupLocationModal(false);
  };

  const getPickupLocation = async () => {
    try {
      const res = await getListPickupLocation();
      const pickupData = res[0]?.filter((i) => i?.userId === user?.userId);
      setAddressData(pickupData);
    } catch (err) {
      console.log(err);
    }
  };

  const [vehicleDetails, setVehicleDetails] = useState([] as any);
  const getVehicle = async () => {
    try {
      const res = await getVehicleType();
      const activeVehicle = res.filter((d: any) => d.portalEnabled === true);
      setVehicleDetails(activeVehicle);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPickupLocation();
  }, []);

  useEffect(() => {
    getVehicle();
  }, []);

  return (
    <>
      <ModalContainer>
        <div className="p-10 py-5 whitespace-normal text-center ">
          <div className="mx-auto my-4 ">
            <Span color="orange" size="base" weight="semi">
              Qoute
            </Span>
          </div>

          <Form layout="vertical" form={form}>
            <InputContainerNew icon={false} label="Address" required={true}>
              <Form.Item
                name="address"
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Address is required",
                  },
                ]}
              >
                <Select
                  className="w-full"
                  placeholder="Address"
                  onChange={(v: any) =>
                    setQoutePickup({ ...qoutePickup, pickupLocationId: v })
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {addressData?.map((type, index) => (
                    <Option key={index} value={type.id}>
                      {type.address}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </InputContainerNew>

            <InputContainerNew icon={false} label="Bill To" required={true}>
              <Form.Item
                name="billTo"
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Bill to is required",
                  },
                ]}
              >
                <Select
                  className="w-full"
                  placeholder="Bill to"
                  onChange={(v: any) =>
                    setQoutePickup({ ...qoutePickup, billTo: v })
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {user?.customer?.billings?.map((bill, index) => (
                    <Option key={index} value={bill.email}>
                      {bill.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </InputContainerNew>

            <InputContainerNew
              icon={false}
              label="Vehicle Type"
              required={true}
            >
              <Form.Item
                name="vehicleType"
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Vehicle Type is required",
                  },
                ]}
              >
                <Select
                  className="w-full"
                  placeholder="Vehicle Type"
                  onChange={(v: any) =>
                    setQoutePickup({ ...qoutePickup, vehicleType: v })
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {vehicleDetails.map((vehicle, index) => (
                    <Option key={index} value={vehicle.code}>
                      <Span>{vehicle.name} </Span>
                      <Span color="grey">
                        (up to {vehicle.kgCapacity} kg or {vehicle.cmCapacity}{" "}
                        cm)
                      </Span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </InputContainerNew>
            <Form.Item
              name="notes"
              label="Notes"
              className="w-full"
              rules={[
                {
                  required: true,
                  message: "Vehicle Type is required",
                },
              ]}
            >
              <TextArea
                placeholder="Notes"
                onChange={(v: any) =>
                  setQoutePickup({
                    ...qoutePickup,
                    notes: v.target.value.trim(),
                  })
                }
              />
            </Form.Item>

            <div className="flex justify-end">
              <Button
                bg="white"
                color="grey"
                width="none"
                margin="m-4"
                onClick={() => setPickupLocationModal(false)}
              >
                Cancel
              </Button>
              <Button
                bg="orange"
                width="none"
                padding="px-6"
                onClick={handleQoute}
              >
                Get Qoute
              </Button>
            </div>
          </Form>
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
