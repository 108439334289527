/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { getTeamMember, updateUser } from "@api";
import { Container, GridContainer, Button, Inputs } from "@common";
import { InputContainerNew, Loader } from "@components";
import { Dispatch, RootState } from "@store";
import { Form } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChangePasswordModal } from "./Modal/ChangePasswordModal";

export const Profile = () => {
  const dispatch = useDispatch<Dispatch>();

  const user: any = useSelector((state: RootState) => state.User.userInfo);

  const [changePassModal, setChangePasswordModal] = useState(false);
  const handleChangePassword = (e) => {
    e.preventDefault();
    setChangePasswordModal(true);
  };

  const [isLoading, setisloading] = useState(false);
  const [isEdit, setEdit] = useState(false);

  // Value
  const getData = async () => {
    setisloading(true);
    const usersData = await getTeamMember(user?.id);
    setUserDetails(usersData);
    setisloading(false);
  };

  const handleUpdate = async () => {
    try {
      setisloading(true);
      const res = await updateUser(userDetails);
      if (res) {
        dispatch.UI.setAlert({
          message: "User updated!",
          type: "Success",
        });
        setEdit(false);
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "Unable update user!",
        type: "Error",
      });
    }
    setisloading(false);
  };

  const [form]: any = Form.useForm();
  const [userDetails, setUserDetails] = useState({}) as any;

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    setEdit(false);
    window.location.reload();
  };

  const handleEdit = (e: any) => {
    e.preventDefault();
    setEdit(true);
  };

  useEffect(() => {
    const details = {
      id: userDetails?.id,
      firstName: userDetails?.firstName,
      middleName: userDetails?.middleName,
      lastName: userDetails?.lastName,
      email: userDetails?.email,
    };
    setUserDetails(details);
    form.setFieldsValue(details);
  }, [userDetails?.id]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <GridContainer>
        {!isLoading ? (
          <Form scrollToFirstError layout="vertical" form={form}>
            <Container padding="p-4">
              <InputContainerNew icon={false} label="First Name">
                <Form.Item
                  name="firstName"
                  rules={[{ required: true }]}
                  className="w-full"
                >
                  <Inputs
                    padding="left"
                    border="bottom"
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    transform="capitalize"
                    onChange={onChange}
                    disabled={isEdit ? false : true}
                  />
                </Form.Item>
              </InputContainerNew>

              <InputContainerNew icon={false} label="Middle Name">
                <Form.Item
                  name="middleName"
                  rules={[{ required: true }]}
                  className="w-full"
                >
                  <Inputs
                    padding="left"
                    border="bottom"
                    name="middleName"
                    type="text"
                    placeholder="Middle Name"
                    transform="capitalize"
                    onChange={onChange}
                    disabled={isEdit ? false : true}
                  />
                </Form.Item>
              </InputContainerNew>

              <InputContainerNew icon={false} label="Last Name">
                <Form.Item
                  name="lastName"
                  rules={[{ required: true }]}
                  className="w-full"
                >
                  <Inputs
                    padding="left"
                    border="bottom"
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    transform="capitalize"
                    onChange={onChange}
                    disabled={isEdit ? false : true}
                  />
                </Form.Item>
              </InputContainerNew>

              <InputContainerNew icon={false} label="Email">
                <Form.Item
                  name="email"
                  rules={[{ required: true }]}
                  className="w-full"
                >
                  <Inputs
                    padding="left"
                    border="bottom"
                    name="email"
                    type="email"
                    placeholder="Email"
                    disabled
                  />
                </Form.Item>
              </InputContainerNew>
            </Container>

            <div className="flex justify-between">
              <Button
                bg="white"
                color="grey"
                width="none"
                padding="px-6"
                onClick={handleChangePassword}
              >
                Change Password
              </Button>
              <div>
                {isEdit ? (
                  <>
                    <Button
                      bg="white"
                      color="grey"
                      width="none"
                      margin="m-4"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      bg="orange"
                      width="none"
                      padding="px-6"
                      onClick={handleUpdate}
                    >
                      Save
                    </Button>{" "}
                  </>
                ) : (
                  <Button
                    bg="orange"
                    width="none"
                    padding="px-6"
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>
                )}
              </div>
            </div>
          </Form>
        ) : (
          <div className="flex justify-center bg-white p-44">
            <Loader />
          </div>
        )}
      </GridContainer>

      {changePassModal && (
        <ChangePasswordModal setChangePasswordModal={setChangePasswordModal} />
      )}
    </>
  );
};
