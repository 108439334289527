/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { Stack } from "@mui/material";
import { Button, Span } from "@common";

import { useState } from "react";
import { DeleteModal } from "../Modal/DeleteModal";
import { EditModal } from "../Modal/EditModal";

const StatusSupplier = ({ id }: any) => {
  const [editModals, setEditModals] = useState(false);
  const [deleteModals, setDeleteModals] = useState(false);
  const handleEdit = (e) => {
    e.preventDefault();
    setEditModals(true);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setDeleteModals(true);
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Button
          bg="green"
          width="none"
          margin="none"
          padding="px-6"
          onClick={handleEdit}
        >
          Edit
        </Button>
        <Button bg="red" width="none" margin="m-4" onClick={handleDelete}>
          Delete
        </Button>
      </Stack>

      {deleteModals && (
        <DeleteModal setDeleteModals={setDeleteModals} id={id} />
      )}

      {editModals && <EditModal setEditModals={setEditModals} id={id} />}
    </>
  );
};

export default StatusSupplier;
