import { Select, Span } from "@common";

type Props = {   
    children: React.ReactNode, 
    title?: string,
    select?: boolean
    data?: any
}

export const CardContent = ({ title, children, data, select }: Props) => { 
    return ( 
        <div className="mt-4 relative p-1 rounded-md bg-white"> 
            <div className="flex justify-between">
                <div className="m-1 px-1.5">
                    <Span margin="my-2" color="maroon" weight="semi" size="base">{title}</Span>
                </div> 

                {select &&
                    <Select className="ml-6">
                    {data?.map((type, index) => (
                        <option key={index} value={type.value}>
                        {type.name}
                        </option>
                    ))}
                    </Select>
                }
            </div>
            
            {children} 
        </div>
    );
}   
