
export const COLUMNS = [
    {
        accessor: 'id',
        Header: 'Id', 
        isVisible: true, 
        Cell: data => {
            const d = data?.row?.original;
            return ( 
            <div className='flex justify-center'>
                <span className="text-center">{d?.id}</span> 
            </div>
        )}
    },
    {
        accessor: 'name',
        Header: 'Name', 
        isVisible: true,
        Cell: data => {
            const d = data?.row?.original;
            return (
                <div className='flex justify-center'>
                    <span className="text-center capitalize">{`${d?.firstName} ${d?.lastName} ${d?.middleName}`}</span>
                </div>
        )}
    },     
    {
        accessor: 'email',
        Header: 'Email', 
        isVisible: true, 
        Cell: data => {
            const d = data?.row?.original;
            return ( 
            <div className='flex justify-center'>
                <span className="text-center">{d?.email}</span> 
            </div>
        )}
    },
    {
        accessor: 'contactNumber',
        Header: 'Mobile No.', 
        isVisible: true,
        Cell: data => {
            const d = data?.row?.original;
            return ( 
            <div className='flex justify-center'>
                <span className="text-center">{d?.contactNumber}</span> 
            </div>
        )}
    },
    {
        accessor: 'role',
        Header: 'Role', 
        isVisible: true, 
        Cell: data => {
            const d = data?.row?.original;
            return ( 
            <div className='flex justify-center'>
                <span className="text-center capitalize">{d?.role}</span> 
            </div>
        )}
    },  
]