import { IUserInfo } from "@types";
import { get, patch, post, remove } from "./core";

export const getTeam = async (restaurantID?: string) => {
    try {    
        let res = await get(`/merchant-users/member?restaurantId=${restaurantID}`);    
        return res.data.results;
    } catch (err) {
      throw err;
    }
};

export const updateTeamMember = async (id:string, team: IUserInfo) => {
  try { 
    let res = await patch(`/merchant-users/member/${id}`, team);   
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const addTeamMember = async (team: IUserInfo) => {
  try {
    let res = await post(`/merchant-users/member`, team);  
    return res.data; 
  } catch (err) {
    throw err;
  }
};

export const getTeamMember = async (id:string) => {
  try {
    let res = await get(`/merchant-users/member/${id}`);   
    return res.data; 
  } catch (err) {
    throw err;
  }
};


export const removeTeamMember = async (id:string) => {
  try {
    let res = await remove(`/merchant-users/member/${id}`);  
    return res.data; 
  } catch (err) {
    throw err;
  }
};

export const getTeamMemberByUser = async (id:string) => {
  try {
    let res = await get(`/merchant-users/member/find-by-user-id/${id}`); 
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const getResto = async () => {
  try {
    let resto = await get(`/merchant-users/member/dependencies/restaurants-for-user`);  
    return resto.data; 
  } catch (err) {
    throw err;
  }
};

export const getStore = async () => {
  try {
    let resto = await get(`/merchant-users/member/dependencies/stores-for-user`);  
    return resto.data; 
  } catch (err) {
    throw err;
  }
}; 
