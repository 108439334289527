/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Container,
  GridContainer,
  Inputs,
  Span,
  QuantityButton,
  QuantityContainer,
  QuantityInput,
} from "@common";
import { useEffect, useState } from "react";
import { BiPlus, BiSearchAlt2, IoMdArrowRoundBack } from "@icons";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { useHistory } from "react-router-dom";
import { Switch } from "antd";
import { FaShoppingCart } from "@icons";

import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { Category, Measurement, NoData } from "@components";
let PRODUCT_UNSUBSCRIBE: any = null;
export const Shop = ({ id }: any) => {
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();

  const [search, setSearch] = useState("");
  const [productData, setProductData] = useState([] as any);
  const [shopData, setShopData] = useState([] as any);
  const [view, setView] = useState(false);
  const isDefaultSearchValue = search?.length === 0;

  const cartItems: any = useSelector((state: RootState) => state.Cart.items);
  const total: any = useSelector((state: RootState) => state.Cart.total);

  const storeName: any = useSelector(
    (state: RootState) => state.Supplier.storeName
  );

  const db = getFirestore();
  const listenToProduct = async () => {
    const q = query(
      collection(db, "store_supplier_product"),
      where("storeId", "==", id)
    );

    PRODUCT_UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || []).map((ref) => {
        const data = ref.data();
        return data;
      }) as any[];
      setShopData(listData);
    });
  };

  useEffect(() => {
    listenToProduct();

    return function () {
      try {
        if (PRODUCT_UNSUBSCRIBE) PRODUCT_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };
  }, []);

  const setTable = () => {
    const data = !isDefaultSearchValue
      ? shopData.filter((d: any) => {
          if (search.length > 0) {
            return (
              JSON.stringify(Object.values(d)).search(new RegExp(search, "i")) >
              -1
            );
          }
        })
      : shopData;

    setProductData(data);
  };

  useEffect(() => {
    setTable();
  }, [shopData, search]);

  // Cart
  const handleAddProduct = (product) => {
    product.quantities = 1;
    dispatch.Cart.addItem(product);
  };

  const updateItem = (product) => {
    dispatch.Cart.updateItem(product);
  };

  const removeItem = (product) => {
    dispatch.Cart.removeItem(product);
  };

  const [count, setCount] = useState(0);
  // function handleQuantity(e) {
  //   e.preventDefault();
  //   setCount(count);
  // }

  function handleQuantity(key: any, e: any) {
    const inputValue = e.target.value;
    let item = productData[key];

    if (
      e.nativeEvent.inputType === "deleteContentBackward" &&
      (inputValue === "" || inputValue === "0" || /^0+/.test(inputValue))
    ) {
      item.quantities = 0;
      removeItem(item);
    } else {
      const newQuantity = Math.max(0, parseInt(inputValue, 10)) || 0;

      item.quantities = newQuantity;
    }

    updateItem(item);
  }

  function handlePlus(key: any) {
    let item = productData[key];
    item.quantities = item.quantities + 1;
    updateItem(item);
  }

  function handleMinus(key: any) {
    let item = productData[key];
    item.quantities = item.quantities - 1;
    if (item.quantities <= 0) {
      item.quantities = 0;
      removeItem(item);
    } else {
      updateItem(item);
    }
  }

  const handleOrder = () => {
    history.push(`/supplier/choose-supplier/shop/${id}/order`);
  };

  const handleBack = () => {
    history.push(`/supplier/choose-supplier`);
  };

  return (
    <>
      <div className="flex">
        <div onClick={handleBack} className="link-supplier">
          <Span color="grey">Supplier / Choose Supplier /</Span>
        </div>
        <div className="pl-1">
          <Span> Shop </Span>
        </div>
      </div>
      <Container padding="p-4">
        <div className="flex justify-between">
          <div>
            <Button
              width="none"
              bg="orange"
              padding="none"
              margin="none"
              onClick={handleBack}
            >
              <div className="flex p-2 team-addMember">
                <IoMdArrowRoundBack className="my-auto text-semibold" />
                <Span margin="mx-1" color="white" weight="semi">
                  Back
                </Span>
              </div>
            </Button>
            <Span color="orange" size="lg" weight="bold" margin="mx-2">
              {storeName}
            </Span>
          </div>
          {productData?.length ? (
            <div>
              <Button
                width="none"
                bg="orange"
                padding="none"
                margin="none"
                onClick={handleOrder}
                disabled={cartItems?.length === 0}
              >
                <div className="flex p-2 team-addMember">
                  <FaShoppingCart />
                  <div className="flex m-auto">
                    <Span margin="mx-1" color="white" weight="semi">
                      Cart - {cartItems?.length ?? ""} Item(s) - Total of ₱
                      {total}
                    </Span>
                  </div>
                </div>
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="shop">
          {!productData?.length ? (
            <NoData />
          ) : (
            <>
              <div className="flex justify-between">
                <div>
                  <Container className="index" flex="wrap" margin="none">
                    <Inputs
                      padding="left"
                      placeholder="Search"
                      value={search}
                      onChange={(e: any) => {
                        setSearch(e.target.value);
                      }}
                    />
                    <span className="bar"></span>

                    <span className="pencil">
                      <BiSearchAlt2 />
                    </span>
                  </Container>
                </div>

                <Switch
                  checkedChildren="List View"
                  unCheckedChildren="Grid View"
                  onChange={(v) => setView(v)}
                />
              </div>
              {view ? (
                <div className="orders-content">
                  <table>
                    <thead>
                      <tr>
                        <td> Image</td>
                        <td> Item</td>
                        <td> Price</td>
                        <td> Measurement</td>
                        <td> Action</td>
                      </tr>
                    </thead>

                    <tbody>
                      {productData.map((product, key) => {
                        for (const key in cartItems) {
                          let obj = cartItems[key];

                          if (product.id === obj.id) {
                            product.quantities = obj.quantities;
                          }
                        }
                        return (
                          <>
                            {product?.status !== "inactive" &&
                              product?.quantity !== 0 && (
                                <tr className="card-orders">
                                  <td className="td-order-img">
                                    <img
                                      src={product.image}
                                      alt={product.name}
                                      className="order-img"
                                    />
                                  </td>
                                  <td>
                                    <div className="flex flex-col items-center justify-center">
                                      <p className="item capitalize">
                                        {product.name}
                                      </p>
                                      <label className="category capitalize">
                                        <Category
                                          categoryId={product.categoryId}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="flex items-end justify-center">
                                      <p className="card-text">
                                        ₱{" "}
                                        {`${parseFloat(product.price)
                                          .toFixed(2)
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}`}{" "}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <Measurement
                                      unitMeasurementId={
                                        product?.unitMeasurementId
                                      }
                                      abbreviation={true}
                                    />
                                  </td>
                                  <td>
                                    <div className="flex items-end justify-center">
                                      {product.quantities >= 1 && (
                                        <div className="flex justify-center">
                                          <QuantityContainer
                                            bg="grey"
                                            margin="none"
                                          >
                                            <QuantityButton
                                              onClick={() => handleMinus(key)}
                                            >
                                              -
                                            </QuantityButton>
                                            <QuantityInput
                                              width="eight"
                                              color="white"
                                              type="text"
                                              value={product.quantities}
                                              onChange={(e) =>
                                                handleQuantity(key, e)
                                              }
                                            />
                                            <QuantityButton
                                              onClick={() => handlePlus(key)}
                                            >
                                              +
                                            </QuantityButton>
                                          </QuantityContainer>
                                        </div>
                                      )}
                                      {!product.quantities && (
                                        <Button
                                          width="none"
                                          bg="orange"
                                          padding="none"
                                          margin="none"
                                          onClick={() =>
                                            handleAddProduct(product)
                                          }
                                        >
                                          <div className="flex action-button py-1 mx-2">
                                            <BiPlus className="my-auto text-semibold" />
                                            <Span
                                              margin="mx-1"
                                              color="white"
                                              weight="semi"
                                            >
                                              Add to Cart
                                            </Span>
                                          </div>
                                        </Button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <GridContainer grid="four">
                  {productData.map((product, key) => {
                    for (const key in cartItems) {
                      let obj = cartItems[key];

                      if (product.id === obj.id) {
                        product.quantities = obj.quantities;
                      }
                    }

                    let outOfStock = product.quantities >= product.quantity;
                    return (
                      <>
                        {product?.status !== "inactive" &&
                          product?.quantity !== 0 && (
                            <div className="card-shop">
                              <img
                                src={product.image}
                                alt={product.name}
                                className="product-img"
                              />
                              <div className="my-1">
                                <p className="item">{product.name}</p>
                                <div className="flex justify-between items-end">
                                  <label className="category">
                                    <Category categoryId={product.categoryId} />
                                  </label>
                                  <label className="red">
                                    <b>{outOfStock ? "Stock Limit" : ""}</b>
                                  </label>
                                </div>
                              </div>

                              <div className="flex justify-between items-end">
                                <div className="flex items-end">
                                  <p className="card-text">
                                    {`₱${parseFloat(product.price)
                                      .toFixed(2)
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}`}{" "}
                                  </p>
                                  <label className="unit"> / </label>
                                  <label className="unit">
                                    <Measurement
                                      unitMeasurementId={
                                        product?.unitMeasurementId
                                      }
                                      abbreviation={false}
                                    />
                                  </label>
                                </div>
                                {product.quantities >= 1 && (
                                  <>
                                    <div className="flex justify-center">
                                      <QuantityContainer margin="none">
                                        <QuantityButton
                                          onClick={() => handleMinus(key)}
                                        >
                                          -
                                        </QuantityButton>
                                        <QuantityInput
                                          color="white"
                                          type="text"
                                          value={product.quantities}
                                          onChange={(e) =>
                                            handleQuantity(key, e)
                                          }
                                        />
                                        <QuantityButton
                                          onClick={() => handlePlus(key)}
                                          disabled={outOfStock}
                                        >
                                          +
                                        </QuantityButton>
                                      </QuantityContainer>
                                    </div>
                                  </>
                                )}
                                {!product.quantities && (
                                  <Button
                                    width="none"
                                    bg="orange"
                                    padding="none"
                                    margin="none"
                                    onClick={() => handleAddProduct(product)}
                                  >
                                    <div className="flex action-button py-1 mx-2">
                                      {/* <BiPlus className="my-auto text-semibold" /> */}
                                      <Span
                                        margin="mx-1"
                                        color="white"
                                        weight="semi"
                                      >
                                        Add to Cart
                                      </Span>
                                    </div>
                                  </Button>
                                )}
                              </div>
                            </div>
                          )}
                      </>
                    );
                  })}
                </GridContainer>
              )}
            </>
          )}
        </div>
      </Container>
    </>
  );
};
