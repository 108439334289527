/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  MdStoreMallDirectory,
  MdDashboard,
  IoMdDownload,
  RiCalendarEventFill,
} from "@icons";
import { Select, Span, GridContainer } from "@common";
import {
  CardContent,
  IconContainer,
  Loader,
  TabContent,
  NoData,
} from "@components";
import { DAYS, SERVICES } from "@constant";
import React, { useEffect, useState } from "react";

import {
  Transactions,
  TransactionsContent,
  Commission,
  CommissionContent,
  NetSales,
  FooterSalesOverview,
  NetSalesContent,
} from "@salesoverview";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { getSalesList } from "@api";
import { DatePicker } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const SalesOverview = () => {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch<Dispatch>();

  // Tab
  const [active, setActive] = useState("Transactions");
  const items = [
    { name: "Transactions" },
    { name: "Net Sales" },
    { name: "AllEasy Go Commission" },
  ];

  const sales = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const restaurant = useSelector(
    (state: RootState) => state.User.userInfo?.restaurantAccesses
  );

  const store = useSelector(
    (state: RootState) => state.User.userInfo?.storeAccesses
  );

  const [salesData, setSalesData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);

  const { filter, services, days, dateRange } = useSelector(
    (state: RootState) => state.SalesOverview
  );

  const now = moment();
  var today = moment()
    .add(-days + 1, "days")
    .startOf("day")
    .toDate()
    .toISOString();
  const sign = !services ? `?` : `&`;
  const startTime = `${sign}startTime=${today}`;

  const getSales = async () => {
    setIsLoading(true);
    try {
      const filters = !services ? "" : `?type=${services}${filter}`;
      const dateFilter = !dateRange ? startTime : dateRange;
      const res = await getSalesList(filters, dateFilter);
      setSalesData(res);
    } catch (err: any) {
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!services) {
      dispatch.SalesOverview.updateState({
        filter: "",
        services: "",
      });
    }

    getSales();
  }, [filter, services, days, dateRange]);

  const user: any = useSelector((state: RootState) => state.User.userInfo);
  const isNewLogin = useSelector((state: RootState) => state.UI.isNewLogin);

  useEffect(() => {
    if (isNewLogin) {
      // dispatch.UI.setAlert({ message: user?.email, type: "Success" });
      dispatch.UI.updateState({ isNewLogin: false });
      dispatch.Team.updateState({
        restaurantId: user?.restaurantAccesses?.[0]?.restaurantId,
      });
    }
  }, [isNewLogin]);

  const exportPDF = () => {
    const input = document.getElementById("SalesOverviewPDF")!;
    html2canvas(input, {
      logging: true,
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      const imgWidth = 200;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 5, 5, imgWidth, imgHeight);
      pdf.save(`SalesOverview_${now.format("MM/DD/YYYY")}.pdf`);
    });
  };

  return (
    <>
      <div className="px-6" ref={sales}>
        <div className="sales-categories">
          <div className="services">
            <IconContainer icon={<MdDashboard className="services-icon" />}>
              <Select
                className="ml-6 w-full"
                value={services}
                onChange={(e: any) => {
                  dispatch.SalesOverview.updateState({
                    services: e.target.value,
                  });
                }}
              >
                {SERVICES?.map((type, index) => (
                  <option key={index} value={type?.value}>
                    {type.name}
                  </option>
                ))}
              </Select>
            </IconContainer>
          </div>

          <div className="store">
            <IconContainer
              icon={<MdStoreMallDirectory className="store-icon" />}
            >
              <Select
                className="ml-6 w-full"
                onChange={(e: any) => {
                  dispatch.SalesOverview.updateState({
                    filter: e.target.value,
                  });
                }}
                value={filter}
              >
                <option value="" className="capitalize">
                  All Store
                </option>
                {services === "food" && (
                  <>
                    {restaurant?.map((resto: any) => (
                      <option
                        key={resto?.restaurant?.id}
                        value={`&restaurantId=${resto?.restaurant?.id}`}
                        className="capitalize"
                      >
                        <span className="capitalize">
                          {resto?.restaurant?.name}
                        </span>
                      </option>
                    ))}
                  </>
                )}
                {services === "mart" && (
                  <>
                    {store?.map((s: any) => (
                      <option
                        key={s?.store?.id}
                        value={`&storeId=${s?.store?.id}`}
                        className="capitalize"
                      >
                        <span className="capitalize">{s?.store?.name}</span>
                      </option>
                    ))}
                  </>
                )}
              </Select>
            </IconContainer>
          </div>

          <div className="date">
            {days == 0 ? (
              <RangePicker
                onChange={(values: any) => {
                  const startRange = moment(new Date(values[0])).toISOString();
                  const endRange = moment(new Date(values[1])).toISOString();
                  const date = `${sign}startTime=${startRange}&endTime=${endRange}`;
                  dispatch.SalesOverview.updateState({
                    dateRange: date,
                  });
                }}
              />
            ) : (
              <Span weight="semi" size="sm">
                {moment(salesData?.from).format("MMM D, YYYY")} -{" "}
                {moment(salesData?.to).format("MMM D, YYYY")}
              </Span>
            )}
          </div>

          <div className="days">
            <IconContainer icon={<RiCalendarEventFill className="days-icon" />}>
              <Select
                className="ml-6 w-full"
                value={days}
                onChange={(e: any) => {
                  dispatch.SalesOverview.updateState({
                    days: e.target.value,
                    dateRange: "",
                  });
                }}
              >
                {DAYS?.map((type, index) => (
                  <option key={index} value={type.value}>
                    {type.name}
                  </option>
                ))}
              </Select>
            </IconContainer>
          </div>

          <div className="exportOption">
            <IconContainer
              icon={<IoMdDownload className="exportOption-icon" />}
            >
              <span
                onClick={exportPDF}
                className="ml-6 w-full text-xs cursor-pointer"
              >
                Export
              </span>
            </IconContainer>
          </div>
        </div>

        {!isLoading ? (
          <>
            {!salesData?.orderTransaction?.summary ? (
              <>
                <NoData />
              </>
            ) : (
              <>
                <div id="SalesOverviewPDF">
                  <GridContainer grid="three">
                    {items.map(({ name }) => (
                      <>
                        <div
                          key={name}
                          onClick={() => setActive(name)}
                          className={active === name ? "tab-active" : "tab"}
                        >
                          <TabContent title={name}>
                            {name === "Transactions" ? (
                              <Transactions
                                transaction={salesData?.orderTransaction?.total}
                              />
                            ) : name === "Net Sales" ? (
                              <NetSales net={salesData?.orderNetSales?.total} />
                            ) : name === "AllEasy Go Commission" ? (
                              <Commission
                                commission={salesData?.aegCommission?.total}
                              />
                            ) : null}
                          </TabContent>
                        </div>
                      </>
                    ))}
                  </GridContainer>
                  {active === "Transactions" && (
                    <CardContent title={active}>
                      <TransactionsContent
                        transaction={salesData?.orderTransaction?.summary}
                      />
                    </CardContent>
                  )}
                  {active === "Net Sales" && (
                    <CardContent title={active}>
                      <NetSalesContent
                        net={salesData?.orderNetSales?.summary}
                      />
                    </CardContent>
                  )}
                  {active === "AllEasy Go Commission" && (
                    <CardContent title={active}>
                      <CommissionContent
                        commission={salesData?.aegCommission?.summary}
                      />
                    </CardContent>
                  )}

                  <FooterSalesOverview salesData={salesData} />
                </div>
              </>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};
