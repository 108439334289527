import { STATUS } from "@constant";
import { GenerateStatusDesign } from "./GenerateStatus";

export const COLUMNS_WITH_TC = [
  {
    accessor: "orderNumber",
    Header: "Order Number",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span className="text-center">#{d?.orderNumber}</span>
        </div>
      );
    },
  },
  {
    accessor: "orderId",
    Header: "Reference Number",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span className="text-center">{d?.orderId}</span>
        </div>
      );
    },
  },
  {
    accessor: "name",
    Header: "Customer",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const customer = d?.order?.customer?.default_address;
      return (
        <div className="flex justify-center">
          <span className="text-center capitalize">{customer?.name}</span>
        </div>
      );
    },
  },
  {
    accessor: "phone",
    Header: "Phone Number",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const customer = d?.order?.customer?.default_address;
      return (
        <div className="flex justify-center">
          <span className="text-center capitalize">{customer?.phone}</span>
        </div>
      );
    },
  },
  {
    accessor: "email",
    Header: "Email",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const customer = d?.order?.customer;
      return (
        <div className="flex justify-center">
          <span className="text-center">{customer?.email}</span>
        </div>
      );
    },
  },
  {
    accessor: "status",
    Header: "Status",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <GenerateStatusDesign status={d?.status} label={STATUS[d?.status]} />
      );
    },
  },
  {
    accessor: "locationAddress",
    Header: "Pickup Location",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const pickup = d?.pickup;
      return (
        <div className="truncate pl-10 max-w-[250px]">
          <span className="text-left capitalize">
            {pickup?.locationAddress}
          </span>
          <br />
          <span className="text-left capitalize text-grey-1">
            {pickup?.contactName} | {pickup?.contactPhone}
          </span>
        </div>
      );
    },
  },
  {
    accessor: "total",
    Header: "Total",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const total = d?.fare?.total;
      return (
        <div className="flex items-center justify-center truncate max-w-[200px]">
          <span className="text-violet-1 font-bold">{`₱${parseFloat(total)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</span>
        </div>
      );
    },
  },
];
