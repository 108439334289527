import { MainContainer } from "@common";
import { Navbar, SideMenu } from "@components";
import { BRAND_IMAGES } from "@constant";
import { Supplier } from "@containers";
import { useParams } from "react-router-dom";

export const SupplierPage = () => {
  let params: any = useParams();

  return (
    <>
      <Navbar title="Supplier" icon={BRAND_IMAGES["HEADER_LOGO"]} />
      <SideMenu />
      <MainContainer>
        <Supplier id={params.id} />
      </MainContainer>
    </>
  );
};
