import { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { getPersistor } from "@rematch/persist";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { FirebaseAppProvider } from "reactfire";
import fbConfig from "./configs/firebase"
import { BrowserRouter } from "react-router-dom";

const options = {
  position: positions.MIDDLE_RIGHT,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 1000000,
  },
};

const persistor = getPersistor();

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={fbConfig} suspense>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AlertProvider template={AlertTemplate} {...options}>
          <Suspense fallback={<></>}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Suspense>
        </AlertProvider>
      </PersistGate>
    </Provider>
  </FirebaseAppProvider>,
  document.getElementById("merchant")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
