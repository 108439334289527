import { CardContainer, Container, GridContainer, Header, Span } from "@common";
import { BRAND_IMAGES, LINK_EXPIRED_BG } from "@constant";
import "./registrationLinkExpired.scss";

export const RegistrationLinkExpired = () => { 
    return (<>
        <GridContainer>
            <CardContainer> 
                <img src={BRAND_IMAGES["BRAND_LOGO"]} alt="AEG Logo" className="brand-logo"/>
  
                <Container margin="my-8" align="center">  
                    <Header color="orange" margin="mb-4">Registration Link Expired</Header> 
                    <p className="mt-8">
                        <Span weight="semi">
                            This registration link has expired and can only be used once. 
                        </Span> <br/>
                        <Span weight="semi">Please request a new activation link.</Span>.
                    </p>
                </Container>  
            </CardContainer>

            <div className="flex min-h-screen justify-left items-center"> 
                <img src={LINK_EXPIRED_BG} alt="Link Expired" className="linkExpired-icon"/>
            </div>
        </GridContainer>
    </>);
}   