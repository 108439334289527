/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { useDispatch } from "react-redux";
import { Dispatch } from "@store";

import shop_icon from "@assets/create.png";
import { Stack } from "@mui/material";
import { Button, Span } from "@common";
import { useHistory } from "react-router-dom";

const StatusShop = ({ storeId, storeName }: any) => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  const handleShop = () => {
    history.push(`/supplier/choose-supplier/shop/${storeId}`);
    dispatch.Supplier.updateState({ storeId: storeId, storeName: storeName });
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      alignItems="center"
    >
      <Button
        width="none"
        bg="orange"
        padding="none"
        margin="none"
        onClick={handleShop}
      >
        <div className="flex action-button px-2 py-1 mx-2">
          <img
            src={shop_icon}
            placeholder={shop_icon}
            className="status_icon"
          />
          <Span margin="auto" color="white" weight="semi">
            Shop
          </Span>
        </div>
      </Button>
    </Stack>
  );
};

export default StatusShop;
