import { useDispatch } from "react-redux";
import { Dispatch } from "@store";
import { useState } from "react";

import { Button, Container, GridContainer, Inputs, Span } from "@common";
import { InputContainerNew } from "@components";
import { MdKeyboardArrowRight } from "@icons";
import { Form } from "antd";
import { addShopifyInfo } from "@api";
import { useHistory } from "react-router-dom";

export const ShopifyAdd = () => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();

  const history = useHistory();

  const initialState = {
    storeName: "",
    storeDomain: "",
    adminToken: "",
    apiKey: "",
    secretKey: "",
  } as any;

  const [shopifyDetails, setShopifyDetails] = useState(initialState);

  const handleCancel = (e: any) => {
    e.preventDefault();
    history.push(`/ecommerce/integration`);
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setShopifyDetails({
      ...shopifyDetails,
      [name]: value,
    });
  };

  const handleAddShopify = async () => {
    try {
      const values = await form.validateFields();

      const res = await addShopifyInfo({
        ...shopifyDetails,
        ...values,
      });
      if (res) {
        dispatch.UI.setAlert({
          message: "Shopify Account successfully added!",
          type: "Success",
        });
        setShopifyDetails(initialState);
        history.push(`/ecommerce/integration`);
      }
    } catch (err: any) {
      const errMsg = err?.response?.data?.message ?? "Unable to add Shopify!";
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }
  };

  return (
    <>
      <div
        className="flex mb-4 cursor-pointer"
        onClick={() => history.push(`/ecommerce/integration`)}
      >
        <Span>Ecommerce </Span> <MdKeyboardArrowRight />
        <Span>Integration </Span> <MdKeyboardArrowRight />
        <Span> Shopify </Span>
        <MdKeyboardArrowRight /> <Span weight="bold">Create Account </Span>
      </div>

      <GridContainer>
        <Form
          scrollToFirstError
          layout="vertical"
          form={form}
          initialValues={initialState}
        >
          <Container padding="p-4">
            <InputContainerNew label="Store Name" required={true}>
              <Form.Item
                name="storeName"
                rules={[{ required: true }]}
                className="w-full"
              >
                <Inputs
                  padding="left"
                  border="bottom"
                  name="storeName"
                  onChange={onChange}
                />
              </Form.Item>
            </InputContainerNew>

            <InputContainerNew icon={false} label="Endpoint" required={true}>
              <Form.Item
                name="storeDomain"
                rules={[{ required: true }]}
                className="w-full"
              >
                <Inputs
                  padding="left"
                  border="bottom"
                  name="storeDomain"
                  onChange={onChange}
                />
              </Form.Item>
            </InputContainerNew>

            <InputContainerNew icon={false} label="Admin Token" required={true}>
              <Form.Item
                name="adminToken"
                rules={[{ required: true }]}
                className="w-full"
              >
                <Inputs
                  padding="left"
                  border="bottom"
                  name="adminToken"
                  type="text"
                  onChange={onChange}
                />
              </Form.Item>
            </InputContainerNew>

            <InputContainerNew icon={false} label="API Key" required={true}>
              <Form.Item
                name="apiKey"
                rules={[{ required: true }]}
                className="w-full"
              >
                <Inputs
                  padding="left"
                  border="bottom"
                  name="apiKey"
                  type="text"
                  onChange={onChange}
                />
              </Form.Item>
            </InputContainerNew>

            <InputContainerNew icon={false} label="Secret Key" required={true}>
              <Form.Item
                name="secretKey"
                rules={[{ required: true }]}
                className="w-full"
              >
                <Inputs
                  padding="left"
                  border="bottom"
                  name="secretKey"
                  type="text"
                  onChange={onChange}
                />
              </Form.Item>
            </InputContainerNew>
          </Container>

          <div className="flex justify-end">
            <Button
              bg="white"
              color="grey"
              width="none"
              margin="m-4"
              onClick={handleCancel}
            >
              Back
            </Button>
            <Button
              bg="orange"
              width="none"
              padding="px-6"
              onClick={handleAddShopify}
            >
              Save
            </Button>
          </div>
        </Form>
      </GridContainer>
    </>
  );
};
