import { SubContainer } from "@common";
import { Table } from "@components";
import { useMemo } from "react";
import { COLUMNS } from "./Table/column";

type Props = {
  salesData?: any;
};

export const TopProduct = ({ salesData }: Props) => {
  const columns = useMemo(() => COLUMNS, []);
  const sales = salesData.slice(0, 10);

  return (
    <>
      <SubContainer padding="pl-4" height="h-60">
        <div className="table-salesOverview">
          <Table columns={columns} data={sales} />
        </div>
      </SubContainer>
    </>
  );
};
