import { Stack } from "@mui/material";
import { Button } from "@common";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dispatch } from "@store";

const StatusShop = ({ storeId, storeName }: any) => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  const handleView = () => {
    history.push(`/orders/customer-order/${storeId}`);
    dispatch.Supplier.updateState({
      storeName: storeName,
      storeId: storeId,
    });
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Button
          bg="green"
          margin="none"
          width="none"
          padding="px-6"
          onClick={handleView}
        >
          View
        </Button>
      </Stack>
    </>
  );
};

export default StatusShop;
