import { Stack } from "@mui/material";
import { Button } from "@common";
import { useState } from "react";
import { EnabledModal } from "../Modal/EnabledModal";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dispatch } from "@store";

const StatusShop = ({ storeId, supplierStatus, storeName }: any) => {
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  const [enabledModal, setEnabledModal] = useState(false);

  const handleView = () => {
    history.push(`/supplier/item-creation/${storeId}`);
    dispatch.Supplier.updateState({
      storeId: storeId,
    });
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Button
          bg={supplierStatus === "enabled" ? "orange" : "red"}
          width="none"
          margin="none"
          onClick={() => {
            setEnabledModal(true);
          }}
          transform="capitalize"
        >
          {supplierStatus}
        </Button>
        <Button
          bg="green"
          margin="none"
          width="none"
          padding="px-6"
          onClick={handleView}
        >
          View
        </Button>
      </Stack>

      {enabledModal && (
        <EnabledModal
          setEnabledModal={setEnabledModal}
          storeId={storeId}
          supplierStatus={supplierStatus}
          storeName={storeName}
        />
      )}
    </>
  );
};

export default StatusShop;
