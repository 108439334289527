import { useState } from "react";
import { Span } from "@common";
import scriptLoader from "react-async-script-loader";
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "@store";

let REACT_APP_API_MAP_KEY = process.env.REACT_APP_API_MAP_KEY
  ? process.env.REACT_APP_API_MAP_KEY
  : "";

function Autocomplete({ isScriptLoaded, isScriptLoadSucceed }) {
  const dispatch = useDispatch<Dispatch>();
  const pickup = useSelector((state: RootState) => state.Ecommerce);
  const [address, setAddress] = useState(pickup?.address);
  const [oldAddress, setOldAddress] = useState<any>({
    oldAddress: "",
    newAddress: address,
  });

  const handleChange = (address) => {
    setOldAddress({ oldAddress: oldAddress.newAddress, newAddress: address });
    setAddress(address);
    dispatch.Ecommerce.updateState({
      address: address,
    });
  };

  const handleSelect = async (address, placeId) => {
    if (!placeId) {
      return;
    }
    let place = await geocodeByPlaceId(placeId);
    let location = {
      lat: place[0]?.geometry?.location?.lat(),
      lng: place[0]?.geometry?.location?.lng(),
    };

    dispatch.Ecommerce.updateState({
      address: address,
      location: {
        lat: location?.lat,
        lng: location?.lng,
      },
    });
    setAddress(address);
  };

  if (isScriptLoaded && isScriptLoadSucceed) {
    return (
      <div className="w-full pb-4">
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <>
              <input
                {...getInputProps({
                  placeholder: "",
                  className:
                    "focus:outline-none text-xs bg-transparent border-b border-grey-2 w-full py-2 pl-2 pr-10 capitalize",
                })}
              />
              <div className="absolute z-10 w-full shadow-md">
                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active ? "#f2f2f2" : "#ffffff",
                    cursor: "pointer",
                  };

                  return (
                    <div {...getSuggestionItemProps(suggestion, { style })}>
                      <div className="text-left p-2 border-b border-themegrey-400">
                        <Span>{suggestion.description}</Span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </PlacesAutocomplete>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default scriptLoader([
  `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_API_MAP_KEY}&libraries=places`,
])(Autocomplete);
