import { createModel } from "@rematch/core"; 
import { RootModel } from "."; 

interface IHourlyData { 
  restaurantId: string;
  services: string;
  filter: string;
  days: number;
  dateRange: string;
}

const initialState = {  
    restaurantId: '',
    services: '',
    filter: '',
    days: 30,
    dateRange: ''
} as IHourlyData;

export const HourlyData = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState }
    },
    /**
     * @name resetForm
     * @description resets from by passing table property underform
     * @param table
     */ 
    updateState(state, newState: any) {
      return {
        ...state,
        ...newState
      };
    }, 
  }, 
});
