/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  MdStoreMallDirectory,
  MdDashboard,
  IoMdDownload,
  RiCalendarEventFill,
} from "@icons";
import { useEffect, useState } from "react";
import { Container, Select, Span } from "@common";
import { IconContainer, Loader } from "@components";
import { DAYS, SERVICES } from "@constant";
import { TIME } from "./Table/common";
import "./Table/table.scss";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { getHourlyList } from "@api";
import { Table } from "./Table/Table";
import { useColumns } from "./Table/useColumns";
import { DatePicker } from "antd";
import { formatMonthDay } from "@utils";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const SalesReport = () => {
  const now = moment();
  const dispatch = useDispatch<Dispatch>();
  const { RangePicker } = DatePicker;

  const [isLoading, setIsLoading] = useState(false);

  const restaurant = useSelector(
    (state: RootState) => state.User.userInfo?.restaurantAccesses
  );

  const store = useSelector(
    (state: RootState) => state.User.userInfo?.storeAccesses
  );

  const { filter, services, days, dateRange } = useSelector(
    (state: RootState) => state.HourlyData
  );

  var today = moment()
    .add(-days + 1, "days")
    .startOf("day")
    .toDate()
    .toISOString();
  const sign = !services ? `?` : `&`;
  const startTime = `${sign}startTime=${today}`;

  const [active, setActive] = useState("net");
  const items = [
    { value: "net", name: "Total Net Sales" },
    { value: "order", name: "Total Number of Order" },
  ];

  const [hourlyData, setHourlyData] = useState([] as any);
  const getHourly = async () => {
    setIsLoading(true);
    try {
      const filters = !services ? "" : `?type=${services}${filter}`;
      const dateFilter = !dateRange ? startTime : dateRange;
      const res = await getHourlyList(filters, dateFilter);
      setHourlyData(res);
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "No data found!",
        type: "Error",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!services) {
      dispatch.HourlyData.updateState({
        filter: "",
        services: "",
      });
    }
    getHourly();
  }, [services, filter, days, dateRange]);

  const setDate: any = hourlyData?.orderSalesPerHour?.map((e: any) =>
    formatMonthDay(e?.date)
  );
  //@ts-ignore
  const columnDate = [...new Set(setDate)];
  const column = useColumns(columnDate, active);

  const timeData = TIME?.map((t) => {
    const data = hourlyData?.orderSalesPerHour?.find((h: any) => h?.hour === t);
    const payload = {
      ...data,
      hour: t,
    };
    return payload;
  });

  const exportPDF = () => {
    const input = document.getElementById("SalesReportPDF")!;
    html2canvas(input, {
      logging: true,
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      const imgWidth = 208;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 1, 1, imgWidth, imgHeight);
      pdf.save(`SalesReport_${now.format("MM/DD/YYYY")}.pdf`);
    });
  };

  return (
    <>
      <div className="sales-categories px-6">
        <div className="services">
          <IconContainer icon={<MdDashboard className="services-icon" />}>
            <Select
              className="ml-6 w-full"
              value={services}
              onChange={(e: any) => {
                dispatch.HourlyData.updateState({
                  services: e.target.value,
                });
              }}
            >
              {SERVICES?.map((type, index) => (
                <option key={index} value={type?.value}>
                  {type.name}
                </option>
              ))}
            </Select>
          </IconContainer>
        </div>

        <div className="store">
          <IconContainer icon={<MdStoreMallDirectory className="store-icon" />}>
            <Select
              className="ml-6 w-full"
              value={filter}
              onChange={(e: any) => {
                dispatch.HourlyData.updateState({
                  filter: e.target.value,
                });
              }}
            >
              <option value="" className="capitalize">
                All Store
              </option>
              {services === "food" && (
                <>
                  {restaurant?.map((resto: any) => (
                    <option
                      key={resto?.restaurant?.id}
                      value={`&restaurantId=${resto?.restaurant?.id}`}
                      className="capitalize"
                    >
                      <span className="capitalize">
                        {resto?.restaurant?.name}
                      </span>
                    </option>
                  ))}
                </>
              )}
              {services === "mart" && (
                <>
                  {store?.map((s: any) => (
                    <option
                      key={s?.store?.id}
                      value={`&storeId=${s?.store?.id}`}
                      className="capitalize"
                    >
                      <span className="capitalize">{s?.store?.name}</span>
                    </option>
                  ))}
                </>
              )}
            </Select>
          </IconContainer>
        </div>

        <div className="date">
          {days == 0 ? (
            <RangePicker
              onChange={(values: any) => {
                const startRange = moment(new Date(values[0])).toISOString();
                const endRange = moment(new Date(values[1])).toISOString();
                const date = `${sign}startTime=${startRange}&endTime=${endRange}`;
                dispatch.HourlyData.updateState({
                  dateRange: date,
                });
              }}
            />
          ) : (
            <Span weight="semi" size="sm">
              {moment(hourlyData?.from).format("MMM D, YYYY")} -{" "}
              {moment(hourlyData?.to).format("MMM D, YYYY")}
            </Span>
          )}
        </div>

        <div className="days">
          <IconContainer icon={<RiCalendarEventFill className="days-icon" />}>
            <Select
              value={days}
              className="ml-6 w-full"
              onChange={(e: any) => {
                dispatch.HourlyData.updateState({
                  days: e.target.value,
                  dateRange: "",
                });
              }}
            >
              {DAYS?.map((type, index) => (
                <option key={index} value={type.value}>
                  {type.name}
                </option>
              ))}
            </Select>
          </IconContainer>
        </div>

        <div className="exportOption">
          <IconContainer icon={<IoMdDownload className="exportOption-icon" />}>
            <span
              onClick={exportPDF}
              className="ml-6 w-full text-xs cursor-pointer"
            >
              Export
            </span>
          </IconContainer>
        </div>
      </div>

      {!isLoading ? (
        <div className="px-6" id="SalesReportPDF">
          <Container padding="p-4">
            <div className="flex justify-between">
              <div className="flex">
                {items.map((i: any) => (
                  <>
                    <div
                      key={i}
                      onClick={() => setActive(i?.value)}
                      className="cursor-pointer"
                    >
                      <Span
                        color={active === i?.value ? "maroon" : "grey"}
                        weight={active === i?.value ? "semi" : "default"}
                        decor={active === i?.value ? "underline" : "default"}
                        margin="mr-2"
                      >
                        {i?.name}
                      </Span>
                    </div>
                  </>
                ))}
              </div>
            </div>

            <div className="table-list flex">
              <Table columns={column} data={timeData} header={false} />
            </div>
          </Container>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
