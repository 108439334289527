export const useColumns = (columnDate, active) => { 
  const columnResult = columnDate?.map((e, i) => {
    const payload = {
      id: i,
      accessor: data => data?.date === e ? active === "net" ? data?.total : data?.count : "0",
      Header: e,
      Footer: e,
      isVisible: true,
    };
    return payload;
  });

  const timeStamp = {
    id: "hour",
    accessor: "hour",
    isVisible: true,
     Cell: (data: any) => {
            const d = data?.row?.original; 
            return (<span> 
                {`${d?.hour}:00`} 
            </span>
        )}  
  };

  return [timeStamp, ...columnResult];
};
