/* eslint-disable @typescript-eslint/no-unused-vars */
import { GridContainer } from "@common";
import { CardContent } from "@components";
import { ORDER_METHOD, PAYMENT_METHOD, TOP_PRODUCT } from "@constant";
import { OrderMethod } from "../OrderMethod/OrderMethod";
import { PaymentMethod } from "../PaymentMethod/PaymentMethod";
import { TopProduct } from "../TopProduct/TopProduct";

type Props = {
  salesData?: any;
};

export const FooterSalesOverview = ({ salesData }: Props) => {
  return (
    <>
      <GridContainer grid="three">
        {salesData?.topProducts && (
          <CardContent title="Top Products" data={TOP_PRODUCT} select={false}>
            <TopProduct salesData={salesData?.topProducts} />
          </CardContent>
        )}

        {salesData?.orderPaymentMethod && (
          <CardContent
            title="Payment Method"
            data={PAYMENT_METHOD}
            select={false}
          >
            <PaymentMethod paymentMethod={salesData?.orderPaymentMethod} />
          </CardContent>
        )}

        {salesData?.orderMethod && (
          <CardContent title="Order Method" data={ORDER_METHOD} select={false}>
            <OrderMethod orderMethodData={salesData?.orderMethod} />
          </CardContent>
        )}
      </GridContainer>
    </>
  );
};
