import { MainContainer } from '@common';
import { Navbar, SideMenu} from '@components';
import { BRAND_IMAGES } from '@constant';
import {TeamDetails} from '@containers';
import { useParams } from 'react-router-dom';

export const TeamDetailsPage = () => {

  let params: any = useParams();
  
  return (
    <> 
      <Navbar title='Team Members' icon={BRAND_IMAGES["HEADER_LOGO"]}/>
      <SideMenu />
      <MainContainer > 
        <TeamDetails id={params.id} />
      </MainContainer>
    </>
  )
}