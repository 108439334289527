import { useState } from 'react';
import { HeaderContainer, Header, Span} from '@common' 
import { AlertSnackBar } from "@components";
import './Navbar.scss'


type Props = { 
  title?: string,
  icon?: string
}

export const Navbar = ({ title, icon }: Props) => { 
  
  const [open, setOpen] = useState(false);

  return (<>
    <HeaderContainer>
      <AlertSnackBar open={open} setOpen={setOpen} />
      <div className='flex'> 
        <img src={icon} alt="AEG Logo" className='m-auto h-10'/> 
        <Span margin='auto' color='white' size='lg' padding="px-8">{title}</Span>
      </div>

      <div className='flex'>  
        <Header margin='auto' color='white'>Merchant Portal</Header>
      </div>
    </HeaderContainer>
  </>);
}   