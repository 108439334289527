import { MainContainer } from "@common";
import { Navbar, SideMenu } from "@components";
import { BRAND_IMAGES } from "@constant";
import { CustomerOrderByStore } from "@containers";

import { useParams } from "react-router-dom";

export const CustomerOrderByStorePage = () => {
  let params: any = useParams();
  return (
    <>
      <Navbar title="Customer Order" icon={BRAND_IMAGES["HEADER_LOGO"]} />
      <SideMenu />
      <MainContainer>
        <CustomerOrderByStore id={params.id} />
      </MainContainer>
    </>
  );
};
