import { createModel } from "@rematch/core"; 
import { RootModel } from ".";

export interface State {  
  storeId: string;
  storeName: string;
}

export const initialState = { 
    storeId: '',
    storeName: ''
} as State;

export const Supplier = createModel<RootModel>()({
    state: initialState,
    reducers: {
      resetState() {
        return { ...initialState }
      },
      /**
       * @name resetForm
       * @description resets from by passing table property underform
       * @param table
       */ 
      updateState(state, newState: Partial<State>) {
        return { ...state, ...newState };
      }
    }, 
  });