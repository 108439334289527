import { MainContainer } from '@common';
import { Navbar, SideMenu} from '@components';
import { BRAND_IMAGES } from '@constant';
import {SalesReport} from '@containers';

export const SalesReportPage = () => {
  return (
    <> 
      <Navbar title='Sales Report' icon={BRAND_IMAGES["HEADER_LOGO"]}/>
      <SideMenu />
      <MainContainer > 
        <SalesReport />
      </MainContainer>
    </>
  )
}