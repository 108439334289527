/* eslint-disable jsx-a11y/alt-text */
import { Span, SubContainer } from "@common";
import { PRICE } from "@components"; 

type Props = {
  net?: any;
};

export const NetSales = ({ net }: Props) => {
  return (
    <>
      <SubContainer margin="auto">
        <div className="text-center">
          <p>
            <Span weight="bold" size="2xl">
              <PRICE price={net} sign={true} />
            </Span>
          </p>
        </div>
      </SubContainer>
    </>
  );
};
