/* eslint-disable @typescript-eslint/no-unused-vars */
import { postSetPassword } from "@api";
import { Button, Container, Header, ModalContainer, Span } from "@common";
import { ErrorMessage, PasswordContainer } from "@components";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch, RootState } from "@store";
import profileIcon from "@assets/profile_icon.png";
import check from "@assets/check.png";
import check_green from "@assets/check_green.png";
import check_red from "@assets/check_red.png";
import success_check from "@assets/success_check.png";
import {
  validateSpecialCharacter,
  validateWithNumber,
  validateWithUpperOrLowerCase,
} from "@utils";

type Props = {
  setChangePasswordModal: Function;
};

export const ChangePasswordModal = ({ setChangePasswordModal }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const user: any = useSelector((state: RootState) => state.User.userInfo);
  const isUserLoggedIn = useSelector(
    (state: RootState) => state.User.isUserLoggedIn
  );
  var accessToken = user?.accessToken;

  const validation = !password || !passwordConfirm;

  let handelSetPassword = async () => {
    try {
      const result = await postSetPassword(
        accessToken,
        password,
        passwordConfirm,
        currentPassword
      );

      if (result) {
        setSuccess(true);
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message,
        type: "Error",
      });
      setChangePasswordModal(false);
    }
  };

  // Validation
  const isEightCharacter = password.length > 8;
  const isWithValue = !password.length || !passwordConfirm.length;
  const isPasswordMatch = isWithValue || password === passwordConfirm;
  const isWithNumber = validateWithNumber(password);
  const isLowerOrUpperCase = validateWithUpperOrLowerCase(password);
  const isSpecialCharacter = validateSpecialCharacter(password);

  const handleLogout = async () => {
    setChangePasswordModal(false);
    await dispatch.User.logout();
    if (isUserLoggedIn) {
      dispatch.UI.setAlert({
        message: "Logout successfully!",
        type: "Success",
      });
    }
    history.push("/");
  };

  return (
    <>
      <ModalContainer bg="grey">
        <div className="p-10 py-5 whitespace-normal text-center ">
          {!success ? (
            <>
              <div className="mx-auto my-4">
                <img
                  src={profileIcon}
                  alt={profileIcon}
                  className="mx-auto my-2"
                />
                <Span color="orange" size="base" weight="semi">
                  Change Password
                </Span>
              </div>

              <Container padding="p-2" margin="none" bg="transparent">
                <Span color="grey">Current Password</Span>
                <PasswordContainer
                  id="currentPassword"
                  minLength={8}
                  maxLength={80}
                  onChange={(e: any) => {
                    setCurrentPassword(e.target.value);
                  }}
                />
              </Container>

              <Container padding="p-2" margin="none" bg="transparent">
                <Span color="grey">Password</Span>
                <PasswordContainer
                  id="password"
                  minLength={8}
                  maxLength={80}
                  onChange={(e: any) => {
                    setPassword(e.target.value);
                  }}
                />
              </Container>

              <Container padding="p-2" margin="none" bg="transparent">
                <Span color="grey">Confirm Password</Span>
                <PasswordContainer
                  id="passwordConfirm"
                  minLength={8}
                  maxLength={80}
                  onChange={(e: any) => {
                    setPasswordConfirm(e.target.value);
                  }}
                />
              </Container>

              <Container padding="p-2" margin="none" bg="transparent">
                <div className="flex my-auto">
                  <img
                    src={
                      !password
                        ? check
                        : isEightCharacter
                        ? check_green
                        : check_red
                    }
                    alt={check}
                    className="my-1"
                  />
                  <Span
                    color={
                      !password ? "grey" : isEightCharacter ? "green" : "red"
                    }
                    margin="my-auto"
                  >
                    At least (8) characters long
                  </Span>
                </div>

                <div className="flex my-auto">
                  <img
                    src={
                      !password.length
                        ? check
                        : isLowerOrUpperCase
                        ? check_green
                        : check_red
                    }
                    alt={check}
                    className="my-1"
                  />
                  <Span
                    color={
                      !password.length
                        ? "grey"
                        : isLowerOrUpperCase
                        ? "green"
                        : "red"
                    }
                    margin="my-auto"
                  >
                    Combination of uppercase and lowercase letters
                  </Span>
                </div>

                <div className="flex my-auto">
                  <img
                    src={
                      !password.length
                        ? check
                        : isWithNumber
                        ? check_green
                        : check_red
                    }
                    alt={check}
                    className="my-1"
                  />
                  <Span
                    color={
                      !password.length ? "grey" : isWithNumber ? "green" : "red"
                    }
                    margin="my-auto"
                  >
                    Should contain at least 1 number
                  </Span>
                </div>

                <div className="flex my-auto">
                  <img
                    src={
                      !password.length
                        ? check
                        : isSpecialCharacter
                        ? check_green
                        : check_red
                    }
                    alt={check}
                    className="my-1"
                  />
                  <Span
                    color={
                      !password.length
                        ? "grey"
                        : isSpecialCharacter
                        ? "green"
                        : "red"
                    }
                    margin="my-auto"
                  >
                    At least (1) symbol or special character
                  </Span>
                </div>

                <div className="flex my-auto">
                  <img
                    src={
                      isWithValue
                        ? check
                        : isPasswordMatch
                        ? check_green
                        : check_red
                    }
                    alt={check}
                    className="my-1"
                  />
                  <Span
                    color={
                      isWithValue ? "grey" : isPasswordMatch ? "green" : "red"
                    }
                    margin="my-auto"
                  >
                    Password must match
                  </Span>
                </div>
              </Container>

              <div className="flex justify-end">
                <Button
                  bg="white"
                  color="grey"
                  width="none"
                  margin="m-4"
                  onClick={() => setChangePasswordModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  bg="orange"
                  width="none"
                  padding="px-6"
                  onClick={handelSetPassword}
                  disabled={validation || !isPasswordMatch}
                >
                  Save
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="mx-auto my-4">
                <img
                  src={success_check}
                  alt={success_check}
                  className="mx-auto my-2"
                />
                <Span color="orange" size="lg" weight="semi">
                  Change Password Successful
                </Span>
                <p className="my-2">
                  <Span size="sm" weight="semi">
                    Your AllEasy Go Merchant Portal account password has been
                    successfully changed.
                  </Span>
                </p>
              </div>

              <Button bg="orange" padding="px-6" onClick={handleLogout}>
                Logout
              </Button>
            </>
          )}
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
