/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, GridContainer, Inputs, ModalContainer, Span } from "@common";
import { InputContainerNew, UploadFile } from "@components";
import { onlyNumberKey } from "@utils";
import { Form, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import AddItemIcon from "@assets/add-items.png";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { updateItem } from "@api";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch, RootState } from "@store";
import { IItemSupplier } from "@types";

const { Option } = Select;

type Props = {
  setEditModals: Function;
  id: string;
};

let CATEGORY_UNSUBSCRIBE: any = null;
let MEASUREMENT_UNSUBSCRIBE: any = null;
let ITEM_UNSUBSCRIBE: any = null;
export const EditModal = ({ setEditModals, id }: Props) => {
  const dispatch = useDispatch<Dispatch>();

  const storeId: any = useSelector(
    (state: RootState) => state.Supplier.storeId
  );

  const [form]: any = Form.useForm();

  const [itemEdit, setItemEdit] = useState({}) as any;
  const [itemEditDetails, setItemDetails] = useState({}) as any;
  const [categoryData, setCategoryData] = useState([] as any);
  const [measurementData, setMeasurementData] = useState([] as any);

  const [file, setFile] = useState() as any;

  // Category
  const db = getFirestore();
  const listenToCategory = async () => {
    const q = query(
      collection(db, "store_supplier_category"),
      where("storeId", "==", storeId)
    );

    CATEGORY_UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || []).map((ref) => {
        const data = ref.data();
        return data;
      }) as any[];
      setCategoryData(listData);
    });
  };

  useEffect(() => {
    listenToCategory();

    return function () {
      try {
        if (CATEGORY_UNSUBSCRIBE) CATEGORY_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };
  }, []);

  // Measurement
  const listenToMeasurement = async () => {
    const q = query(
      collection(db, "store_supplier_unit_measurement"),
      where("storeId", "==", storeId)
    );

    MEASUREMENT_UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || []).map((ref) => {
        const data = ref.data();
        return data;
      }) as any[];
      setMeasurementData(listData);
    });
  };

  useEffect(() => {
    listenToMeasurement();

    return function () {
      try {
        if (MEASUREMENT_UNSUBSCRIBE) MEASUREMENT_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };
  }, []);

  const handleUpdateItem = async () => {
    try {
      const formData = await handleFormData(itemEdit, file);
      const res = await updateItem(storeId, itemEditDetails?.id, formData);
      if (res) {
        dispatch.UI.setAlert({
          message: "User updated!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "Unable update user!",
        type: "Error",
      });
    }
    setEditModals(false);
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setItemEdit({
      ...itemEdit,
      [name]: value,
    });
  };

  useEffect(() => {
    const itemdetails = {
      id: itemEditDetails?.id,
      name: itemEditDetails?.name,
      unitMeasurementId: itemEditDetails?.unitMeasurementId,
      categoryId: itemEditDetails?.categoryId,
      price: itemEditDetails?.price,
      quantity: itemEditDetails?.quantity,
      status: itemEditDetails?.status,
    };
    setItemEdit(itemdetails);
    form.setFieldsValue({
      ...itemdetails,
      status: itemEditDetails.status === "active",
    });
  }, [itemEditDetails?.id]);

  const listenToItem = async () => {
    const q = query(
      collection(db, "store_supplier_product"),
      where("storeId", "==", storeId),
      where("id", "==", id)
    );

    ITEM_UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || []).map((ref) => {
        const data = ref.data();
        return data;
      }) as any[];
      setItemDetails(listData[0]);
    });
  };

  useEffect(() => {
    listenToItem();

    return function () {
      try {
        if (ITEM_UNSUBSCRIBE) ITEM_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };
  }, []);

  const handleFile = async (data: any) => {
    setFile(data);
  };

  const handleFormData = async (itemEdit: IItemSupplier, file: Blob) => {
    const formData = new FormData();

    formData.append("file", file);

    for (let key in itemEdit) {
      formData.append(`${key}`, itemEdit[key]);
    }
    return formData;
  };

  return (
    <>
      <ModalContainer>
        <Form onFinish={handleUpdateItem} layout="vertical" form={form}>
          <div className="p-10 py-5 whitespace-normal text-center ">
            <div className="mx-auto">
              <img
                src={AddItemIcon}
                alt={AddItemIcon}
                className="mx-auto my-2 h-12"
              />
              <Span color="orange" size="base" weight="semi">
                Update Item
              </Span>
            </div>

            <UploadFile
              setFile={handleFile}
              defaultImageurl={itemEditDetails?.image}
            />

            <InputContainerNew
              icon={false}
              label="Item"
              required={true}
              bg="transparent"
            >
              <Form.Item
                name="name"
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Item is required",
                  },
                ]}
              >
                <Inputs
                  padding="left"
                  border="bottom"
                  name="name"
                  type="text"
                  transform="capitalize"
                  onChange={onChange}
                />
              </Form.Item>
            </InputContainerNew>

            <GridContainer>
              <InputContainerNew
                icon={false}
                label="Unit of Measurement"
                required={true}
                bg="transparent"
              >
                <Form.Item
                  name="unitMeasurementId"
                  rules={[
                    {
                      required: true,
                      message: "Unit of Measurement is required",
                    },
                  ]}
                  className="w-full pt-2"
                >
                  <Select
                    className="w-full"
                    placeholder="Select unit of measurement"
                    onChange={(v: any) =>
                      setItemEdit({ ...itemEdit, unitMeasurementId: v })
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {measurementData?.map((type, index) => (
                      <Option key={index} value={type.id}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </InputContainerNew>

              <InputContainerNew
                icon={false}
                label="Category"
                required={true}
                bg="transparent"
              >
                <Form.Item
                  name="categoryId"
                  rules={[{ required: true, message: "Category is required" }]}
                  className="w-full pt-2"
                >
                  <Select
                    className="w-full"
                    placeholder="Select Category"
                    onChange={(v: any) =>
                      setItemEdit({ ...itemEdit, categoryId: v })
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {categoryData?.map((type, index) => (
                      <Option key={index} value={type.id}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </InputContainerNew>
            </GridContainer>

            <GridContainer>
              <InputContainerNew
                icon={false}
                label="Price"
                required={true}
                bg="transparent"
              >
                <Form.Item
                  name="price"
                  rules={[{ required: true, message: "Price is required" }]}
                  className="w-full"
                >
                  <Inputs
                    padding="left"
                    border="bottom"
                    name="price"
                    type="text"
                    transform="capitalize"
                    onChange={(e: any) =>
                      setItemEdit({
                        ...itemEdit,
                        price: Number(e.target.value),
                      })
                    }
                    maxLength={5}
                    onKeyDown={onlyNumberKey}
                  />
                </Form.Item>
              </InputContainerNew>

              <InputContainerNew
                icon={false}
                label="Quantity"
                required={true}
                bg="transparent"
              >
                <Form.Item
                  name="quantity"
                  rules={[{ required: true, message: "Quantity is required" }]}
                  className="w-full"
                >
                  <Inputs
                    padding="left"
                    border="bottom"
                    name="quantity"
                    type="text"
                    transform="capitalize"
                    onChange={(e: any) =>
                      setItemEdit({
                        ...itemEdit,
                        quantity: Number(e.target.value),
                      })
                    }
                    maxLength={5}
                    onKeyDown={onlyNumberKey}
                  />
                </Form.Item>
              </InputContainerNew>
            </GridContainer>
            <div className="flex justify-between border border-grey-2 my-2 mx-2 p-4 rounded">
              <Span color="grey" margin="my-auto">
                Status
              </Span>
              <Form.Item
                className="mb-0"
                name="status"
                valuePropName={
                  itemEditDetails?.status === "active" ? "checked" : ""
                }
              >
                <Switch
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  onChange={(v) =>
                    setItemEdit({
                      ...itemEdit,
                      status: v ? "active" : "inactive",
                    })
                  }
                />
              </Form.Item>
            </div>

            <div className="flex justify-end">
              <Button
                bg="white"
                color="grey"
                width="none"
                margin="mx-4"
                padding="none"
                onClick={() => setEditModals(false)}
              >
                Cancel
              </Button>
              <Button
                bg="orange"
                width="none"
                padding="px-6"
                margin="mx-4"
                onClick={form.submit}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
