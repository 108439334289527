/* eslint-disable react-hooks/exhaustive-deps */
import { createOrder } from "@api";
import { Button, ModalContainer, Span } from "@common";
import { Dispatch, RootState } from "@store";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import createIcon from "@assets/create.png";

type Props = {
  setCreateModal: Function;
};

export const CreateModal = ({ setCreateModal }: Props) => {
  const dispatch = useDispatch<Dispatch>();

  const history = useHistory();

  const orderData: any = useSelector(
    (state: RootState) => state.Ecommerce.orderIdReady
  );

  const handleCreate = async () => {
    try {
      const res = await createOrder({ orderId: orderData });
      if (res) {
        dispatch.UI.setAlert({
          message: "Order Created!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "Unable create order!",
        type: "Error",
      });
    }
    dispatch.Ecommerce.updateState({ orderId: [], orderIdReady: 0 });
    setCreateModal(false);
    history.push("/ecommerce/order?tab=qouted");
  };

  return (
    <>
      <ModalContainer bg="grey">
        <div className="px-8 py-5 whitespace-normal text-center ">
          <div className="mx-auto my-4">
            <img src={createIcon} alt={createIcon} className="mx-auto my-2" />
            <Span color="orange" size="xl" weight="semi">
              Create Order
            </Span>
          </div>
          <Span size="base">Are you sure you want to create order?</Span>

          <div className="flex justify-center">
            <Button
              bg="white"
              color="grey"
              width="none"
              margin="m-4"
              padding="px-10"
              onClick={() => {
                setCreateModal(false);
              }}
            >
              CANCEL
            </Button>
            <Button
              bg="orange"
              width="none"
              padding="px-10"
              onClick={() => handleCreate()}
            >
              CREATE
            </Button>
          </div>
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
