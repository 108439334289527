/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Container, Span } from "@common";
import { COLUMNS } from "./Table/column";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { Loader, NoData, TableWithPagination } from "@components";

import { AddItemModal } from "./Modal/AddItem";
import { CategoryModal } from "./Modal/CategoryModal";
import { MeasurementModal } from "./Modal/MeasurementModal";

import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useHistory } from "react-router-dom";
import { AiOutlineFileText, AiOutlineUpload, IoMdArrowRoundBack } from "@icons";
import moment from "moment";
import { downloadExcelFile } from "@utils";
import { downloadProductFile, uploadProductList } from "@api";
import { Upload } from "antd";
import { useDispatch } from "react-redux";
import { Dispatch } from "@store";

let PRODUCT_UNSUBSCRIBE: any = null;
export const Supplier = ({ id }: any) => {
  const history = useHistory();
  const columns = useMemo(() => COLUMNS, []);
  const dispatch = useDispatch<Dispatch>();
  const [isLoading, setLoading] = useState(false);

  const [addItemModals, setAddItemModals] = useState(false);
  const handleAddItemModal = (e) => {
    e.preventDefault();
    setAddItemModals(true);
  };

  const [categoryModal, setCategoryModal] = useState(false);
  const [measurementModal, setMeasurementModal] = useState(false);

  const handleCategoryModal = (e) => {
    e.preventDefault();
    setCategoryModal(true);
  };

  const handleMeasurementModal = (e) => {
    e.preventDefault();
    setMeasurementModal(true);
  };

  const [productData, setProductData] = useState([] as any);

  const db = getFirestore();
  const listenToProduct = async () => {
    const q = query(
      collection(db, "store_supplier_product"),
      where("storeId", "==", id)
    );

    PRODUCT_UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || []).map((ref) => {
        const data = ref.data();
        return data;
      }) as any[];
      setProductData(
        listData?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      );
    });
  };

  useEffect(() => {
    listenToProduct();

    return function () {
      try {
        if (PRODUCT_UNSUBSCRIBE) PRODUCT_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };
  }, []);

  const handleBack = () => {
    history.push(`/supplier/setup`);
  };

  const handledownloadFile = async () => {
    try {
      const response = await downloadProductFile(id);
      downloadExcelFile({
        response,
        name: `Supplier_ProductList_${moment(new Date()).format(
          "MMDDYYYY_HHmm"
        )}`,
      });
    } catch (err) {
      throw err;
    }
  };

  const uploadProductsList = async ({ file }) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);

      const res = await uploadProductList(id, formData); 
      if (res) {
        dispatch.UI.setAlert({
          message: "Product successfully uploaded!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message:
          err?.response?.data?.message?.Products ??
          "Unable to add the product!",
        type: "Error",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <div className="flex">
        <div onClick={handleBack} className="cursor-pointer">
          <Span color="grey">Supplier / </Span>
        </div>
        <div className="pl-1">
          <Span> Item Creation</Span>
        </div>
      </div>
      {!isLoading ? (
        <Container padding="p-4">
          <div className="flex justify-between">
            <div>
              <Button
                width="none"
                bg="orange"
                padding="none"
                margin="none"
                onClick={handleBack}
              >
                <div className="flex p-2 team-addMember">
                  <IoMdArrowRoundBack className="my-auto text-semibold" />
                  <Span margin="mx-1" color="white" weight="semi">
                    Back
                  </Span>
                </div>
              </Button>

              <Button
                width="none"
                bg="orange"
                padding="none"
                margin="mx-4"
                onClick={handleAddItemModal}
              >
                <div className="flex p-2 team-addMember">
                  <BiPlus className="my-auto text-semibold" />
                  <Span margin="mx-1" color="white" weight="semi">
                    Add Item
                  </Span>
                </div>
              </Button>
            </div>

            <div>
              <div>
                <Upload
                  beforeUpload={() => false}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={uploadProductsList}
                  showUploadList={false}
                  name="upload-products"
                >
                  <Button width="none" padding="none" margin="mx-4">
                    <div className="flex p-2 team-addMember">
                      <AiOutlineUpload className="my-auto text-semibold" />
                      <Span margin="mx-1" color="white" weight="semi">
                        Update Products
                      </Span>
                    </div>
                  </Button>
                </Upload>

                <Button
                  width="none"
                  padding="none"
                  margin="none"
                  onClick={handledownloadFile}
                >
                  <div className="flex p-2 team-addMember">
                    <AiOutlineFileText className="my-auto text-semibold" />
                    <Span margin="mx-1" color="white" weight="semi">
                      Download List
                    </Span>
                  </div>
                </Button>

                <Button
                  width="none"
                  bg="orange"
                  padding="none"
                  margin="mx-4"
                  onClick={handleCategoryModal}
                >
                  <div className="flex p-2 team-addMember">
                    <BiPlus className="my-auto text-semibold" />
                    <Span margin="mx-1" color="white" weight="semi">
                      Add Category
                    </Span>
                  </div>
                </Button>

                <Button
                  width="none"
                  bg="orange"
                  padding="none"
                  margin="none"
                  onClick={handleMeasurementModal}
                >
                  <div className="flex p-2">
                    <BiPlus className="my-auto text-semibold" />
                    <Span margin="mx-1" color="white" weight="semi">
                      Add Measurement
                    </Span>
                  </div>
                </Button>
              </div>
            </div>
          </div>
          {!productData?.length ? (
            <NoData />
          ) : (
            <TableWithPagination
              columns={columns}
              data={productData}
              tableClass="table-content-center"
            />
          )}
        </Container>
      ) : (
        <div className="flex h-screen justify-center">
          <Loader />
        </div>
      )}

      {addItemModals && (
        <AddItemModal setAddItemModals={setAddItemModals} storeId={id} />
      )}

      {categoryModal && (
        <CategoryModal setCategoryModal={setCategoryModal} storeId={id} />
      )}
      {measurementModal && (
        <MeasurementModal
          setMeasurementModal={setMeasurementModal}
          storeId={id}
        />
      )}
    </>
  );
};
