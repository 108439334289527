import { MainContainer } from "@common";
import { Navbar, SideMenu } from "@components";
import { BRAND_IMAGES } from "@constant";
import { EcomOrder } from "@containers";

export const EcomOrderPage = () => {
  return (
    <>
      <Navbar title="Ecommerce Order" icon={BRAND_IMAGES["HEADER_LOGO"]} />
      <SideMenu />
      <MainContainer>
        <EcomOrder />
      </MainContainer>
    </>
  );
};
