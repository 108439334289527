/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Span, Button } from "@common";
import { Loader, TableWithPagination } from "@components";
import { MdAddLocationAlt, MdKeyboardArrowRight } from "@icons";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@store";
import "../Ecom.scss";

import { COLUMNS } from "./Table/column";
import { PickupLocationModal } from "./Modal/PickupLocationModal";
import { UpdatePickupLocationModal } from "./Modal/UpdatePickupLocationModal";
import { getListPickupLocation } from "@api";

export const PickupLocation = ({ id }: any) => {
  const column = useMemo(() => COLUMNS, []);
  const user: any = useSelector((state: RootState) => state.User.userInfo);

  const [isLoading, setIsLoading] = useState(false);
  const [pickupLocationModal, setPickupLocationModal] = useState(false);
  const handleAddPickupLocation = (e) => {
    e.preventDefault();
    setPickupLocationModal(true);
  };
  const [data, setData] = useState([] as any);

  const getPickupLocation = async () => {
    setIsLoading(true);
    try {
      const res = await getListPickupLocation();
      const pickupData = res[0]?.filter((i) => i?.userId === user?.userId);
      setData(pickupData);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getPickupLocation();
  }, []);

  return (
    <>
      <div className="flex mb-4">
        <Span>Ecommerce </Span> <MdKeyboardArrowRight />{" "}
        <Span weight="bold">Pickup Location </Span>
      </div>

      <Container padding="p-4">
        {" "}
        {!isLoading ? (
          <>
            <div className="flex justify-start">
              <Button
                width="none"
                bg="orange"
                padding="none"
                margin="none"
                onClick={handleAddPickupLocation}
              >
                <div className="flex p-2 team-addMember">
                  <MdAddLocationAlt className="my-auto text-semibold" />
                  <Span margin="mx-1" color="white" weight="semi">
                    Add Pickup Locations
                  </Span>
                </div>
              </Button>
            </div>

            <TableWithPagination
              columns={column}
              data={data}
              dataType="ecom-pickup"
              tableClass="table-content"
            />
          </>
        ) : (
          <div className="p-32">
            <Loader />
          </div>
        )}
      </Container>

      {pickupLocationModal && (
        <PickupLocationModal
          setPickupLocationModal={setPickupLocationModal}
          getPickupLocation={getPickupLocation}
        />
      )}

      {id && (
        <UpdatePickupLocationModal
          id={id}
          getPickupLocation={getPickupLocation}
        />
      )}
    </>
  );
};
