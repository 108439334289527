import { BRAND_IMAGES } from "@constant"; 
 
import { Link } from "react-router-dom";
import { Button, Span, CardContainer, Container, Inputs } from "@common";

export const ForgetPassword = () => { 
  
  return (
    <>
      <CardContainer> 
        <img src={BRAND_IMAGES["BRAND_LOGO"]} alt="AEG Logo" className="brand-logo"/>

        <Container margin="my-8" align="center">
          <p>
            <Span weight="semi">
              Please enter your email address.
            </Span>
          </p>
          <p>
            <Span weight="semi">
              You will receive a link to create a new password.
            </Span>
          </p>
        </Container>
        
        <Container className="index" margin='mt-4'>  
          <Inputs
            border="bottom"
            placeholder="Email"
            type="email" 
            required
            autoFocus
          />
          <span className="bar"></span>
        </Container>   
        <Link to="../forget-password-confirmation">
          <Button margin="top">
            Get New Password
          </Button>
        </Link>

        <div className="text-center mt-3">
          <Link to="/">
            <Span color='blue'>Back to Login</Span>
          </Link>
        </div>  
      </CardContainer>
    </>
  );
};
