/* eslint-disable react-hooks/exhaustive-deps */
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
} from "firebase/firestore";
import { useEffect, useState } from "react";

let CATEGORY_UNSUBSCRIBE: any = null;
export const Category = ({ categoryId }: any) => {
  const [categoryData, setCategoryData] = useState([] as any);

  const db = getFirestore();
  const listenToCategory = async () => {
    const q = query(collection(db, "store_supplier_category"));

    CATEGORY_UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || []).map((ref) => {
        const data = ref.data();
        return data;
      }) as any[];
      setCategoryData(listData);
    });
  };

  useEffect(() => {
    listenToCategory();

    return function () {
      try {
        if (CATEGORY_UNSUBSCRIBE) CATEGORY_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };
  }, []);

  const category = categoryData?.filter((s) => s?.id === categoryId);

  return <>{category[0]?.name}</>;
};
