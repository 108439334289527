/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, ModalContainer, Span } from "@common";
import { InputContainerNew, Loader } from "@components";
import { Form, Input, Tag, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { IMeasurement } from "@types";
import MeasurementIcon from "@assets/measurement.png";
import { Dispatch } from "@store";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useDispatch } from "react-redux";
import { addMeasurement, deleteMeasurement } from "@api";

type Props = {
  setMeasurementModal: Function;
  storeId: string;
};

let MEASUREMENT_UNSUBSCRIBE: any = null;
export const MeasurementModal = ({ setMeasurementModal, storeId }: Props) => {
  const [measurement, setMeasurement] = useState<Array<IMeasurement>>([]);
  const [measurementData, setMeasurementData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch<Dispatch>();

  const db = getFirestore();
  const listenToMeasurement = async () => {
    setIsLoading(true);
    const q = query(
      collection(db, "store_supplier_unit_measurement"),
      where("storeId", "==", storeId)
    );

    MEASUREMENT_UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || []).map((ref) => {
        const data = ref.data();
        return data;
      }) as any[];
      setMeasurementData(listData);
    });
    setIsLoading(false);
  };

  useEffect(() => {
    listenToMeasurement();

    return function () {
      try {
        if (MEASUREMENT_UNSUBSCRIBE) MEASUREMENT_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };
  }, []);

  useEffect(() => {
    setFormValues();
  }, [measurement]);

  const setFormValues = useCallback(() => {
    form.setFieldsValue({
      measurementName: undefined,
      measurementAbbreviation: undefined,
    });
    setMeasurement(measurementData || []);
  }, [measurement]);

  const handleAddMeasurement = async () => {
    try {
      setIsLoading(true);
      const { measurementName, measurementAbbreviation } =
        await form.validateFields();

      await addMeasurement(storeId, {
        name: measurementName,
        abbreviation: measurementAbbreviation,
      });
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "Unable add measurement!",
        type: "Error",
      });
    }
    form.setFieldsValue({
      measurementName: undefined,
      measurementAbbreviation: undefined,
    });
    setIsLoading(false);
  };

  const handleDelete = async (item) => {
    try {
      const res = await deleteMeasurement(storeId, item);
      if (res) {
        dispatch.UI.setAlert({
          message: "Measurement deleted!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "Unable delete measurement!",
        type: "Error",
      });
    }
  };

  const renderTag = useCallback((measure: IMeasurement) => {
    return (
      <span className="capitalize">
        <>
          <Typography.Link>{measure.abbreviation}</Typography.Link>
          <br />
          {measure.name}
        </>
      </span>
    );
  }, []);

  return (
    <>
      <ModalContainer>
        <Form form={form}>
          <div className="p-10 py-5 whitespace-normal ">
            <div className="mx-auto text-center my-4">
              <img
                src={MeasurementIcon}
                alt="Measurement Icon"
                className="mx-auto my-2 h-13"
              />
              <Span color="orange" size="base" weight="semi">
                Measurement
              </Span>
            </div>

            <InputContainerNew
              icon={false}
              label="Unit of Measurement"
              required={true}
              bg="transparent"
            >
              <Form.Item className="config-form" name="measurementName">
                <Input placeholder="Unit of Measurement" className="" />
              </Form.Item>
            </InputContainerNew>

            <InputContainerNew
              icon={false}
              label="Abbreviation"
              required={true}
              bg="transparent"
            >
              <Form.Item className="config-form" name="measurementAbbreviation">
                <Input placeholder="Abbreviation" className="" />
              </Form.Item>
            </InputContainerNew>

            <Form.Item
              noStyle
              shouldUpdate={(prevValue, currentValue) =>
                prevValue["measurementName"] !==
                  currentValue["measurementName"] ||
                prevValue["measurementAbbreviation"] !==
                  currentValue["measurementAbbreviation"]
              }
            >
              {({ getFieldValue }) => (
                <Button
                  bg="orange"
                  width="none"
                  padding="px-6"
                  margin="mx-2"
                  disabled={
                    !getFieldValue("measurementName") ||
                    !getFieldValue("measurementAbbreviation")
                  }
                  onClick={handleAddMeasurement}
                >
                  Add Measurement
                </Button>
              )}
            </Form.Item>

            {!isLoading ? (
              measurementData.length > 0 && (
                <div className="card-scroll">
                  {measurementData.map((item) => (
                    <Tag
                      className=" m-1"
                      color="processing"
                      key={item.name}
                      closable
                      onClose={() => handleDelete(item?.id)}
                    >
                      {renderTag(item)}
                    </Tag>
                  ))}
                </div>
              )
            ) : (
              <div className="flex justify-center bg-white">
                <Loader />
              </div>
            )}

            <div className="flex justify-end">
              <Button
                bg="orange"
                width="none"
                padding="px-6"
                margin="mx-4"
                onClick={() => setMeasurementModal(false)}
              >
                Close
              </Button>
            </div>
          </div>
        </Form>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
