import React, { useEffect, useRef } from "react";

export const Checkbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef: any = useRef(null);
    const resolveRef = ref || defaultRef;

    useEffect(() => {
      resolveRef.current.indeterminate = indeterminate;
    }, [resolveRef, indeterminate]) as any;

    return (
      <>
        <div className="flex justify-center">
          <input type="checkbox" ref={resolveRef} {...rest} />
        </div>
      </>
    );
  }
);
