import { Measurement, Category } from "@components";
import StatusSupplier from "./StatusSupplier";
import { Span } from "@common";

export const COLUMNS = [
  {
    accessor: "image",
    Header: "Image",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center column-order-img">
          <img src={d.image} alt={d.name} className="column-img" />
        </div>
      );
    },
  },
  {
    accessor: "name",
    Header: "Name",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span className="text-center capitalize">{d?.name}</span>
        </div>
      );
    },
  },
  {
    accessor: "unitMeasurementId",
    Header: "Unit of Measurement",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <Measurement
          unitMeasurementId={d.unitMeasurementId}
          abbreviation={true}
        />
      );
    },
  },
  {
    accessor: "categoryId",
    Header: "Category",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <>
          <div className="text-center capitalize">
            <Span margin="auto">
              <Category categoryId={d.categoryId} />
            </Span>
          </div>
        </>
      );
    },
  },
  {
    accessor: "price",
    Header: "Price",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span className="text-center">{d?.price}</span>
        </div>
      );
    },
  },
  {
    accessor: "status",
    Header: "Status",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span className="text-center capitalize">{d?.status}</span>
        </div>
      );
    },
  },
  {
    accessor: " ",
    Header: "Action",
    isVisible: true,
    Cell: (data) => <StatusSupplier id={data?.row?.original?.id} />,
  },
];
