import { createModel } from "@rematch/core"; 
import { RootModel } from "."; 

interface ISalesOverview {
  activeTab: string; 
  restaurantId: string;
  services: string;
  filter: string;
  days: number; 
  dateRange: string
}

const initialState = { 
    activeTab: '', 
    restaurantId: '',
    services: '',
    filter: '',
    days: 30,
    dateRange: "", 
} as ISalesOverview;

export const SalesOverview = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState }
    },
    /**
     * @name resetForm
     * @description resets from by passing table property underform
     * @param table
     */ 
    updateState(state, newState: any) {
      return {
        ...state,
        ...newState
      };
    }, 
  }, 
});
