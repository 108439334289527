import * as React from "react";
import classnames from "classnames"; 

type Props = React.HTMLProps<HTMLSelectElement> & {
  children?: React.ReactNode;
  border?: "none";
  radius?: "rounded";
};

let getBorder = (border: Props["border"]) => {
  switch (border) {
    case "none":
      return "border-none";
    default:
      return "border-b border-grey-2";
  }
};

let getRadius = (radius: Props["radius"]) => {
  switch (radius) {
    case "rounded":
      return "rounded";
    default:
      return "rounded-none";
  }
}; 

export const Select = ({ radius, border, children, ...props }: Props) => {
  return (
    <select
      required 
      className={classnames(
        "focus:outline-none text-xs py-2 pl-2 pr-10",
        getBorder(border),
        getRadius(radius)
      )}
      {...props}
    >
      {children}
    </select>
  );
};
