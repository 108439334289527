import { MainContainer } from '@common';
import { Navbar, SideMenu} from '@components';
import { BRAND_IMAGES } from '@constant';
import {TeamDetailsAdd} from '@containers';

export const TeamDetailsAddPage = () => {
  return (
    <> 
      <Navbar title='Team Members' icon={BRAND_IMAGES["HEADER_LOGO"]}/>
      <SideMenu />
      <MainContainer > 
        <TeamDetailsAdd/>
      </MainContainer>
    </>
  )
}