/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container, Span } from "@common";
import { COLUMNS } from "./Table/column";
import { useEffect, useMemo, useState } from "react";
import { Loader, NoData, TableWithPagination } from "@components";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "@store";

let SUPPLIER_UNSUBSCRIBE: any = null;

export const ChooseSupplier = () => {
  const dispatch = useDispatch<Dispatch>();
  const columns = useMemo(() => COLUMNS, []);
  const [supplierData, setSupplierData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);

  const user: any = useSelector((state: RootState) => state.User.userInfo);

  const MYSTORES = user?.storeAccesses.map((elem: any) => {
    const storeId = elem?.storeId;
    return storeId;
  });

  const db = getFirestore();
  const listenToSupplier = async () => {
    const q = query(
      collection(db, "store_supplier"),
      where("supplierStatus", "==", "enabled")
    );

    SUPPLIER_UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || []).map((ref) => {
        const data = ref.data();
        return data;
      }) as any[];
      setSupplierData(listData);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    listenToSupplier();

    return function () {
      try {
        if (SUPPLIER_UNSUBSCRIBE) SUPPLIER_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };
  }, []);

  useEffect(() => {
    dispatch.Cart.resetState();
  });

  const data = supplierData
    ?.filter((i) => !MYSTORES.includes(i?.storeId))
    ?.map((s) => {
      return s;
    });

  return (
    <>
      <div>
        <Span color="grey">Supplier / </Span>
        <Span>Choose Supplier</Span>
      </div>
      <Container padding="p-4">
        {!isLoading ? (
          <>
            {!data?.length ? (
              <NoData />
            ) : (
              <TableWithPagination
                columns={columns}
                data={data}
                tableClass="table-content-center"
                dataType="choose-supplier"
              />
            )}
          </>
        ) : (
          <Loader />
        )}
      </Container>
    </>
  );
};
