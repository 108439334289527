/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container, Span } from "@common";
import { COLUMNS } from "./Table/column";
import { useEffect, useMemo, useState } from "react";
import { NoData, PopupInfo, TableWithPagination } from "@components";
import {
  collection,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "@store";

let ORDER_UNSUBSCRIBE: any = null;

export const PurchaseOrder = () => {
  const dispatch = useDispatch<Dispatch>();
  const columns = useMemo(() => COLUMNS, []);

  const [orderData, setOrderData] = useState([] as any);

  const user: any = useSelector((state: RootState) => state.User.userInfo);

  const MYSTORES = user?.storeAccesses.map((elem: any) => {
    const storeId = elem?.storeId;
    return storeId;
  });

  const db = getFirestore();
  const listenToOrder = async () => {
    const q = query(
      collection(db, "store_supplier_order"),
      orderBy("createdAt", "desc")
    );

    ORDER_UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || []).map((ref) => {
        const data = ref.data();
        return data;
      }) as any[];
      setOrderData(listData);
    });
  };

  const order = orderData
    ?.filter((i) => !MYSTORES.includes(i?.storeId))
    ?.map((s) => {
      return s;
    });

  dispatch.Table.setTableData({ table: "purchaseOrders", data: order });

  useEffect(() => {
    listenToOrder();

    return function () {
      try {
        if (ORDER_UNSUBSCRIBE) ORDER_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };
  }, []);

  useEffect(() => {
    dispatch.Cart.resetState();
  });

  return (
    <>
      <div className="flex">
        <Span color="grey">Order / </Span>
        <Span margin="mr-2">Purchase Order</Span>
        <Span margin="mt-0.5">
          <PopupInfo info={"Orders made in other stores"} />
        </Span>
      </div>

      <Container padding="p-4">
        <div className="flex justify-between">
          <div>
            <Span color="orange" size="lg" weight="bold">
              Orders
            </Span>
          </div>
        </div>
        {!order?.length ? (
          <NoData />
        ) : (
          <TableWithPagination
            columns={columns}
            data={order}
            tableClass="table-content"
            dataType="purchase-view"
          />
        )}
      </Container>
    </>
  );
};
