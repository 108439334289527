import { MainContainer } from "@common";
import { Navbar, SideMenu } from "@components";
import { BRAND_IMAGES } from "@constant";
import { PickupLocation } from "@containers";

import { useParams } from "react-router-dom";
export const PickupLocationPage = () => {
  let params: any = useParams();
  return (
    <>
      <Navbar
        title="Ecommerce Pickup Location"
        icon={BRAND_IMAGES["HEADER_LOGO"]}
      />
      <SideMenu />
      <MainContainer>
        <PickupLocation id={params.id} />
      </MainContainer>
    </>
  );
};
