/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";

import { Span, Button, GridContainer } from "@common";
import { MdKeyboardArrowRight } from "@icons";
import shopify from "../../../assets/shopify.png";
import "../Ecom.scss";
import { useHistory } from "react-router-dom";
import { getListEcommerce } from "@api";
import { Loader } from "@components";
import { EnabledModal } from "./Modal/Enabled";
import { DisabledModal } from "./Modal/Disabled";
import { Tag } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export const Integration = () => {
  const history = useHistory();

  const [listEcomShopify, setListEcomShopify] = useState({}) as any;
  const [isLoading, setIsLoading] = useState(false);
  const [ecomActive, setActiveEcom] = useState(false);
  const [shopifyId, setShopifyId] = useState();
  const [enabledModal, setEnabledModal] = useState(false);
  const [disabledModal, setDisabledModal] = useState(false);

  const getListEcom = async () => {
    setIsLoading(true);
    try {
      const res = await getListEcommerce();

      const shopifyServiceType = res[0]?.filter(
        (e: any) => e?.serviceType === "shopify"
      );

      setListEcomShopify(shopifyServiceType[0]);
      setShopifyId(shopifyServiceType[0].id);
      setActiveEcom(shopifyServiceType[0].active);
    } catch (err: any) {
      console.log(err?.response?.data?.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getListEcom();
  }, []);

  const handleEcom = () => {
    if (listEcomShopify) {
      history.push(`/ecommerce/integration/shopify/${shopifyId}`);
    } else {
      history.push(`/ecommerce/integration/shopify-add`);
    }
  };

  return (
    <>
      {!isLoading ? (
        <>
          <div className="flex mb-4">
            <Span>Ecommerce </Span> <MdKeyboardArrowRight />{" "}
            <Span weight="bold">Integration </Span>
          </div>

          <GridContainer grid="three">
            <div className="ecom">
              <div className="ecom-card">
                <div className="ecom-title">
                  <Span weight="bold" color="white" size="lg">
                    Shopify
                  </Span>
                </div>
                <div className="ecom-card-img cursor-pointer">
                  <img src={shopify} alt={shopify} onClick={handleEcom} />
                </div>
                {listEcomShopify ? (
                  <>
                    <div className="flex justify-center">
                      {ecomActive ? (
                        <Button
                          width="none"
                          bg="orange"
                          onClick={() => {
                            setDisabledModal(true);
                          }}
                        >
                          Enabled
                        </Button>
                      ) : (
                        <Button
                          width="none"
                          bg="grey"
                          onClick={() => {
                            setEnabledModal(true);
                          }}
                        >
                          Disabled
                        </Button>
                      )}
                    </div>
                  </>
                ) : (
                  <div
                    className="flex justify-center"
                    onClick={() =>
                      history.push(`/ecommerce/integration/shopify-add`)
                    }
                  >
                    <Button width="none" bg="orange">
                      Add
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </GridContainer>
        </>
      ) : (
        <div className="flex h-screen justify-center">
          <Loader />
        </div>
      )}

      {enabledModal && (
        <EnabledModal
          setEnabledModal={setEnabledModal}
          listEcomShopify={listEcomShopify}
          getListEcom={getListEcom}
        />
      )}

      {disabledModal && (
        <DisabledModal
          setDisabledModal={setDisabledModal}
          listEcomShopify={listEcomShopify}
          getListEcom={getListEcom}
        />
      )}
    </>
  );
};
