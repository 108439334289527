import { SubContainer } from "@common";
import { formatMonthDay } from "@utils";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

type Props = {
  net?: any;
};

export const NetSalesContent = ({ net }: Props) => {
  const total = [
    net?.sort((a, b) => a?.date - b?.date).map((e: any) => e?.total),
  ];
  const date = [net.map((e: any) => formatMonthDay(e?.date))];

  const data = {
    labels: date[0],
    datasets: [
      {
        label: "Completed",
        data: total[0],
        backgroundColor: ["#B1CB60"],
        borderColor: ["#fff"],
        maxBarThickness: 30, // number (pixels)
        barThickness: 20,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <>
      <SubContainer height="none">
        <div className="flex w-full">
          {/* <div className="m-2 w-full h-96 pl-1 pr-4 pb-2 pt-2"> */}
          <div className="m-2 w-full h-full pl-1 pr-4 pb-2 pt-2">
            <Bar data={data} options={options} />
          </div>
        </div>
      </SubContainer>
    </>
  );
};
