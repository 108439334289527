import { post } from "./core";

export const postSetPassword = async (accessToken: string, password: string, passwordConfirm: string, currentPassword?:string,) => {
  try {
    let res = await post(`/merchant-users/set-password`, {accessToken, password, passwordConfirm, currentPassword}); 
    return res.data; 
  } catch (err) {
    throw err;
  }
};
  

export const postAccessValidation = async (accessToken: string) => {
  try {
    let res = await post(`/merchant-users/validate-confirmation-link`, {accessToken});   
    return res.data; 
  } catch (err) {
    throw err;
  }
};