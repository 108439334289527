/* eslint-disable react-hooks/exhaustive-deps */
import { Span } from "@common";

import {
  collection,
  getFirestore,
  onSnapshot,
  query,
} from "firebase/firestore";
import { useEffect, useState } from "react";

let MEASUREMENT_UNSUBSCRIBE: any = null;
type Props = {
  unitMeasurementId: string;
  abbreviation?: boolean;
};
export const Measurement = ({ unitMeasurementId, abbreviation }: Props) => {
  const [measurementData, setMeasurementData] = useState([] as any);

  const db = getFirestore();
  const listenToMeasurement = async () => {
    const q = query(collection(db, "store_supplier_unit_measurement"));

    MEASUREMENT_UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || []).map((ref) => {
        const data = ref.data();
        return data;
      }) as any[];
      setMeasurementData(listData);
    });
  };

  useEffect(() => {
    listenToMeasurement();

    return function () {
      try {
        if (MEASUREMENT_UNSUBSCRIBE) MEASUREMENT_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };
  }, []);

  const measurement = measurementData?.filter(
    (s) => s?.id === unitMeasurementId
  );

  return (
    <div className="text-center capitalize">
      {abbreviation ? <Span margin="auto">{measurement[0]?.name} </Span> : ""}
      <Span margin="auto" color="grey">
        ({measurement[0]?.abbreviation})
      </Span>
    </div>
  );
};
