import { Button, ModalContainer, Span } from "@common";
import infoIcon from "@assets/info.png";
import { Dispatch } from "@store";
import { useDispatch } from "react-redux";
import { updatePurchaseOrderStatus } from "@api";
import { ORDER_STATUS } from "@constant";

type Props = {
  setChangeStatusModal: Function;
  id: string;
  purchaseStatus: string;
  getOrderData: Function;
};

export const ChangeStatusModal = ({
  setChangeStatusModal,
  id,
  purchaseStatus,
  getOrderData,
}: Props) => {
  const dispatch = useDispatch<Dispatch>();

  const handleCancelDelivery = async () => {
    try {
      const res = await updatePurchaseOrderStatus(id, {
        status: purchaseStatus,
      });
      if (res) {
        dispatch.UI.setAlert({
          message: "Change status successfully!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "Unable change status!",
        type: "Error",
      });
    }
    setChangeStatusModal(false);
    getOrderData();
  };

  return (
    <>
      <ModalContainer bg="grey">
        <div className="px-8 py-5 whitespace-normal text-center ">
          <div className="mx-auto my-4">
            <img src={infoIcon} alt={infoIcon} className="mx-auto my-2" />
            <Span color="orange" size="xl" weight="semi">
              Change order status
            </Span>
          </div>
          <Span size="base">
            Are you sure you want to change the status to{" "}
            <b>{ORDER_STATUS[purchaseStatus]}</b>?
          </Span>

          <div className="flex justify-center">
            <Button
              bg="white"
              color="grey"
              width="none"
              margin="m-4"
              padding="px-10"
              onClick={() => {
                setChangeStatusModal(false);
              }}
            >
              CLOSE
            </Button>
            <Button
              bg="orange"
              width="none"
              padding="px-10"
              onClick={handleCancelDelivery}
            >
              OK
            </Button>
          </div>
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
