import ActionSupplier from "./ActionSupplier";

export const COLUMNS = [
  {
    accessor: "storeName",
    Header: "Store Name",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span className="text-center">{d?.storeName}</span>
        </div>
      );
    },
  },
  // {
  //   accessor: "operationStatus",
  //   Header: "Operation Status",
  //   isVisible: true,
  //   Cell: (data) => {
  //     const d = data?.row?.original?.store;
  //     return (
  //       <div className="truncate text-center capitalize">
  //         <span>{d?.operationStatus}</span>
  //       </div>
  //     );
  //   },
  // },
  {
    accessor: " ",
    Header: "Action",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return <ActionSupplier storeId={d?.storeId} storeName={d?.storeName} />;
    },
  },
];
