import { SubContainer } from "@common";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ORDER_METHOD_STATUS } from "@constant";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

type Props = {
  orderMethodData?: any;
  exportActive?: any;
};

export const OrderMethod = ({ exportActive, orderMethodData }: Props) => {
  const title = [
    orderMethodData?.map((e: any) => ORDER_METHOD_STATUS[e?.orderMethod]),
  ];
  const total = [orderMethodData?.map((e: any) => e?.total)]; 
  const data = {
    labels: title,
    datasets: [
      {
        label: "",
        data: total,
        backgroundColor: ["#C1272D", "#FF7F14"],
        borderColor: ["#fff"],
      },
    ],
  };

  const options = {
    showAllTooltips: true,
    responsive: false,
    layout: {
      padding: {
        top: 15,
        bottom: 6,
      },
      margin: "auto",
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false, //Label in y-axis
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: "#000",
        align: "top",
        anchor: "end",
        offset: 1,
        font: {
          size: 12,
          weight: "bold",
          family: "Montserrat",
          lineHeight: 0.5,
        },
      },
      tooltip: {
        enabled: false, // <-- this option disables tooltips
      },
    },
  } as any;

  return (
    <>
      <SubContainer height={"h-60"}>
        <div className="flex w-full my-auto">
          <div className="my-2 w-full h-full">
            <Bar
              data={data}
              options={options}
              plugins={[ChartDataLabels]}
              className="mx-auto mt-2"
            />
          </div>
        </div>
      </SubContainer>
    </>
  );
};
