/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { SubMenu } from "./SubMenu";

export const Menu = ({ item }: { item: any }) => {
  const [subnav, setSubnav] = useState(false);
  const [pathname, setPathname] = useState("/");
  const location = useLocation();

  const showSubnav = () => {
    setSubnav(!subnav);
  };

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  return (
    <>
      <li onClick={item.subNav && showSubnav}>
        <Link
          to={item?.path}
          className={
            item.subNav
              ? null
              : pathname === item.path?.split("?")[0]
              ? "active-link"
              : null
          }
        >
          <div className="flex justify-between">
            <div className="side-menu flex">
              {pathname === item.path ? item.iconActive : item.icon}
              <span>{item.title}</span>
            </div>

            <div className="my-auto">
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                ? item.iconClosed
                : null}
            </div>
          </div>
        </Link>
      </li>

      <ul className="submenu">
        {subnav &&
          item?.subNav?.map((item: any, index: any) => {
            return <SubMenu item={item} key={index} />;
          })}
      </ul>
    </>
  );
};
