import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const SubMenu = ({ item }: any) => {
  const { pathname } = useLocation();
  return (
    <li>
      <Link
        to={item?.path}
        className={
          pathname?.search(item?.path?.split("?")[0]) > -1
            ? "active-link"
            : null
        }
      >
        <span>{item.title}</span>
      </Link>
    </li>
  );
};
