type Props = {   
    children: React.ReactNode, 
    title?: string
}

export const TabContent = ({ title, children }: Props) => { 
    return ( 
        <div className="relative p-1 rounded-md"> 
            <div className="m-1"> 
                <p className="px-1.5">{title}</p>
            </div> 
            {children} 
        </div>
    );
}   