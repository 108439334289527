import { Route, RouteProps, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";

import { RootState } from "@store";

interface MyRouteProps extends RouteProps {
  component: any;
  path: any;
  exact: boolean;
}

const LoginRoute: React.FC<MyRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { shouldRedirectToLogin, isUserLoggedIn } = useSelector(
    (state: RootState) => state.User
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        isUserLoggedIn ? (
          <Redirect
            to={{
              pathname: "/sales-overview",
              state: { from: props.location },
            }}
          />
        ) : (
          <>
            {shouldRedirectToLogin ? (
              <Component {...props} />
            ) : (
              <Component {...props} />
            )}
          </>
        )
      }
    />
  );
};

export default LoginRoute;
