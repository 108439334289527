import { MainContainer } from "@common";
import { Navbar, SideMenu } from "@components";
import { BRAND_IMAGES } from "@constant";
import { ChooseSupplier } from "@containers";

export const ChooseSupplierPage = () => {
  return (
    <>
      <Navbar title="Supplier" icon={BRAND_IMAGES["HEADER_LOGO"]} />
      <SideMenu />
      <MainContainer>
        <ChooseSupplier />
      </MainContainer>
    </>
  );
};
