export const COLUMNS = [
  {
    accessor: "storeName",
    Header: "Store Name",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span className="text-center">{d?.storeName}</span>
        </div>
      );
    },
  },
  {
    accessor: "address",
    Header: "Address",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="truncate flex justify-center">
          <span className="text-center capitalize">{d?.address}</span>
        </div>
      );
    },
  },
  {
    accessor: "contactName",
    Header: "Contact Name",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span className="text-center capitalize">{d?.contactName}</span>
        </div>
      );
    },
  },
  {
    accessor: "contactNumber",
    Header: "Contact Number",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span className="text-center capitalize">{d?.contactNumber}</span>
        </div>
      );
    },
  },
  {
    accessor: "email",
    Header: "Email",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center text-center" >
          <span className={d?.email ? "text-center" : "text-grey-1" } >{d?.email ? d?.email : "No Data"}</span> 
        </div>
      );
    },
  },
];
