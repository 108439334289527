import { createModel } from "@rematch/core"; 
import { DependenciesRestaurantType } from "@types";
import { RootModel } from "."; 

interface ITeam { 
  restaurantId: string;  
  dependenciesRestaurants: DependenciesRestaurantType[];
  dependenciesStores: DependenciesRestaurantType[];
}

const initialState = {  
    restaurantId: '',  
    dependenciesRestaurants: [],
    dependenciesStores: [],
} as ITeam;

export const Team = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState }
    },
    /**
     * @name resetForm
     * @description resets from by passing table property underform
     * @param table
     */ 
    updateState(state, newState: any) {
      return {
        ...state,
        ...newState
      };
    },
  }, 
});
