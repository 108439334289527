import * as React from 'react';
import classnames from 'classnames'

type Props =  {
  children?: React.ReactNode, 
  color?: 'blue' | 'white' | 'orange',
  weight?:'normal' | 'semi' ,
  size?: 'xl', 
  margin?: 'auto' | 'mb-4'
}

let getColor = (color: Props['color']) => {
    switch (color) {
    case 'orange': 
      return 'text-primary-main';
    case 'white': 
      return 'text-white';
    case 'blue':
      return 'text-blue-1';
    default:
      return 'text-black';
  }
} 

let getFontSize = (size: Props['size']) => {
  switch (size) {   
    case 'xl':
      return 'text-xl';  
    default:
      return 'text-2xl';
  }
} 

let getFontWeight = (weight: Props['weight']) => {
    switch (weight) {   
      case 'semi':
        return 'font-semibold';
      case 'normal':
        return 'font-normal';
      default:
        return 'font-bold';
    }
  }  

let getMargin = (margin: Props["margin"]) => {
  switch (margin) { 
    case "mb-4":
      return "mb-4"; 
    case "auto":
      return "my-auto"; 
    default:
      return "my-1";
  }
};

export const Header = ({children, color, weight, size, margin }: Props) => { 
    return <h1 className={classnames(getColor(color), getFontWeight(weight), getFontSize(size),
            getMargin(margin)
            )}>{children}
            </h1>
}