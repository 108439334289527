import { GenerateStatusDesign } from "@components";
import { ORDER_STATUS } from "@constant";

export const COLUMNS = [
  {
    accessor: "name",
    Header: "Store Name",
    isVisible: true,
    Cell: (data) => {
      const storeName = data?.row?.original?.store?.name;
      return (
        <div className="flex justify-center">
          <span className="text-center">{storeName}</span>
        </div>
      );
    },
  },
  {
    accessor: "totalUnit",
    Header: "Total Unit",
    isVisible: true,
    Cell: (data) => {
      const totalUnit = data?.row?.original?.totalUnit;
      return (
        <div className="flex justify-center">
          <span className="text-center">{totalUnit}</span>
        </div>
      );
    },
  },
  {
    accessor: "totalAmount",
    Header: "Grand Amount",
    isVisible: true,
    Cell: (data) => {
      const amount = data?.row?.original?.totalAmount;
      return (
        <div className="flex justify-center">
          {`₱${parseFloat(amount)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}
        </div>
      );
    },
  },
  {
    accessor: "status",
    Header: "Status",
    isVisible: true,
    Cell: (data) => {
      const status = data?.row?.original?.status;
      return (
        <div className="flex justify-center">
          <GenerateStatusDesign status={status} label={ORDER_STATUS[status]} />
        </div>
      );
    },
  },
];
