export const HEADERS_SALESREPORT = [
    { label: "Hour", key: "hour" },
    { label: "Date", key: "date" },
    { label: "Total", key: "total" }, 
    { label: "Count", key: "count" }, 
];
  
export const TIME = [ 
    "23", 
    "22", 
    "21", 
    "20", 
    "19", 
    "18", 
    "17", 
    "16", 
    "15", 
    "14", 
    "13", 
    "12", 
    "11", 
    "10", 
    "09", 
    "08", 
    "07", 
    "06", 
    "05", 
    "04", 
    "03", 
    "02", 
    "01", 
    "00"
]