import { MainContainer } from '@common';
import {Navbar, SideMenu} from '@components';
import { BRAND_IMAGES } from '@constant';
import {Team} from '@containers';

export const TeamPage = () => {
  return (
    <> 
      <Navbar title='Team Members' icon={BRAND_IMAGES["HEADER_LOGO"]}/>
      <SideMenu />
      <MainContainer > 
        <Team />
      </MainContainer>
    </>
  )
}