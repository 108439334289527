import React from "react";
import { Button, ModalContainer, Span } from "@common";
import { useDispatch } from "react-redux";
import { updateEcomm } from "@api";
import { decryptData } from "@utils";
import infoIcon from "@assets/info.png";
import { useHistory } from "react-router-dom";
import { Dispatch } from "@store";

type Props = {
  setEnabledModal: Function;
  getListEcom: Function;
  listEcomShopify: any;
};

export const EnabledModal: React.FC<Props> = ({
  setEnabledModal,
  listEcomShopify,
  getListEcom,
}: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();

  const handleUpdate = async () => {
    try {
      const res = await updateEcomm({
        id: listEcomShopify.id,
        active: true,
      });
      if (res) {
        dispatch.UI.setAlert({
          message: "Shopify Account updated!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "Unable to update Shopify account!",
        type: "Error",
      });
    }

    setEnabledModal(false);
    getListEcom();
    history.push("/ecommerce/integration");
  };

  const closeModal = () => {
    setEnabledModal(false);
  };

  return (
    <>
      <ModalContainer bg="grey">
        <div className="px-8 py-5 whitespace-normal text-center ">
          <div className="mx-auto my-4">
            <img src={infoIcon} alt={infoIcon} className="mx-auto my-2" />
            <Span color="orange" size="xl" weight="semi">
              Enable Shopify
            </Span>
          </div>
          <Span size="base">Are you sure you want to enable Shopify?</Span>

          <div className="flex justify-center">
            <Button
              bg="white"
              color="grey"
              width="none"
              margin="m-4"
              padding="px-10"
              onClick={closeModal}
            >
              CANCEL
            </Button>
            <Button
              bg="orange"
              width="none"
              padding="px-10"
              onClick={handleUpdate}
            >
              ENABLE
            </Button>
          </div>
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
