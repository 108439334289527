/* eslint-disable react-hooks/exhaustive-deps */
import { Button, ModalContainer, Span } from "@common";
import { InputContainerNew, Loader } from "@components";
import { Form, Input, Tag, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { ICategory } from "@types";
import CategoryIcon from "@assets/category.png";
import { Dispatch } from "@store";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useDispatch } from "react-redux";
import { addCategory, deleteCategory } from "@api";

type Props = {
  setCategoryModal: Function;
  storeId: string;
};

let CATEGORY_UNSUBSCRIBE: any = null;
export const CategoryModal = ({ setCategoryModal, storeId }: Props) => {
  const [category, setCategory] = useState<Array<ICategory>>([]) as any;
  const [categoryData, setCategoryData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    setFormValues();
  }, [category]);

  const setFormValues = useCallback(() => {
    form.setFieldsValue({
      categoryName: undefined,
      categoryDescription: undefined,
    });
    setCategory(categoryData || []);
  }, [category]);

  const db = getFirestore();
  const listenToCategory = async () => {
    setIsLoading(true);
    const q = query(
      collection(db, "store_supplier_category"),
      where("storeId", "==", storeId)
    );

    CATEGORY_UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || []).map((ref) => {
        const data = ref.data();
        return data;
      }) as any[];
      setCategoryData(listData);
    });
    setIsLoading(false);
  };

  useEffect(() => {
    listenToCategory();

    return function () {
      try {
        if (CATEGORY_UNSUBSCRIBE) CATEGORY_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };
  }, []);

  const handleAddCategory = async () => {
    try {
      setIsLoading(true);
      const { categoryName, categoryDescription } = await form.validateFields();

      await addCategory(storeId, {
        name: categoryName,
        description: categoryDescription,
      });
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "Unable add category!",
        type: "Error",
      });
    }
    form.setFieldsValue({
      categoryName: undefined,
      categoryDescription: undefined,
    });
    setIsLoading(false);
  };

  const handleDelete = async (item) => {
    try {
      const res = await deleteCategory(storeId, item);
      if (res) {
        dispatch.UI.setAlert({
          message: "Category deleted!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "Unable delete category!",
        type: "Error",
      });
    }
  };

  const renderTag = useCallback((categories: ICategory) => {
    return categories?.description ? (
      <span className="capitalize">
        <Typography.Link>{categories.name}</Typography.Link>
        <br />
        {categories?.description}
      </span>
    ) : (
      <span className="capitalize">
        <Typography.Link>{categories.name}</Typography.Link>
      </span>
    );
  }, []);

  return (
    <>
      <ModalContainer>
        <Form form={form}>
          <div className="p-10 py-5 whitespace-normal ">
            <div className="mx-auto text-center my-4">
              <img
                src={CategoryIcon}
                alt="Category Icon"
                className="mx-auto my-2 h-13"
              />
              <Span color="orange" size="base" weight="semi">
                Category
              </Span>
            </div>

            <InputContainerNew
              icon={false}
              label="Name"
              required={true}
              bg="transparent"
            >
              <Form.Item
                name="categoryName"
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              >
                <Input
                  placeholder="Name"
                  className="config-form"
                  style={{ marginBottom: 0 }}
                />
              </Form.Item>
            </InputContainerNew>

            <InputContainerNew
              icon={false}
              label="Description"
              bg="transparent"
            >
              <Form.Item name="categoryDescription" className="w-full">
                <Input
                  placeholder="Description"
                  className="config-form"
                  style={{ marginBottom: 0 }}
                />
              </Form.Item>
            </InputContainerNew>

            <Form.Item
              noStyle
              shouldUpdate={(prevValue, currentValue) =>
                prevValue["categoryName"] !== currentValue["categoryName"] ||
                prevValue["categoryDescription"] !==
                  currentValue["categoryDescription"]
              }
            >
              {({ getFieldValue }) => (
                <Button
                  bg="orange"
                  width="none"
                  padding="px-6"
                  margin="mx-2"
                  disabled={!getFieldValue("categoryName")}
                  onClick={handleAddCategory}
                >
                  Add Category
                </Button>
              )}
            </Form.Item>

            {!isLoading ? (
              categoryData.length > 0 && (
                <>
                  <div className="card-scroll">
                    {categoryData.map((item) => (
                      <Tag
                        className=" m-1"
                        color="processing"
                        key={item.name}
                        closable
                        onClose={() => handleDelete(item?.id)}
                      >
                        {renderTag(item)}
                      </Tag>
                    ))}
                  </div>
                </>
              )
            ) : (
              <div className="flex justify-center bg-white">
                <Loader />
              </div>
            )}

            <div className="flex justify-end">
              <Button
                bg="orange"
                width="none"
                padding="px-6"
                margin="mx-4"
                onClick={() => setCategoryModal(false)}
              >
                Close
              </Button>
            </div>
          </div>
        </Form>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
