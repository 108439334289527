import { createModel } from "@rematch/core";
import { authenticateViaPassword, getUserCredentials } from "@api";
import { IUserInfo } from "@types";
import { RootModel } from ".";
import { getAuth, signInWithCustomToken } from "firebase/auth";

export interface State {
  accessToken: string;
  isUserLoggedIn: boolean;
  userInfo: IUserInfo | null;
  restaurantId: string;
  userToken: string;
  shouldRedirectToLogin: boolean;
}

const initialState = {
  accessToken: "",
  isUserLoggedIn: false,
  shouldRedirectToLogin: false,
  userInfo: null,
  restaurantId: "",
  userToken: "",
} as State;

export const User = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState };
    },
    setUserLoggedIn(state, payload: boolean) {
      state.isUserLoggedIn = payload;
      return { ...state };
    },
    setUserInfo(state, payload: IUserInfo | null) {
      state.userInfo = payload;
      return { ...state };
    },
    setUserToken(state, payload: string) {
      state.userToken = payload;
      return { ...state };
    },
    updateState(state, newState: Partial<State>) {
      return { ...state, ...newState };
    },
  },
  effects: (dispatch) => ({
    /**
     * @name login
     * @desc login user using email and password
     */
    async login({ email, password }) {
      const auth = getAuth();
      dispatch.UI.setIsUserLoading({ target: "login", value: true });
      try {
        const { accessToken, customToken } = await authenticateViaPassword({
          email,
          password,
        });

        if (customToken) {
          const user = await signInWithCustomToken(auth, customToken);
          if (user) {
            dispatch.User.updateState({
              userToken: (user as any).accessToken,
            });
          }
        }

        dispatch.User.updateState({
          accessToken,
          isUserLoggedIn: true,
        });

        dispatch.UI.setErrorMsg({
          target: "login",
          value: "",
        });

        dispatch.UI.resetForm("login");
        await this.getUserInfoViaToken();
      } catch (err: any) {
        dispatch.UI.setAlert({
          message: err?.response?.data?.message ?? "Invalid credentials",
          type: "Error",
        });
        dispatch.UI.setErrorMsg({
          target: "login",
          value: err?.response?.data?.message,
        });
      } finally {
        dispatch.UI.setIsUserLoading({ target: "login", value: false });
      }
    },
    async getUserInfoViaToken() {
      let user_profiles = await getUserCredentials();

      dispatch.User.setUserInfo({
        ...user_profiles,
      });
      dispatch.User.setUserLoggedIn(true);
    },
    /**
     * @name getUserInfoViaToken
     * @desc fetches and sets user data via token(state.userToken)
     */

    async logout() {
      await getAuth().signOut()
      dispatch.User.resetState();
      dispatch.UI.resetState();
      dispatch.Table.resetState();
      dispatch.SalesOverview.resetState();
      dispatch.HourlyData.resetState();
      dispatch.Team.resetState();
      dispatch.User.updateState({
        shouldRedirectToLogin: true,
        isUserLoggedIn: false,
      });
    },
  }),
});
