/* eslint-disable react-hooks/exhaustive-deps */
import { deleteOrder } from "@api";
import { Button, ModalContainer, Span } from "@common"; 
import { Dispatch, RootState } from "@store";
import infoIcon from "@assets/info.png";
import { useDispatch, useSelector } from "react-redux";
 
type Props = {
  setDeleteModal: Function;
};

export const DeleteModal = ({ setDeleteModal }: Props) => { 
  const dispatch = useDispatch<Dispatch>(); 
 
  const orderData = useSelector((state: RootState) => state.Ecommerce.orderId); 

  const handleDelete = async () => {
    try {
      const res = await deleteOrder({ orderIds: orderData });
      if (res) {
        dispatch.UI.setAlert({
          message: "Order successfuly qouted!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "Unable assign order!",
        type: "Error",
      });
    }
    dispatch.Ecommerce.updateState({ orderId: [], orderIdReady: 0 });
    setDeleteModal(false);
  };
   

  return (
    <>
      <ModalContainer bg="grey">
        <div className="px-8 py-5 whitespace-normal text-center ">
          <div className="mx-auto my-4">
            <img src={infoIcon} alt={infoIcon} className="mx-auto my-2" />
            <Span color="orange" size="xl" weight="semi">
            Delivery
            </Span>
          </div>
          <Span size="base">Are you sure you want to delete this order?</Span>

          <div className="flex justify-center">
            <Button
              bg="white"
              color="grey"
              width="none"
              margin="m-4"
              padding="px-10"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              CLOSE
            </Button>
            <Button
              bg="red"
              width="none"
              padding="px-10"
              onClick={handleDelete}
            >
              DELETE
            </Button>
          </div>
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
