import './Alert.scss'

type Props = {   
    message: string  
    type: string
}
  
export const ErrorMessage = ({type, message}: Props) => {
    return <>
        <div className={type}>
            <span className='text-xs'>
                {message}
            </span>
        </div>
    </>;
};
