import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import persistPlugin from '@rematch/persist';
import storage from 'redux-persist/lib/storage'
import { models, RootModel } from './model';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['Locations']
}

export const store = init({
    models,
    // @ts-ignore
    plugins: [persistPlugin(persistConfig)],
     
})

export type Store = typeof store
export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel>