/* eslint-disable @typescript-eslint/no-unused-vars */
import { createModel } from "@rematch/core";
import moment from "moment"; 
import { RootModel } from ".";

interface IErrorMessagePayload {
  target: string;
  value: string;
}

interface IAlertPayload {
  message: string;
  type: any;
}

interface IModalUpdateIdPayload {
  target: string;
  value: string | null;
}

interface IModalIsRequestingPayload {
  target: string;
  value: boolean;
}

interface IFormPayload {
  target: string;
  field: string;
  value: any;
}

const initialState = { 
  forms: {
    login: {
      isRequesting: false,
      errorMessage: "",
      fields: {
        email: "",
        password: "", 
      },
    }, 
  },
  alert: {
    message: "",
    type: "",
    alertWatcher: moment.now(),
  },
  isLoading: false,
  currentRoute: null,
  isNewLogin: true,
  currentRoleAccesses: null, 
} as any;

export const UI = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState(){
      return { ...initialState }
    },
    updateState(state, newState){
      return {
        ...state,
        ...newState
      }
    },
    updateFormId(state, payload:IModalUpdateIdPayload) {
      const { target, value } = payload
      return { 
        ...state,
        forms: { ...state.forms, [target]: { ...state.forms[target], id:value } },
       }
    },
    /**
     * @name resetForm
     * @description resets from by passing target property underform
     * @param target
     */
    resetForm(state, target: string) {
      return {
        ...state,
        forms: { ...state.forms, [target]: { ...initialState.forms[target] } },
      };
    },
    resetAlert(state){
      return{
        ...state,
        alert: {...state.alert, message: "", type: ""}
      }
    }
    ,
    setModal(state, payload: IModalIsRequestingPayload) {
      const { target, value } = payload;

      return { ...state, modals: { ...state.modals, [target]: value } };
    },
    setMode(
      state,
      payload: {
        target: string;
        value: "add" | "edit";
      }
    ) {
      const { target, value } = payload;

      return {
        ...state,
        forms: {
          ...state.forms,
          [target]: { ...state.forms[target], mode: value },
        },
      };
    },
    setIsUserLoading(state, payload: IModalIsRequestingPayload) {
      const { target, value } = payload;

      return {
        ...state,
        forms: {
          ...state.forms,
          [target]: { ...state.forms[target], isRequesting: value },
        },
      };
    },
    setErrorMsg(state, payload: IErrorMessagePayload) {
      const { target, value } = payload;

      return {
        ...state,
        forms: {
          ...state.forms,
          [target]: { ...state.forms[target], errorMessage: value },
        },
      };
    },
    setInputFormFields(state, payload: IFormPayload) {
      const { target, field, value } = payload;

      return {
        ...state,
        forms: {
          ...state.forms,
          [target]: {
            ...state.forms[target],
            fields: { ...state.forms[target].fields, [field]: value },
          },
        },
      };
    },
    setAlert(state, { message, type }: IAlertPayload) {
      return {
        ...state,
        alert: { ...state.alert, message, type, alertWatcher: moment.now() },
      };
    },
    setIsLoading(state, isLoading: boolean) {
      return {
        ...state, isLoading
      };
    },
    setCurrentRoute(state, currentRoute: string) {
      return {
        ...state, currentRoute
      }
    }
  }, 
});
