import { Models } from "@rematch/core";
import { HourlyData } from "./HourlyData";
import { SalesOverview } from "./SalesOverview";
import { Table } from "./TableModel";
import { Team } from "./Team";
import { UI } from "./UIModel";
import { User } from "./UserModel";
import { Ecommerce } from "./EcommerceModel";
import { Cart } from "./cart";
import { Supplier } from "./Supplier";

export interface RootModel extends Models<RootModel> {
  UI: typeof UI;
  Table: typeof Table;
  User: typeof User;
  SalesOverview: typeof SalesOverview;
  HourlyData: typeof HourlyData;
  Team: typeof Team;
  Ecommerce: typeof Ecommerce;
  Cart: typeof Cart;
  Supplier: typeof Supplier;
}

export const models: RootModel = {
  UI,
  Table,
  User,
  SalesOverview,
  HourlyData,
  Team,
  Ecommerce,
  Cart,
  Supplier,
};
