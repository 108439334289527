/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { Route, Switch } from "react-router-dom";
import "./App.css";
import "./Container.scss";

import {
  LoginPage,
  ForgetPasswordPage,
  ResetPasswordPage,
  SetPasswordPage,
  ProfilePage,
  SalesReportPage,
  SalesOverviewPage,
  TeamPage,
  IntegrationPage,
  ShopifyPage,
  ShopifyAddPage,
  PickupLocationPage,
  EcomOrderPage,
  TeamDetailsPage,
  TeamDetailsAddPage,
  SupplierPage,
  SetUpPage,
  ChooseSupplierPage,
  ShopPage,
  ShopOrderPage,
  PurchaseOrderPage,
  PurchaseOrderViewPage,
  CustomerOrderPage,
  CustomerOrderViewPage,
  CustomerOrderByStorePage,
  Logout,
} from "@pages";
import AuthRoute from "./securedRoute/AuthRoute";
import LoginRoute from "./securedRoute/LoginRoute";
import { AuthProvider, FirestoreProvider, useFirebaseApp } from "reactfire";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { useCallback, useEffect } from "react";
import { Dispatch } from "@store";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch<Dispatch>();

  const firebaseApp = useFirebaseApp();
  const firestoreInstance = getFirestore(useFirebaseApp());
  const auth = getAuth(firebaseApp);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (!user) {
        return;
      }
      await updateToken();
    });

    onIdTokenChanged(auth, async (user) => {
      if (user) {
        await Promise.all([updateToken()]);
      }
    });
  }, []);

  const updateToken = useCallback(async () => {
    const user = auth.currentUser;
    if (!user) return;
    const token = await user.getIdToken();
    dispatch.User.updateState({ userToken: token });
  }, []);

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <Switch>
          <Route path="/logout" exact component={Logout} />
          <LoginRoute exact path="/" component={LoginPage} />
          <Route exact path="/forget-password" component={ForgetPasswordPage} />
          <Route exact path="/reset-password" component={ResetPasswordPage} />
          <Route exact path="/set-password" component={SetPasswordPage} />
          <AuthRoute exact path="/profile" component={ProfilePage} />
          <AuthRoute
            exact
            path="/sales-overview"
            component={SalesOverviewPage}
          />
          <AuthRoute exact path="/sales-report" component={SalesReportPage} />
          <AuthRoute exact path="/team" component={TeamPage} />
          <AuthRoute
            exact
            path="/ecommerce/integration"
            component={IntegrationPage}
          />
          <AuthRoute
            exact
            path="/ecommerce/integration/shopify-add"
            component={ShopifyAddPage}
          />
          <AuthRoute
            exact
            path="/ecommerce/integration/shopify/:id"
            component={ShopifyPage}
          />
          <AuthRoute
            exact
            path="/ecommerce/pickup-location/:id?"
            component={PickupLocationPage}
          />

          <AuthRoute exact path="/ecommerce/order" component={EcomOrderPage} />
          <AuthRoute
            exact
            path="/team-details/:id?"
            component={TeamDetailsPage}
          />
          <AuthRoute
            exact
            path="/team-details-add"
            component={TeamDetailsAddPage}
          />
          <AuthRoute exact path="/supplier/setup" component={SetUpPage} />
          <AuthRoute
            exact
            path="/supplier/item-creation/:id"
            component={SupplierPage}
          />
          <AuthRoute
            exact
            path="/supplier/choose-supplier"
            component={ChooseSupplierPage}
          />
          <AuthRoute
            exact
            path="/supplier/choose-supplier/shop/:id"
            component={ShopPage}
          />
          <AuthRoute
            exact
            path="/supplier/choose-supplier/shop/:id/order"
            component={ShopOrderPage}
          />

          {/* Orders */}
          <AuthRoute
            exact
            path="/orders/purchase"
            component={PurchaseOrderPage}
          />
          <AuthRoute
            exact
            path="/orders/purchase/:id"
            component={PurchaseOrderViewPage}
          />
          <AuthRoute
            exact
            path="/orders/customer-order"
            component={CustomerOrderPage}
          />
          <AuthRoute
            exact
            path="/orders/customer-order/:id"
            component={CustomerOrderViewPage}
          />
          <AuthRoute
            exact
            path="/orders/customer-order/view/:id"
            component={CustomerOrderByStorePage}
          />
        </Switch>
      </FirestoreProvider>
    </AuthProvider>
  );
}

export default App;
