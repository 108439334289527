import { createModel } from "@rematch/core"; 
import { RootModel } from ".";

export interface State { 
  location?: {
    lat: number;
    lng: number; 
  },
  address: any;
  pickUpId: string;
  orderId: number[];
  orderIdReady: number;
  referenceNumber: any
}

export const initialState = {
  location: {
    lat: 0,
    lng: 0, 
  },
  address: null,
  pickUpId: "",
  orderId: [],
  referenceNumber: null,
  orderIdReady: 0,
} as State;

export const Ecommerce = createModel<RootModel>()({
    state: initialState,
    reducers: {
      resetState() {
        return { ...initialState }
      },
      /**
       * @name resetForm
       * @description resets from by passing table property underform
       * @param table
       */ 
      updateState(state, newState: Partial<State>) {
        return { ...state, ...newState };
      }
    }, 
  });