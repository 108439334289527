import { MainContainer } from "@common";
import { Navbar, SideMenu } from "@components";
import { BRAND_IMAGES } from "@constant";
import { Order } from "@containers";

export const ShopOrderPage = () => {
  return (
    <>
      <Navbar
        title="Ecommerce Integration"
        icon={BRAND_IMAGES["HEADER_LOGO"]}
      />
      <SideMenu />
      <MainContainer>
        <Order />
      </MainContainer>
    </>
  );
};
