import { addTeamMember } from "@api";
import { Container, GridContainer, Button, Span, Inputs } from "@common";
import { InputContainerNew } from "@components";
import { BRAND_IMAGES, ROLE } from "@constant";
import { Dispatch } from "@store";
import { onlyNumberKey } from "@utils";
import { Form, Select, Switch } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "../Team.scss";
import { SelectResto } from "./SelectResto";
import { SelectStore } from "./SelectStore";

const { Option } = Select;

export const TeamDetailsAdd = () => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();
  const history = useHistory();

  const initialState = {
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    contactNumber: "",
    role: "",
    restaurantAccesses: [
      {
        restaurantId: "",
      },
    ],
    storeAccesses: [
      {
        storeId: "",
      },
    ],
  } as any;

  const [teamDetailsAdd, setTeamDetailsAdd] = useState() as any;

  const handleAddTeamMember = async () => {
    try {
      const {
        listChosenRestaurants: restaurantAccessIds,
        listChosenStores: storeAccessIds,
        ...teamDetailsAdd
      } = await form.validateFields();
      const res = await addTeamMember({
        ...teamDetailsAdd,
        contactNumber: `+63${teamDetailsAdd?.contactNumber}`,
        role: teamDetailsAdd?.role,
        email: teamDetailsAdd?.email,
        accessStatus:
          teamDetailsAdd.accessStatus === true ? "active" : "inactive",

        restaurantAccessIds,
        storeAccessIds,
      });
      if (res) {
        dispatch.UI.setAlert({
          message: "Team member successfully added!",
          type: "Success",
        });
      }
      setTeamDetailsAdd(initialState);
      history.push("/team");
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "Unable to add team member!",
        type: "Error",
      });
    }
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setTeamDetailsAdd({
      ...teamDetailsAdd,
      [name]: value,
    });
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    history.push("/team");
  };

  return (
    <>
      <GridContainer>
        <div>
          <Form onFinish={handleAddTeamMember} layout="vertical" form={form}>
            <Container padding="p-4" margin="none">
              <Container padding="p-4" margin="none">
                <Container margin="none">
                  <img
                    src={BRAND_IMAGES["DEFAULT_ICON"]}
                    alt="AEG Logo"
                    className="default-icon"
                  />

                  <InputContainerNew
                    icon={false}
                    label="First Name"
                    required={true}
                  >
                    <Form.Item
                      name="firstName"
                      className="w-full"
                      rules={[
                        {
                          required: true,
                          message: "First Name is required",
                        },
                      ]}
                    >
                      <Inputs
                        padding="left"
                        border="bottom"
                        name="firstName"
                        type="text"
                        transform="capitalize"
                        onChange={onChange}
                      />
                    </Form.Item>
                  </InputContainerNew>

                  <InputContainerNew icon={false} label="Middle Name">
                    <Form.Item name="middleName" className="w-full">
                      <Inputs
                        padding="left"
                        border="bottom"
                        name="middleName"
                        type="text"
                        transform="capitalize"
                        onChange={onChange}
                      />
                    </Form.Item>
                  </InputContainerNew>

                  <InputContainerNew
                    icon={false}
                    label="Last Name"
                    required={true}
                  >
                    <Form.Item
                      name="lastName"
                      rules={[
                        { required: true, message: "Last Name is required" },
                      ]}
                      className="w-full"
                    >
                      <Inputs
                        padding="left"
                        border="bottom"
                        name="lastName"
                        type="text"
                        transform="capitalize"
                        onChange={onChange}
                      />
                    </Form.Item>
                  </InputContainerNew>

                  <InputContainerNew
                    icon={false}
                    label="Mobile"
                    required={true}
                  >
                    <div className="flex w-full">
                      <span className="absolute border-b border-grey-2 py-2 text-xs">
                        (+63)
                      </span>
                      <Form.Item
                        name="contactNumber"
                        rules={[
                          {
                            required: true,
                            message: "Mobile Number is required",
                          },
                        ]}
                        className="w-full"
                      >
                        <Inputs
                          padding="both"
                          border="bottom"
                          name="contactNumber"
                          type="phone"
                          onChange={onChange}
                          maxLength={10}
                          onKeyDown={onlyNumberKey}
                        />
                      </Form.Item>
                    </div>
                  </InputContainerNew>

                  <InputContainerNew icon={false} label="Email" required={true}>
                    <Form.Item
                      name="email"
                      rules={[{ required: true, message: "Email is required" }]}
                      className="w-full"
                    >
                      <Inputs
                        padding="left"
                        border="bottom"
                        name="email"
                        type="email"
                        onChange={onChange}
                      />
                    </Form.Item>
                  </InputContainerNew>

                  <InputContainerNew icon={false} label="Role" required={true}>
                    <Form.Item
                      name="role"
                      rules={[{ required: true, message: "Role is required" }]}
                      className="w-full pt-2"
                    >
                      <Select
                        className="w-full"
                        placeholder="Select Role"
                        onChange={(v: any) =>
                          setTeamDetailsAdd({ ...teamDetailsAdd, role: v })
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {ROLE?.map((type, index) => (
                          <Option key={index} value={type.value}>
                            {type.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </InputContainerNew>

                  <SelectResto />
                  <SelectStore />

                  <div className="flex justify-between border border-grey-2 my-2 mx-2 p-4 rounded">
                    <Span color="grey" margin="my-auto">
                      Member Status
                    </Span>
                    <Form.Item name="accessStatus" className="mb-0">
                      <Switch
                        checkedChildren="Active"
                        unCheckedChildren="Inactive"
                      />
                    </Form.Item>
                  </div>
                </Container>
              </Container>
            </Container>

            <div className="flex justify-end">
              <Button
                bg="white"
                color="grey"
                width="none"
                margin="m-4"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button bg="orange" width="none" padding="px-6">
                Save
              </Button>
            </div>
          </Form>
        </div>
      </GridContainer>
    </>
  );
};
