import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { FaInfoCircle } from "@icons";
import { Span } from "@common";

type Props = {
  info: string;
};

export const PopupInfo = ({ info }: Props) => {
  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <>
            <button {...bindTrigger(popupState)}>
              <FaInfoCircle />
            </button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Typography sx={{ p: 2 }}>
                <p>
                  <Span>{info}</Span>
                </p>
              </Typography>
            </Popover>
          </>
        )}
      </PopupState>
    </>
  );
};
