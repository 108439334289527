import { BRAND_IMAGES } from "@constant"; 
 
import { Link } from "react-router-dom";
import { Span, CardContainer, Container, Header, Button } from "@common";
import { PasswordContainer } from "@components";

export const ResetPassword = () => {
  return (
    <>
      <CardContainer> 
        <img src={BRAND_IMAGES["BRAND_LOGO"]} alt="AEG Logo" className="brand-logo"/>

        <Container margin="my-8" align="center">  
            <Header color="orange">Reset Password</Header> 
            <Span weight="semi">
                Please enter your new password below.
            </Span> 
        </Container>  
        
        <PasswordContainer />

        <Button margin="top">
          Save Password
        </Button>

        <div className="text-center mt-3">
          <Link to="/">
            <Span color='blue'>Back to login</Span>
          </Link>
        </div>  
      </CardContainer>
    </>
  );
};
