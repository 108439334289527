import * as React from "react";
import classnames from "classnames";

type Props = React.HTMLProps<HTMLButtonElement> & {
  children: React.ReactNode;
  type?: "submit";
  bg?: "white" | "orange" | "grey" | "greylight" | "red" | "green" | "maroon";
  color?: "grey" | "black";
  margin?: "auto" | "top" | "m-4" | "mx-4" | "none" | "mx-2";
  padding?: "none" | "px-6" | "p-2" | "px-10";
  radius?: "none";
  border?: "grey";
  transform?: "capitalize" | "uppercase";
};

let getRadius = (radius: Props["radius"]) => {
  switch (radius) {
    case "none":
      return "rounded-none";
    default:
      return "rounded-md";
  }
};

let getPadding = (padding: Props["padding"]) => {
  switch (padding) {
    case "p-2":
      return "p-2";
    case "px-10":
      return "py-4 px-10";
    case "px-6":
      return "py-2 px-6";
    case "none":
      return "p-none";
    default:
      return "py-2 px-4";
  }
};

let getMargin = (margin: Props["margin"]) => {
  switch (margin) {
    case "none":
      return "m-0";
    case "mx-2":
      return "mx-2";
    case "mx-4":
      return "mx-4";
    case "m-4":
      return "m-4";
    case "top":
      return "mt-8 mb-4";
    case "auto":
      return "m-auto";
    default:
      return "my-4";
  }
};

let getColor = (color: Props["color"]) => {
  switch (color) {
    case "black":
      return "text-black";
    case "grey":
      return "text-grey-1";
    default:
      return "text-white";
  }
};

let getBg = (bg: Props["bg"]) => {
  switch (bg) {
    case "orange":
      return "bg-primary-main";
    case "green":
      return "bg-green-2";
    case "maroon":
      return "bg-red-2";
    case "red":
      return "bg-red-1";
    case "white":
      return "bg-white";
    case "greylight":
      return "bg-grey-2";
    case "grey":
      return "bg-grey-1";
    default:
      return "bg-grey-1 hover:bg-primary-main";
  }
};

let getWidth = (width: Props["width"]) => {
  switch (width) {
    case "none":
      return "w-none";
    default:
      return "w-full";
  }
};

let getBorder = (border: Props["border"]) => {
  switch (border) {
    case "grey":
      return "border border-grey-4";
    default:
      return "border-none";
  }
};

let getTransform = (transform: Props["transform"]) => {
  switch (transform) {
    case "capitalize":
      return "capitalize";
    case "uppercase":
      return "uppercase";
    default:
      return "normal-case";
  }
};

export const Button = ({
  children,
  type,
  bg,
  width,
  color,
  margin,
  padding,
  radius,
  border,
  transform,
  ...props
}: Props) => {
  return (
    <button
      className={classnames(
        "focus:outline-none whitespace-nowrap tracking-wider font-semibold text-sm",
        getBg(bg),
        getWidth(width),
        getColor(color),
        getMargin(margin),
        getPadding(padding),
        getRadius(radius),
        getBorder(border),
        getTransform(transform)
      )}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};

export const QuantityButton = ({ children, ...props }: Props) => {
  return (
    <button className={classnames("mx-auto qty-btn")} {...props}>
      {children}
    </button>
  );
};
