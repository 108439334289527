import { createModel } from "@rematch/core"; 
import { RootModel } from ".";

type Item = {
    id: number;
    items: string; 
    uom: string;
    category: string;
    price: number; 
    quantities: number;
    //quantity: number; actual quantity
    subtotal?: number;  
}

type Customer = { 
    first_name: string,
    last_name: string, 
    unit: string,  
    phone: string;
    email: string, 
    note: string,  
  } 
  
  type OrderType = {
    first_name?: string,
    last_name?: string, 
    address?: {
      lat?: number,
      lng?: number,
      unit?: string
    }, 
    phone?: string,
    email?: string,
    note?: string,
    items?: [
        {
          id?: number,
          name?: string,
          merchant?: string,
          quantities?: number,
          //quantity: number; actual quantity
          price?: number,
          total?: number
        }
    ],
    total?: number
  }

  const initialState = {
    items: [] as any,
    customer: {}, 
    total: 0,
    location: {
      lat: 0,
      lng: 0,
      address: '',
    },
    orders:[] as any, 
  }

  export const Cart = createModel<RootModel>()({
    state: initialState, 
    reducers: {
      resetState(state) {
        // making sure that pop over were close
        return { ...state, ...initialState };
      },
      // handle state changes with pure functions
      setCustomer(state, payload: Customer) {
        return {...state, customer: payload};
      }, 
      updateOrders(state, payload: Array<OrderType>) {
        return {
          ...state,
          orders: payload
        };
      }, 
      updateItems(state, payload: Array<Item>) {
  
        let total = 0;
        for (const key in payload) {
          let subtotal = payload[key].price * payload[key].quantities;
          payload[key].subtotal = subtotal
          total = total + subtotal;
        } 
  
        return {
          ...state,
          total,
          items: payload
        };
      },
    },
    effects: (dispatch) => ({
      // handle state changes with impure functions.
      // use async/await for async actions
      async addItem(payload: Item, state) { 
  
          let currentItems = state.Cart.items;
  
          let exist = false;
  
          for (const key in state.Cart.items) {
              let obj = currentItems[key];
   
              if(payload.id === obj.id){
                exist = true;
              }
          }
  
          if(!exist){
            dispatch.Cart.updateItems([...state.Cart.items, payload]);
          }
  
          dispatch.UI.setAlert({
            message: 'Item Added to Cart',
            type: "Success",
          });
  
      },    
      async cleanCart(payload:boolean, state) {
        dispatch.Cart.updateItems([]);
      },
      async updateItem(payload: Item, state) {
  
          let currentItems = state.Cart.items;
          
         //find the key of the item
         for (const key in state.Cart.items) {
             let obj = currentItems[key];
  
             if(payload.id === obj.id){
              currentItems[key] = payload
             }
         }
  
        //find the key of the item
        dispatch.Cart.updateItems([...currentItems]);
      },
      async removeItem(payload: Item, state) {
  
          let currentItems = state.Cart.items;
          
         //find the key of the item
         for (const key in state.Cart.items) {
             let obj = currentItems[key];
  
             if(payload.id === obj.id){
              currentItems.splice(key, 1)
             }
         }
  
        //find the key of the item
        dispatch.Cart.updateItems([...currentItems]);
      },  
      async updateCustomer(payload: Customer, state){
        dispatch.Cart.setCustomer(payload);
      },
      async order(payload: OrderType, state) {  
        dispatch.Cart.updateOrders([payload]);
        
        dispatch.Cart.cleanCart(true); 
  
      },
    }),
  });