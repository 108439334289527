
export const COLUMNS = [
    {
        accessor: 'productName',
        Header: 'Product', 
        isVisible: true, 
    },     
    {
        accessor: 'unitSold',
        Header: 'Unit Sold', 
        isVisible: true, 
    }, 
    {
        accessor: 'totalSales',
        Header: 'Total Net Sales', 
        isVisible: true,  
        // Cell: (data: any) => {
        //     const d = data?.row?.original;
        //     const num = d?.totalSales
        //     return (<p>
        //         {num?.toLocaleString()}
        //       </p>
        // )} 
    },
]