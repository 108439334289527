/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {
  MdStoreMallDirectory,
  IoMdDownload,
  BiPlus,
  BiSearchAlt2,
} from "@icons";
import { Container, Select, Span, Button, Inputs } from "@common";
import { CSVLinkContent, IconContainer, Loader, NoData } from "@components";
import { useEffect, useMemo, useState } from "react";
import { COLUMNS } from "./Table/column";
import { Table } from "./Table/Table";
import { Link } from "react-router-dom";
import "./Team.scss";
import { HEADERS_TEAM } from "./Table/common";
import moment from "moment";
import { getTeam } from "@api";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";

export const Team = () => {
  const dispatch = useDispatch<Dispatch>();

  const columns = useMemo(() => COLUMNS, []);
  const now = moment();

  const [search, setSearch] = useState("");
  const [finalData, setFinalData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);

  const isDefaultSearchValue = search?.length === 0;

  const setTable = () => {
    const data = !isDefaultSearchValue
      ? teamMemberData.filter((d: any) => {
          if (search.length > 0) {
            return (
              JSON.stringify(Object.values(d)).search(new RegExp(search, "i")) >
              -1
            );
          }
        })
      : teamMemberData;

    setFinalData(data);
  };

  const restaurant = useSelector(
    (state: RootState) => state.User.userInfo?.restaurantAccesses
  );

  const restaurantId = useSelector(
    (state: RootState) => state.Team.restaurantId
  );

  const [teamMemberData, setTeamMemberData] = useState([] as any);
  const getTeamMember = async () => {
    setIsLoading(true);

    try {
      const res = await getTeam(restaurantId);
      setTeamMemberData(res);
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getTeamMember();
  }, [restaurantId]);

  useEffect(() => {
    setTable();
  }, [teamMemberData, search]);

  return (
    <>
      <div className="flex justify-between mb-4">
        <div className="store">
          <IconContainer icon={<MdStoreMallDirectory className="store-icon" />}>
            <Select
              className="ml-6 w-full"
              onChange={(e: any) => {
                dispatch.Team.updateState({ restaurantId: e.target.value });
              }}
            >
              {restaurant?.map((resto: any) => (
                <option
                  key={resto?.restaurant?.id}
                  value={resto?.restaurant?.id}
                  className="capitalize"
                >
                  <span className="capitalize">{resto?.restaurant?.name}</span>
                </option>
              ))}
            </Select>
          </IconContainer>
        </div>

        <div className="exportOption">
          <IconContainer icon={<IoMdDownload className="exportOption-icon" />}>
            <CSVLinkContent
              data={finalData}
              headers={HEADERS_TEAM}
              filename={`merchantPortal_user_${now.format("MM/DD/YYYY hh:ss")}`}
            />
          </IconContainer>
        </div>
      </div>

      <Container padding="p-4">
        {!isLoading ? (
          <>
            <div className="flex justify-between">
              <div>
                <Link to="/team-details-add">
                  <Button width="none" bg="orange" padding="none" margin="none">
                    <div className="flex p-2 team-addMember">
                      <BiPlus className="my-auto text-semibold" />
                      <Span margin="mx-1" color="white" weight="semi">
                        Add Member
                      </Span>
                    </div>
                  </Button>
                </Link>
              </div>

              <div>
                <Container className="index" flex="wrap" margin="none">
                  <Inputs
                    padding="left"
                    border="bottom"
                    placeholder="Search"
                    value={search}
                    onChange={(e: any) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <span className="bar"></span>

                  <span className="pencil">
                    <BiSearchAlt2 />
                  </span>
                </Container>
              </div>
            </div>

            {finalData?.length === 0 ? (
              <NoData />
            ) : (
              <>
                <Table columns={columns} data={finalData} header={true} />
              </>
            )}
          </>
        ) : (
          <div className="p-32">
            <Loader />
          </div>
        )}
      </Container>
    </>
  );
};
