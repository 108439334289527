/* eslint-disable @typescript-eslint/no-unused-vars */
import { MenuData } from "./MenuData.js";
import { Menu } from "@components";
import "./SideMenu.scss";
import { Button, Span } from "@common";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";

import { FaBars, FiLogOut, IoMdArrowRoundBack } from "@icons";
import { useEffect, useState } from "react";

export const SideMenu = () => {
  const dispatch = useDispatch<Dispatch>();

  const handleLogout = async () => {
    await dispatch.User.logout();
  };

  const [pathname, setPathname] = useState("/");
  const location = useLocation();

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  return (
    <>
      <input type="checkbox" id="nav-toggle" />
      <div className="sidebar relative">
        <div className="sidebar-menu sidenav" id="mySidenav">
          <ul>
            <li>
              <div className="sidebar-header">
                <label htmlFor="nav-toggle" className="menubar-icon">
                  <FaBars className="cursor-pointer mx-auto" />
                </label>

                <label htmlFor="nav-toggle" className="menu-arrow">
                  <span>
                    <IoMdArrowRoundBack className="cursor-pointer" />
                  </span>
                </label>
              </div>
            </li>

            {MenuData.map((item, index) => {
              return <Menu item={item} key={index} />;
            })}
          </ul>
        </div>

        <Link to="/">
          <div className="sidebar-footer">
            <Button
              margin="auto"
              bg="orange"
              padding="none"
              onClick={handleLogout}
            >
              <div className="flex p-2">
                <FiLogOut className="sidebar-footer-icon" />
                <Span color="white" margin="auto">
                  Logout
                </Span>
              </div>
            </Button>
          </div>
        </Link>
      </div>
    </>
  );
};
