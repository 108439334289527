import { MainContainer } from '@common';
import { Navbar, SideMenu} from '@components';
import { BRAND_IMAGES } from '@constant';
import {SalesOverview} from '@containers';

export const SalesOverviewPage = () => {
  return (
    <> 
      <Navbar title='Sales Overview' icon={BRAND_IMAGES["HEADER_LOGO"]}/>
      <SideMenu />
      <MainContainer > 
        <SalesOverview />
      </MainContainer>
    </>
  )
}