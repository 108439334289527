
import { ICategory, IMeasurement} from "@types";
import { put, post, remove, postMultipart, putMultipart, patch, get, getBlobv2 } from "./core";

export const deleteCategory = async (storeId: string, ItemId: string) => {
  try {
    let res = await remove(`/supplier/${storeId}/category/${ItemId}`);  
    return res.data; 
  } catch (err) {
    throw err;
  }
};

export const addCategory = async (storeId: string, category: ICategory) => {
  try {
    let res = await post(`/supplier/${storeId}/category`, category);  
    return res.data; 
  } catch (err) {
    throw err;
  }
};

export const addMeasurement = async (storeId: string, measurement: IMeasurement) => {
  try {
    let res = await post(`/supplier/${storeId}/unit-measurement`, measurement);  
    return res.data; 
  } catch (err) {
    throw err;
  }
};

export const deleteMeasurement = async (storeId: string, ItemId: string) => {
  try {
    let res = await remove(`/supplier/${storeId}/unit-measurement/${ItemId}`);  
    return res.data; 
  } catch (err) {
    throw err;
  }
};
  
// Product
export const addProduct = async (storeId: string, formData: FormData) => {
  try {  
    let res = await postMultipart(`/supplier/${storeId}/product`, formData);  
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 

export const deleteItem = async (storeId: string, id: string) => {
  try {  
    let res = await remove(`/supplier/${storeId}/product/${id}`);  
    return res.data; 
  } catch (err) {
    throw err;
  }
};

export const updateItem = async (storeId: string, id: string, data: FormData) => {
    try {   

      let res = await putMultipart(`/supplier/${storeId}/product/${id}`, data);      
      return res.data; 
    } catch (err) {
      throw err;
    }
  };

export const createSupplierOrder = async (order: any) => {
  try {
    let res = await post(`/supplier/order`, order);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};


  
export const updateSupplierStatus = async (storeId: string, status: any) => {
  try { 
    let res = await put(`/supplier/update-status/${storeId}`, status);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};


export const updatePurchaseOrderStatus = async (id: string, status: any) => {
  try {  
    let res = await patch(`/supplier/order/${id}`, status);  
    return res.data; 
  } catch (err) {
    throw err;
  }
}; 
  
export const downloadProductFile = async (id: string) => {  
  try {
    let res = await getBlobv2(`/supplier/${id}/product/get-product-excel-file`);  
    return res; 
  } catch(err) {
    throw err;
  }
}


export const downloadList = (id: string) => {
  return get(`/supplier/${id}/product/get-product-excel-file`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}


export const uploadProductList = async (id: any, formData: FormData) => {   
  try { 
    let res = await postMultipart(`/supplier/${id}/product/upload-product-excel`, formData);   
    return res; 
  } catch(err) {
    throw err;
  }
} 