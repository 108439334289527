/* eslint-disable react-hooks/exhaustive-deps */

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { Dispatch } from "@store";

export const Logout = () => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  const handleLogout = async () => {
    try {
      dispatch.User.logout();
      history.push(`/`);
    } catch (_) {}
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return <></>;
};
