import StatusShop from "./StatusShop";

export const COLUMNS = [
  {
    accessor: "storeName",
    Header: "Store Name",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span className="text-center">{d?.storeName}</span>
        </div>
      );
    },
  },
  {
    accessor: " ",
    Header: "Action",
    isVisible: true,
    Cell: (data) => (
      <StatusShop
        storeId={data?.row?.original?.storeId}
        storeName={data?.row?.original?.storeName}
      />
    ),
  },
];
