import { GenerateStatusDesign } from "@components";
import { ORDER_STATUS } from "@constant";

export const COLUMNS = [
  {
    accessor: "customer",
    Header: "Customer",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original?.customer;
      return (
        <div className="flex justify-center">
          <span className="text-center">
            {d?.firstName} {d?.middleName ?? ""} {d?.lastName}
          </span>
        </div>
      );
    },
  },
  {
    accessor: "contact",
    Header: "Contact Details",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original?.customer?.contact;
      return (
        <div className="truncate text-center">
          <span>{d?.email}</span>
          <br />
          <span className="capitalize text-grey-1">
            {d?.contactNumber ?? ""}
          </span>
        </div>
      );
    },
  },
  {
    accessor: "totalUnit",
    Header: "Total Unit",
    isVisible: true,
    Cell: (data) => {
      const totalUnit = data?.row?.original?.totalUnit;
      return (
        <div className="flex justify-center">
          <span className="text-center">{totalUnit}</span>
        </div>
      );
    },
  },
  {
    accessor: "totalAmount",
    Header: "Grand Amount",
    isVisible: true,
    Cell: (data) => {
      const amount = data?.row?.original?.totalAmount;
      return (
        <div className="flex justify-center">
          <span className="text-center">{amount}</span>
        </div>
      );
    },
  },
  {
    accessor: "status",
    Header: "Status",
    isVisible: true,
    Cell: (data) => {
      const status = data?.row?.original?.status;
      return (
        <div className="flex justify-center">
          <GenerateStatusDesign status={status} label={ORDER_STATUS[status]} />
        </div>
      );
    },
  },
];

export const HEADER_CUSTOMER_ORDER = [
  { label: "Id", key: "id" },
  { label: "Name", key: "customerName" },
  { label: "Store", key: "storeName" },
  { label: "Store contact", key: "storeContact" },
  { label: "Total Unit", key: "totalUnit" },
  { label: "Total Amount", key: "totalAmount" },
  { label: "Created At", key: "created" },
  { label: "Status", key: "statusOrder" },
];
