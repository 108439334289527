/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { getUserCredentials } from "@api";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";

interface MyRouteProps extends RouteProps {
  component: any;
  path: any;
  exact: boolean;
}

const AuthRoute: React.FC<MyRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { shouldRedirectToLogin, isUserLoggedIn } = useSelector(
    (state: RootState) => state.User
  );
  const dispatch = useDispatch<Dispatch>();

  const getUserCredential = async () => {
    try {
      const userCredentials = await getUserCredentials();

      dispatch.User.updateState({
        userInfo: userCredentials,
      });
    } catch {
      await dispatch.User.logout();
      dispatch.UI.setAlert({
        message: "Session Expired!",
        type: "Error",
      });
    }
  };

  useEffect(() => {
    getUserCredential();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        isUserLoggedIn ? (
          <Component {...props} />
        ) : (
          <>
            {shouldRedirectToLogin ? (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location },
                }}
              />
            ) : null}
          </>
        )
      }
    />
  );
};

export default AuthRoute;
