/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch } from "react-redux";
import { Dispatch } from "@store";
import { useEffect, useState } from "react";

import { Button, Container, GridContainer, Inputs, Span } from "@common";
import { InputContainerNew, Loader } from "@components";
import { MdKeyboardArrowRight } from "@icons";
import { Form } from "antd";
import { deleteEcomm, getEcomyById } from "@api";
import { useHistory } from "react-router-dom";
import { decryptData } from "@utils";

export const Shopify = ({ id }: any) => {
  const dispatch = useDispatch<Dispatch>();
  const [form]: any = Form.useForm();
  const history = useHistory();

  const [shopifyDetails, setShopifyDetails] = useState({}) as any;
  const [isLoading, setIsLoading] = useState(false);

  const handleBack = () => {
    history.push(`/ecommerce/integration`);
  };

  const handleDelete = async () => {
    try {
      const res = await deleteEcomm(shopifyDetails);
      if (res) {
        dispatch.UI.setAlert({
          message: "Shopify Account deleted!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message:
          err?.response?.data?.message ?? "Unable delete shopify account!",
        type: "Error",
      });
    }
    history.push(`/ecommerce/integration`);
  };

  const getShopifyById = async () => {
    setIsLoading(true);
    try {
      await getEcomyById(id).then((res) => {
        const details = {
          id: res?.id,
          storeName: res?.storeName,
          storeDomain: res?.storeDomain,
          adminToken: decryptData(res?.credential?.adminToken),
          apiKey: decryptData(res?.credential?.apiKey),
          secretKey: decryptData(res?.credential?.secretKey),
          active: res?.active === true ? true : false,
        };
        form.setFieldsValue(details);
        setShopifyDetails(details);
      });
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message,
        type: "Error",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getShopifyById();
  }, []);

  return (
    <>
      <div className="flex mb-4">
        <Span>Ecommerce </Span> <MdKeyboardArrowRight />{" "}
        <Span>Integration </Span> <MdKeyboardArrowRight />{" "}
        <Span weight="bold">Shopify </Span>
      </div>

      <GridContainer>
        {!isLoading ? (
          <Form scrollToFirstError layout="vertical" form={form}>
            <Container padding="p-4">
              <InputContainerNew label="Store Name" required={true}>
                <Form.Item
                  name="storeName"
                  rules={[{ required: true }]}
                  className="w-full"
                >
                  <Inputs
                    padding="left"
                    border="bottom"
                    name="storeName"
                    type="text"
                    disabled
                  />
                </Form.Item>
              </InputContainerNew>

              <InputContainerNew icon={false} label="Endpoint" required={true}>
                <Form.Item
                  name="storeDomain"
                  rules={[{ required: true }]}
                  className="w-full"
                >
                  <Inputs
                    padding="left"
                    border="bottom"
                    name="storeDomain"
                    type="text"
                    disabled
                  />
                </Form.Item>
              </InputContainerNew>

              <InputContainerNew
                icon={false}
                label="Admin Token"
                required={true}
              >
                <Form.Item
                  name="adminToken"
                  rules={[{ required: true }]}
                  className="w-full"
                >
                  <Inputs
                    padding="left"
                    border="bottom"
                    name="adminToken"
                    type="text"
                    disabled
                  />
                </Form.Item>
              </InputContainerNew>

              <InputContainerNew icon={false} label="API Key" required={true}>
                <Form.Item
                  name="apiKey"
                  rules={[{ required: true }]}
                  className="w-full"
                >
                  <Inputs
                    padding="left"
                    border="bottom"
                    name="apiKey"
                    type="text"
                    disabled
                  />
                </Form.Item>
              </InputContainerNew>

              <InputContainerNew
                icon={false}
                label="Secret Key"
                required={true}
              >
                <Form.Item
                  name="secretKey"
                  rules={[{ required: true }]}
                  className="w-full"
                >
                  <Inputs
                    padding="left"
                    border="bottom"
                    name="secretKey"
                    type="text"
                    disabled
                  />
                </Form.Item>
              </InputContainerNew>
            </Container>

            <div className="flex justify-between">
              <Button
                bg="white"
                color="grey"
                width="none"
                padding="px-6"
                onClick={handleBack}
              >
                Back
              </Button>

              <div>
                <Button
                  bg="white"
                  color="grey"
                  width="none"
                  padding="px-6"
                  onClick={handleDelete}
                >
                  Delete Account
                </Button>
              </div>
            </div>
          </Form>
        ) : (
          <div className="flex justify-center bg-white p-44">
            <Loader />
          </div>
        )}
      </GridContainer>
    </>
  );
};
