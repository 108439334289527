/* eslint-disable react-hooks/exhaustive-deps */
import { Button, ModalContainer, Span } from "@common"; 
import { Dispatch, RootState } from "@store";
import { Card, Empty, Space, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux"; 
import { useEffect, useState } from "react";
import { getDetailsByReference } from "@api";

import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { TableNoData } from "@components";
type Props = {
  finalData: any;
};


let ORDER_UNSUBSCRIBE: any = null;
const { Title, Text } = Typography;

export const DetailsModal =  ({ finalData }: Props) => {
  const dispatch = useDispatch<Dispatch>();

  const [detailsData, setDetailsData] = useState([] as any);
  const { referenceNumber }: any = useSelector((state: RootState) => state.Ecommerce);
  const user: any = useSelector((state: RootState) => state.User.userInfo);
  
  var accessToken = user?.accessToken;
 
  const handleClose = () => {
    dispatch.Ecommerce.updateState({
      referenceNumber: null, 
    });
  } 
  
  const db = getFirestore();
  const listenToDetails = async () => {
    const q = query(
      collection(db, "orders"),
      where("referenceNumber", "==", `m-shopify-${referenceNumber}`)
    );

    ORDER_UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || []).map((ref) => {
        const data = ref.data();
        return data;
      }) as any[];
      setDetailsData(listData[0]);
    });
  }; 
  
  useEffect(() => {
    listenToDetails();

    return function () {
      try {
        if (ORDER_UNSUBSCRIBE) ORDER_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };

  }, [referenceNumber]); 
  
  return (
    <>
    <ModalContainer bg="grey">
        <div className="px-5 py-5 whitespace-normal">
          <div className="mx-auto my-4 text-center ">
            <Span size="base" weight="semi">
              Details
            </Span><br />
            <Span color="orange" size="base" weight="semi">
              <b>Order Number: #{referenceNumber}</b>
            </Span>
          </div>
    
          <Card size="small" className="mt-1">
              <Title level={5} type="warning">
                DRIVER DETAILS
              </Title>
              {detailsData?.driver ? (
                <Space direction="vertical" size={0}>
                  <div className="mb-2">
                    <Span margin="mt-2">Name:</Span>
                    <p>
                      {detailsData?.driver?.driverProfile ? (
                        <Text strong>
                          {`${detailsData?.driver?.driverProfile?.lastName}, ${
                            detailsData?.driver?.driverProfile?.firstName || ''
                          } ${detailsData?.driver?.driverProfile?.middleName || ''}`}
                        </Text>
                      ) : (
                        <TableNoData />
                      )}
                    </p>
                  </div>
                  <div>
                    <Span margin="mt-2">Phone Number:</Span>
                    <p>
                      <Text strong>
                        {detailsData?.driver?.phoneNumber || <TableNoData />}
                      </Text>
                    </p>
                  </div>
                </Space>
              ) : (
                <Empty />
              )}
            </Card>

            <Card size="small" className="mt-1">
              <Title level={5} type="warning">
                REMARKS
              </Title>
 
                <Span margin="mt-2">Items: </Span>
                <p className="mb-2">
                  <Text strong className="capitalize">
                    {detailsData?.itemDescription
                      ? detailsData?.itemDescription
                      : <TableNoData />
                    }
                  </Text> 
                </p>

                <Span margin="mt-2">Notes to Driver:</Span>
                <p>
                  <Text strong className="capitalize">
                    {detailsData?.notes ?  detailsData?.notes : <TableNoData />}
                  </Text>
                </p>
            </Card>

            <div className="flex justify-end">
              <Button
                bg="white"
                color="grey"
                width="none" 
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
