import { MainContainer } from "@common";
import { Navbar, SideMenu } from "@components";
import { BRAND_IMAGES } from "@constant";
import { SetUp } from "@containers";

export const SetUpPage = () => {
  return (
    <>
      <Navbar title="Supplier" icon={BRAND_IMAGES["HEADER_LOGO"]} />
      <SideMenu />
      <MainContainer>
        <SetUp />
      </MainContainer>
    </>
  );
};
