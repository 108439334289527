import BRAND_LOGO from '@assets/AEG logo.png';
import HEADER_LOGO from '@assets/AEG logo white.png';
import DEFAULT_ICON from '@assets/Face.png';
import LINK_EXPIRED from '@assets/registered_invalid_bg.png'

export const BRAND_IMAGES = {
  BRAND_LOGO,
  HEADER_LOGO,
  DEFAULT_ICON
} 

export const LINK_EXPIRED_BG = LINK_EXPIRED 

export const SERVICES = [
  {
    name: "All Services",
    value: "",
  }, 
  {
    name: "Food",
    value: "food",
  },
  {
    name: "Shop",
    value: "mart",
  }, 
];


export const STORE = [
  {
    name: "All Store",
    value: "",
  },
  {
    name: "Coffee Project",
    value: "coffee_project",
  },
  {
    name: "Dear Joe",
    value: "dear_joe",
  }, 
];

export const EXPORT = [
  {
    name: "Export xls",
    value: "xls",
  },
  {
    name: "Export pdf",
    value: "pdf",
  }, 
];

export const DAYS = [
  {
    name: "Last 30 Days",
    value: 30,
  }, 
  {
    name: "Last 15 Days",
    value: 15,
  },
  {
    name: "Last 7 Days",
    value: 7,
  },  
  {
    name: "Custom",
    value: 0,
  },  
  
];

export const CHART = [
  {
    name: "Line Chart",
    value: "line_chart",
  }, 
  {
    name: "Bar Chart",
    value: "bar_chart",
  }, 
];

export const TOP_PRODUCT = [
  {
    name: "Unit Sold",
    value: "unit_sold",
  }, 
];



export const PAYMENT_METHOD = [
  {
    name: "All Method",
    value: "all_method",
  },  
  {
    name: "AllEasy",
    value: "alleasy",
  },  
  {
    name: "Credit Card",
    value: "credit_card",
  },  
  {
    name: "Points",
    value: "points",
  },  
];

export const ORDER_METHOD = [
  {
    name: "All Method",
    value: "all_method",
  },  
  {
    name: "Delivery",
    value: "delivery",
  }, 
  {
    name: "Pick-up",
    value: "pick_up",
  },   
];

export const ROLE = [
  {
    name: "Staff",
    value: "staff",
  }, 
  {
    name: "Admin",
    value: "admin",
  }, 
]; 

export const ORDER_METHOD_STATUS = {  
  picked_up: "Picked up", 
  delivery: "Delivery",  
}; 

export const PAYMENT_METHOD_STATUS = {  
  all_easy: "All Easy", 
  cash_on_delivery: "Cash",  
  credit_card: "Credit Card",  
  points: "Points",  
}; 

export const FILTER_STATUS = [
  {
    name: "New",
    value: "new",
  }, 
  {
    name: "Ready",
    value: "ready",
  },  
  {
    name: "For Pickup",
    value: "for_pickup",
  },  
  {
    name: "Ongoing Delivery",
    value: "ongoing_delivery",
  },  
  {
    name: "Delivered",
    value: "delivered",
  }, 
  {
    name: "Canceled",
    value: "canceled",
  },
];

export const STATUS = {
  new: "New",
  ready: "Ready",
  quoted: "Quoted",
  created: "Created",
  for_pickup: "For Pickup", 
  picked_up: "Picked Up",  
  on_going: "On going", //On Going 
  delivered: "Delivered",
  canceled: "Canceled"
};


export const CATEGORY = [
  {
    name: "Fruits",
    value: "fruits",
  }, 
  {
    name: "Appliance",
    value: "appliance",
  }, 
];

export const UOM = [
  {
    name: "Kg",
    value: "kg",
  }, 
];

export const ONGOING_STATUS = [
  "created",
  "assigned",
  "for_pickup",
  "picked_up",
  "on_going",
  "ongoing_delivery",
];

export const COMLETED_STATUS = [ 
  "canceled",
  "delivered",
  "completed",
];

export const STATUS_ITEM = [
  {
    name: "Active",
    value: "active",
  }, 
  {
    name: "Inactive",
    value: "inactive",
  }, 
];

export const ORDER_STATUS = {
  draft: "Draft", 
  pending: "Pending",
  accepted: "Accepted", 
  preparing: "Preparing",
  
  ready: "Ready",
  for_delivery: "For Delivery",
  in_transit: "In Transit",

  picked_up: "Picked Up",
  delivered: "Delivered",
  canceled: "Canceled",   
};


export const CHANGE_TO = {
  pending: "accepted",
  accepted: "preparing", 
  preparing: "ready",
  ready: "for_delivery",
  for_delivery: "in_transit",
  in_transit: "picked_up",
  picked_up: "delivered", 
};

export const COMPLETED_ORDERS = [
  "canceled",
  "delivered",
  "failed_delivery",
  "declined", 
  "completed",
];

export const CANTCANCEL = [
  "ready",
  "for_delivery",
  "in_transit",
  "picked_up",
  "canceled",
  "delivered",
  "failed_delivery",
  "declined", 
  "completed",
];