/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container, Span } from "@common";
import { COLUMNS } from "./Table/column";
import { useEffect, useMemo, useState } from "react";
import { NoData, PopupInfo, TableWithPagination } from "@components";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
} from "firebase/firestore";

import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "@store";

let SUPPLIER_UNSUBSCRIBE: any = null;
export const CustomerOrder = () => {
  const dispatch = useDispatch<Dispatch>();
  const columns = useMemo(() => COLUMNS, []);
  const user: any = useSelector((state: RootState) => state.User.userInfo);

  const [supplierData, setSupplierData] = useState([] as any);

  const MYSTORES = user?.storeAccesses.map((elem: any) => {
    const storeId = elem?.storeId;
    return storeId;
  });

  const db = getFirestore();
  const listenToSupplier = async () => {
    const q = query(collection(db, "store_supplier"));

    SUPPLIER_UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || []).map((ref) => {
        const data = ref.data();
        return data;
      }) as any[];
      setSupplierData(listData);
    });
  };

  useEffect(() => {
    listenToSupplier();

    return function () {
      try {
        if (SUPPLIER_UNSUBSCRIBE) SUPPLIER_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };
  }, []);

  useEffect(() => {
    dispatch.Supplier.resetState();
  });

  const customerOrder = supplierData
    ?.filter((i) => MYSTORES.includes(i?.storeId))
    ?.map((s) => {
      return s;
    });

  useEffect(() => {
    dispatch.Supplier.resetState();
  });

  return (
    <>
      <div className="flex">
        <Span color="grey">Order / </Span>
        <Span margin="mr-2">Customer Order</Span>
        <Span margin="mt-0.5">
          <PopupInfo info={"Orders received from customers"} />
        </Span>
      </div>

      <Container padding="p-4">
        {!customerOrder?.length ? (
          <NoData />
        ) : (
          <TableWithPagination
            columns={columns}
            data={customerOrder}
            tableClass="table-content"
          />
        )}
      </Container>
    </>
  );
};
