/* eslint-disable react-hooks/exhaustive-deps */
import { Button, ModalContainer, Span } from "@common";
import { Dispatch } from "@store";
import { Form, Select } from "antd";
import { useDispatch } from "react-redux";
import AddItemIcon from "@assets/add-items.png";
import { InputContainerNew } from "@components";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { RootState } from "@store";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { createSupplierOrder } from "@api";

const { Option } = Select;

type Props = {
  setCheckoutModal: Function;
};

let SUPPLIER_UNSUBSCRIBE: any = null;
export const CheckoutModal = ({ setCheckoutModal }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();

  const [supplierData, setSupplierData] = useState([] as any);
  const [customerStoreId, setCustomerStoreId] = useState();

  const user: any = useSelector((state: RootState) => state.User.userInfo);
  const cartItems: any = useSelector((state: RootState) => state.Cart.items);
  const storeId: any = useSelector(
    (state: RootState) => state.Supplier.storeId
  );

  const ORDERS_SUPPLIER = cartItems.map((elem: any) => {
    return {
      productId: elem?.id,
      quantity: elem?.quantities,
      amount: elem?.price,
    };
  });

  const handleOrder = async () => {
    try {
      const res = await createSupplierOrder({
        storeId: storeId,
        orders: ORDERS_SUPPLIER,
        customerStoreId: customerStoreId,
      });
      if (res) {
        dispatch.UI.setAlert({
          message: "Order created successfully!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "Unable to create order!",
        type: "Error",
      });
    }
    setCheckoutModal(false);
    dispatch.Cart.resetState();
    history.push(`/orders/purchase`);
  };

  const MYSTORES = user?.storeAccesses.map((elem: any) => {
    const storeId = elem?.storeId;
    return storeId;
  });

  const db = getFirestore();
  const listenToSupplier = async () => {
    const q = query(
      collection(db, "store_supplier"),
      where("supplierStatus", "==", "enabled")
    );

    SUPPLIER_UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || []).map((ref) => {
        const data = ref.data();
        return data;
      }) as any[];
      setSupplierData(listData);
    });
  };

  useEffect(() => {
    listenToSupplier();

    return function () {
      try {
        if (SUPPLIER_UNSUBSCRIBE) SUPPLIER_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };
  }, []);

  const data = supplierData
    ?.filter((i) => MYSTORES.includes(i?.storeId))
    ?.map((s) => {
      return s;
    });

  return (
    <>
      <ModalContainer>
        <div className="p-10 py-5 whitespace-normal text-center ">
          <div className="mx-auto">
            <img
              src={AddItemIcon}
              alt={AddItemIcon}
              className="mx-auto my-2 h-12"
            />
            <Span color="orange" size="base" weight="semi">
              Checkout
            </Span>
          </div>

          <InputContainerNew
            icon={false}
            label="Your Store"
            required={true}
            bg="transparent"
          >
            <Form.Item
              rules={[{ required: true, message: "Category is required" }]}
              className="w-full pt-2"
            >
              <Select
                className="w-full"
                placeholder="Select your Store"
                onChange={(v: any) => setCustomerStoreId(v)}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {data?.map((supplier, index) => (
                  <Option key={index} value={supplier.storeId}>
                    {supplier.storeName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </InputContainerNew>

          <div className="flex justify-end">
            <Button
              bg="white"
              color="grey"
              width="none"
              margin="mx-4"
              padding="none"
              onClick={() => setCheckoutModal(false)}
            >
              Cancel
            </Button>
            <Button
              bg="orange"
              width="none"
              padding="px-6"
              margin="mx-4"
              onClick={handleOrder}
              disabled={!customerStoreId}
            >
              Save
            </Button>
          </div>
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
