import { MainContainer } from "@common";
import { Navbar, SideMenu } from "@components";
import { BRAND_IMAGES } from "@constant";
import { Shopify } from "@containers";

import { useParams } from "react-router-dom";

export const ShopifyPage = () => {
  let params: any = useParams();

  return (
    <>
      <Navbar
        title="Ecommerce Integration"
        icon={BRAND_IMAGES["HEADER_LOGO"]}
      />
      <SideMenu />
      <MainContainer>
        <Shopify id={params.id} />
      </MainContainer>
    </>
  );
};
