/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container, Span } from "@common";
import { COLUMNS } from "./Table/column";
import { useEffect, useMemo, useState } from "react";
import { Loader, NoData, TableWithPagination } from "@components";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  query,
} from "firebase/firestore";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "@store";

export const SetUp = () => {
  const dispatch = useDispatch<Dispatch>();
  const columns = useMemo(() => COLUMNS, []);

  const [supplierData, setSupplierData] = useState([] as any);
  const user: any = useSelector((state: RootState) => state.User.userInfo);

  const [isLoading, setIsLoading] = useState(true);

  const db = getFirestore();
  const storeStatusCache = {};

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "store_supplier")),
      (snapshot) => {
        const updatedSupplierData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          storeId: doc.id,
        }));
        setSupplierData(updatedSupplierData);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching store supplier data:", error);
        setIsLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const myStore = useMemo(() => {
    return user?.storeAccesses.map((elem: any) => ({
      ...elem?.store,
      storeName: elem?.store?.name,
      storeId: elem?.store?.id,
      supplierStatus: "disabled",
    }));
  }, [user]);

  const mergedSupplierData = useMemo(() => {
    return myStore.map((store) => {
      const matchingStore = supplierData.find(
        (supplierStore) => supplierStore.storeId === store.storeId
      );
      if (matchingStore) {
        return {
          ...store,
          supplierStatus:
            storeStatusCache[store.storeId] || matchingStore.supplierStatus,
        };
      } else {
        return store;
      }
    });
  }, [myStore, supplierData]);

  useEffect(() => {
    mergedSupplierData.forEach(async (store) => {
      if (
        !storeStatusCache[store.storeId] &&
        store.supplierStatus === "disabled"
      ) {
        const status = await fetchStoreStatusFromFirestore(store.storeId);
        store.supplierStatus = status;
        storeStatusCache[store.storeId] = status;
      }
    });
  }, [mergedSupplierData]);

  useEffect(() => {
    dispatch.Cart.resetState();
    dispatch.Supplier.resetState();
  }, []);

  const fetchStoreStatusFromFirestore = async (storeId) => {
    try {
      const storeDocRef = doc(db, "store_supplier", storeId);
      const storeDocSnapshot = await getDoc(storeDocRef);
      if (storeDocSnapshot.exists()) {
        return storeDocSnapshot.data().supplierStatus;
      }
      return "disabled";
    } catch (error) {
      console.error("Error fetching store status from Firestore:", error);
      return "disabled";
    }
  };

  return (
    <>
      <div>
        <Span color="grey">Supplier / </Span>
        <Span>Set up</Span>
      </div>

      <Container padding="p-4">
        {!isLoading ? (
          <>
            {!mergedSupplierData?.length ? (
              <NoData />
            ) : (
              <TableWithPagination
                columns={columns}
                data={mergedSupplierData}
                tableClass="table-content"
              />
            )}
          </>
        ) : (
          <Loader />
        )}
      </Container>
    </>
  );
};
