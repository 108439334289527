export const GenerateStatusDesign = ({ status, label }: any) => {
  const renderDesign = () => {
    switch (status) {
      case "pending":
        return (
          <div className="bg-[#f6f6f6] border-[1px] border-[#808080] rounded-sm w-[100px] flex items-center justify-center">
            <span className="text-xs  text-[#615e5e] p-1">{label}</span>
          </div>
        );
      case "accepted":
        return (
          <div className="bg-[#e6f7ff] border-[1px] border-[#91d5ff] rounded-sm w-[100px] flex items-center justify-center">
            <span className="text-xs text-[#096dd9] p-1">{label}</span>
          </div>
        );
      case "preparing":
        return (
          <div className="bg-[#f0f5ff] border-[1px] border-[#adc6ff] rounded-sm w-[100px] flex items-center justify-center">
            <span className="text-xs text-[#1d39c4] p-1">{label}</span>
          </div>
        );
      case "ready":
        return (
          <div className="bg-[#ffd48630] border-[1px] border-[#ff6800] rounded-sm w-[100px] flex items-center justify-center">
            <span className="text-xs text-[#ff6800] p-1">{label}</span>
          </div>
        );

      case "in_transit":
        return (
          <div className="bg-[#e6f7ff] border-[1px] border-[#91d5ff] rounded-sm w-[100px] flex items-center justify-center">
            <span className="text-xs text-[#096dd9] p-1">{label}</span>
          </div>
        );
      case "for_delivery":
        return (
          <div className="bg-[#f6ffed] border-[1px] border-[#b7eb8f] rounded-sm w-[100px] flex items-center justify-center">
            <span className="text-xs text-[#389e0d] p-1">{label}</span>
          </div>
        );

      case "picked_up":
        return (
          <div className="bg-[#eee8ff] border-[1px] border-[#2700a3] rounded-sm w-[100px] flex items-center justify-center">
            <span className="text-xs text-[#2700a3] p-1">{label}</span>
          </div>
        );

      case "delivered":
        return (
          <div className="bg-[#f6ffed] border-[1px] border-[#b7eb8f] rounded-sm w-[100px] flex items-center justify-center">
            <span className="text-xs  text-[#389e0d] p-1">{label}</span>
          </div>
        );
      case "canceled":
        return (
          <div className="bg-[#fff1f0] border-[1px] border-[#ffa39e] rounded-sm w-[100px] flex items-center justify-center">
            <span className="text-xs  text-[#cf1322] p-1">{label}</span>
          </div>
        );
      default:
        return (
          <div className="border-[1px] border-[#b7eb8f] rounded-sm w-[100px] bg-[#f6ffed] flex items-center justify-center">
            <span className="text-xs text-[#389e0d] p-1">{label}</span>
          </div>
        );
    }
  };
  return renderDesign();
};
