/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Container, GridContainer, Span } from "@common";

import { useSelector } from "react-redux";
import { RootState } from "@store";
import { formatDateV2 } from "@utils";
import { useEffect, useState } from "react";
import {
  CANTCANCEL,
  CHANGE_TO,
  COMPLETED_ORDERS,
  ORDER_STATUS,
} from "@constant";
import {
  CancelOrderModal,
  ChangeStatusModal,
  GenerateStatusDesign,
  Loader,
} from "@components";
import { useHistory } from "react-router-dom";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
//   import { CancelPurchaseModal } from "./Modal/CancelPurchaseModal";

export const CustomerOrderByStore = ({ id }: any) => {
  const history = useHistory();
  const { data }: any = useSelector(
    (state: RootState) => state.Table.tables.customerOrders
  );

  const { storeName, storeId }: any = useSelector(
    (state: RootState) => state.Supplier
  );

  // const customerData = data?.filter((s) => s?.id === id);

  const [customerData, setCustomerData] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);

  const db = getFirestore();

  const getOrderData = async () => {
    try {
      setIsLoading(true);

      const q = query(
        collection(db, "store_supplier_order"),
        where("id", "==", id)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          setCustomerData(data);
        });
      });

      return () => unsubscribe();
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOrderData();
  }, [id]);

  const [cancelOrderModal, setCancelOrderModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);

  const handleBack = () => {
    history.push(`/orders/customer-order/${storeId}`);
  };

  return (
    <>
      {!isLoading ? (
        <>
          <div className="flex justify-between">
            <div>
              <Span weight="semi" transform="uppercase">
                Total Order: &nbsp;
              </Span>
              <Span weight="bold" color="orange" size="base">
                {`₱${parseFloat(customerData?.totalAmount)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}
              </Span>
            </div>
            <div className="flex items-center">
              <Span weight="semi" transform="uppercase">
                Status: &nbsp;
              </Span>
              <Span weight="bold" color="orange" size="base">
                <GenerateStatusDesign
                  status={customerData?.status}
                  label={ORDER_STATUS[customerData?.status]}
                />
              </Span>
            </div>
          </div>
          <Container padding="p-4">
            <GridContainer>
              <div className="mx-2">
                <div className="purchase-view-title">
                  <Span weight="semi" color="orange" transform="uppercase">
                    Order Details
                  </Span>
                </div>
                <div className="rounded-md">
                  <p className="mt-2">
                    <Span>Id:</Span>
                  </p>
                  <Span weight="semi" transform="capitalize">
                    {id}
                  </Span>

                  <p className="mt-2">
                    <Span>Order Placed:</Span>
                  </p>

                  <Span weight="semi">
                    {formatDateV2(customerData?.createdAt)}
                  </Span>

                  <p className="mt-2">
                    <Span>Store Name:</Span>
                  </p>
                  <Span weight="semi" transform="capitalize">
                    {customerData?.store?.name}
                  </Span>
                </div>
              </div>

              <div className="mx-2">
                <div className="purchase-view-title">
                  <Span weight="semi" color="orange" transform="uppercase">
                    Customer Details
                  </Span>
                </div>
                <p className="mt-2">
                  <Span>Contact Name:</Span>
                </p>
                <Span weight="semi" transform="capitalize">
                  {customerData?.customer?.firstName ?? ""}{" "}
                  {customerData?.customer?.middleName ?? ""}{" "}
                  {customerData?.customer?.lastName ?? ""}
                </Span>

                <p className="mt-2">
                  <Span>Email:</Span>
                </p>
                <Span weight="semi">
                  {customerData?.customer?.contact?.email ?? ""}{" "}
                </Span>

                <p className="mt-2">
                  <Span>Contact No:</Span>
                </p>
                <Span weight="semi">
                  {customerData?.customer?.contact?.contactNumber ?? ""}{" "}
                </Span>
              </div>
            </GridContainer>
          </Container>

          <div className="table-purchase my-4">
            <table>
              <thead>
                <tr>
                  <td> Product Name</td>
                  <td> Category</td>
                  <td> Price</td>
                  <td> Unit of Measurement</td>
                  <td> Quantity</td>
                  <td> Subtotal</td>
                </tr>
              </thead>

              <tbody>
                {customerData?.orderItem?.map((orderItem, key) => (
                  <tr className="card-orders">
                    <td>
                      <div className="flex items-end justify-center">
                        <p className="card-text">{orderItem?.product?.name}</p>
                      </div>
                    </td>

                    <td>
                      <div className="flex items-end justify-center">
                        <p className="card-text">{orderItem?.category?.name}</p>
                      </div>
                    </td>

                    <td>
                      <div className="flex items-end justify-center">
                        <p className="card-text">
                          <span className="text-center">
                            {`₱ ${parseFloat(orderItem?.product?.price)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}
                          </span>
                        </p>
                      </div>
                    </td>

                    <td>
                      <div className="flex items-end justify-center">
                        <p className="card-text">
                          {orderItem?.unitMeasurement?.name} (
                          {orderItem?.unitMeasurement?.abbreviation})
                        </p>
                      </div>
                    </td>

                    <td>
                      <div className="flex items-end justify-center">
                        <span className="text-center">
                          {orderItem?.product?.quantity}
                        </span>
                      </div>
                    </td>

                    <td>
                      <div className="flex items-end justify-center">
                        <p className="card-text">
                          <span className="text-center">
                            {`₱ ${parseFloat(orderItem?.product?.subTotal)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `}
                          </span>
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between">
            <Button
              bg="white"
              color="grey"
              width="none"
              margin="m-4"
              padding="px-10"
              onClick={handleBack}
              transform="uppercase"
            >
              BACK
            </Button>
            {!COMPLETED_ORDERS.includes(customerData?.status) && (
              <div>
                <Button
                  bg="orange"
                  width="none"
                  padding="px-10"
                  onClick={() => setChangeStatusModal(true)}
                >
                  Change Status to{" "}
                  <b>{ORDER_STATUS[CHANGE_TO[customerData?.status]]}</b>
                </Button>

                {!CANTCANCEL.includes(customerData?.status) && (
                  <Button
                    bg="white"
                    color="grey"
                    width="none"
                    margin="m-4"
                    padding="px-10"
                    onClick={() => setCancelOrderModal(true)}
                    transform="uppercase"
                  >
                    CANCEL DELIVERY
                  </Button>
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="flex h-screen justify-center">
          <Loader />
        </div>
      )}

      {cancelOrderModal && (
        <CancelOrderModal setCancelOrderModal={setCancelOrderModal} id={id} />
      )}
      {changeStatusModal && (
        <ChangeStatusModal
          setChangeStatusModal={setChangeStatusModal}
          id={id}
          purchaseStatus={CHANGE_TO[customerData?.status]}
          getOrderData={getOrderData}
        />
      )}
    </>
  );
};
