/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";
import { CgChevronLeft, CgChevronRight } from "@icons";
import { Button, Span } from "@common";

import "./Table.scss";
import { Checkbox } from "./Checkbox";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "@store";
import _ from "lodash";

type Props = {
  data: any;
  columns: any;
  header?: boolean;
  footer?: boolean;
};

export const RowSelection = ({ columns, data }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // @ts-ignore
    page,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    state,
    prepareRow,
    // @ts-ignore
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns): any => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }: any) => (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const { pageIndex, pageSize }: any = state;

  useEffect(() => {
    const MAX_SELECTED_ROWS = 5;
    if (selectedFlatRows && selectedFlatRows.length > MAX_SELECTED_ROWS) {
      const rows = selectedFlatRows.map((row) => row.original?.orderId);
      const rowsReady = _.toPlainObject(rows);
      dispatch.Ecommerce.updateState({
        orderId: rows,
        orderIdReady: rowsReady[0],
      });

      dispatch.UI.setAlert({
        message:
          "You can select a maximum of " + MAX_SELECTED_ROWS + " checkboxes.",
        type: "Success",
      });
    } else {
      const rows = selectedFlatRows.map((row) => row.original?.orderId);
      const rowsReady = _.toPlainObject(rows);
      dispatch.Ecommerce.updateState({
        orderId: rows,
        orderIdReady: rowsReady[0],
      });
    }
  }, [selectedFlatRows]);

  return (
    <>
      <div className="table-content">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="cursor-pointer">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination-team">
        <Button
          bg="white"
          color="black"
          margin="none"
          width="none"
          border="grey"
          padding="p-2"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <CgChevronLeft size={16} />
        </Button>
        <div className="my-auto mx-2 flex border border-grey-4 py-2 px-4 rounded-md">
          <Span margin="auto">Page</Span>
          <span className="gotoPage">{pageIndex + 1}</span>
          <Span margin="auto"> of {pageOptions.length}</Span>
        </div>
        <Button
          bg="white"
          margin="none"
          width="none"
          color="black"
          border="grey"
          padding="p-2"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <CgChevronRight size={16} />
        </Button>

        <div className="mx-2 flex border border-grey-4 py-2 px-4 rounded-md">
          <Span margin="auto">Rows per page:</Span>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="rowsperpage"
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};
