/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { RootState, Dispatch } from '@store';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionLeft(props: TransitionProps) {
  return <Slide {...props} direction="left" />;
}

export const AlertSnackBar = ({ open, setOpen }: any) => {

  const alert = useSelector((state: RootState) => state?.UI?.alert)
  const dispatch = useDispatch<Dispatch>();

  const alertType: any = () => {
    switch (alert.type) {
      case 'Error':
        return (
          <Alert severity="error" sx={{minWidth: 400}}>
            <AlertTitle>Error</AlertTitle>
            {alert?.message}
          </Alert>
        )
      case 'Info':
        return (
          <Alert severity="info" sx={{minWidth: 400}}>
            <AlertTitle>Info</AlertTitle>
            {alert?.message}
          </Alert>
        )
      case 'Success':
        return (
          <Alert severity="success" sx={{minWidth: 400}}>
            <AlertTitle>Success</AlertTitle>
            {alert?.message}
          </Alert>
        )
      case 'Warning':
        return (
          <Alert severity="warning" sx={{minWidth: 400}}>
            <AlertTitle>Warning</AlertTitle>
            {alert?.message}
          </Alert>
        )
      default:
        return ''
    }
  }

  const handleClose = () => {
    setOpen(false);
    dispatch.UI.resetAlert();
  }

  useEffect(() => {
    if (alert.message !== '') {
      setOpen(true);
      setTimeout(() => {
        handleClose();
      }, 5000);
    }
  }, [alert]);

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      TransitionComponent={TransitionLeft}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      ContentProps={{
        sx: {
          background: 'transparent',
          border: 'none',
          boxShadow: 'none'
        }
      }}
    >
      {alertType()}
    </Snackbar>
  ) 
};