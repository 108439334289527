
export const COLUMNS = [
    {
        accessor: 'id',
        Header: 'Id', 
        isVisible: true, 
    },
    {
        accessor: 'name',
        Header: 'Name', 
        isVisible: true,
        // Cell: data => {
        //     const d = data?.row?.original;
        //     return (
        //         <div className='flex justify-center'>
        //             <span className="text-center">{d.description}</span>
        //         </div>
        // )}
    },     
    {
        accessor: 'email',
        Header: 'Email', 
        isVisible: true, 
    },
    {
        accessor: 'mobile',
        Header: 'Mobile No.', 
        isVisible: true,
        maxWidth: 60,
        minWidth: 60,
        width: 60,
    },
    {
        accessor: 'role',
        Header: 'Role', 
        isVisible: true, 
    }, 
    {
        accessor: 'store_access',
        Header: 'Store Access', 
        isVisible: true, 
    },
]

export const HEADERS_TEAM = [
    { label: "Id", key: "id" },
    { label: "First Name", key: "firstName" },
    { label: "Middle Name", key: "middleName" },
    { label: "Last Name", key: "lastName" },
    { label: "Email", key: "email" }, 
    { label: "Mobile No.", key: "contactNumber" },
    { label: "Role", key: "role" }, 
];

