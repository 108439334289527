import { getStore } from "@api";
import { InputContainerNew } from "@components";
import { Form, Select } from "antd";
import { useCallback, useEffect, useState } from "react";

export const SelectStore = () => {
  const [shop, setShop] = useState([]) as any;

  const fetchShop = async () => {
    try {
      const store = await getStore();
      setShop(store);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchShop();
  }, []);

  const renderSelectStore = useCallback(() => {
    return (
      <InputContainerNew icon={false} label="Shop" required={true}>
        <Form.Item
          name="listChosenStores"
          className="w-full"
          rules={[{ required: true, message: "Store is required" }]}
        >
          <Select
            showSearch
            filterOption={(input, option: any) =>
              (option.children as any)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            placeholder="Select a store"
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            mode="multiple"
          >
            {shop.map(({ id, name }) => (
              <Select.Option value={id} key={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </InputContainerNew>
    );
  }, [shop]);

  return <>{renderSelectStore()}</>;
};
