import { Span, SubContainer } from "@common";
import { PRICE } from "@components";

type Props = {
  commission?: any;
};

export const Commission = ({ commission }: Props) => {
    return (<>
         <SubContainer  margin='auto'>
            <Span weight="bold" size="2xl" >  
              <PRICE price={commission} sign={true}/>
            </Span>
        </SubContainer>
    </>
  );
};