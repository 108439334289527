import { Button, ModalContainer, Span } from "@common";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch, RootState } from "@store";
import infoIcon from "@assets/info.png";
import { deleteItem } from "@api";

type Props = {
  setDeleteModals: Function;
  id: string;
};

export const DeleteModal = ({ setDeleteModals, id }: Props) => {
  const dispatch = useDispatch<Dispatch>();

  const storeId: any = useSelector(
    (state: RootState) => state.Supplier.storeId
  );

  const handleDelete = async () => {
    try {
      const res = await deleteItem(storeId, id);
      if (res) {
        dispatch.UI.setAlert({
          message: "Product deleted!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "Unable delete product!",
        type: "Error",
      });
    }
    setDeleteModals(false);
  };

  return (
    <>
      <ModalContainer bg="grey">
        <div className="px-8 py-5 whitespace-normal text-center ">
          <div className="mx-auto my-4">
            <img src={infoIcon} alt={infoIcon} className="mx-auto my-2" />
            <Span color="orange" size="xl" weight="semi">
              Delete Item
            </Span>
          </div>
          <Span size="base">Are you sure you want to delete this item?</Span>

          <div className="flex justify-center">
            <Button
              bg="white"
              color="grey"
              width="none"
              margin="m-4"
              padding="px-10"
              onClick={() => {
                setDeleteModals(false);
              }}
            >
              CANCEL
            </Button>
            <Button
              bg="red"
              width="none"
              padding="px-10"
              onClick={handleDelete}
            >
              DELETE
            </Button>
          </div>
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
