import { FaPen } from "@icons";
import { Container } from "@common";
import { Span } from "@common";

type Props = {
  children?: React.ReactNode;
  label: string;
  icon?: boolean;
  required?: boolean;
  bg?: any;
};
export const InputContainerNew = ({
  label,
  icon,
  required,
  children,
  bg,
}: Props) => {
  return (
    <Container padding="px-2" margin="none" bg={bg}>
      {required && (
        <Span color="red" size="sm">
          *
        </Span>
      )}
      <Span color="grey"> {label}</Span>
      <Container className="index" flex="wrap" margin="none" bg={bg}>
        {children}

        {icon && (
          <span className="pencil">
            <FaPen />
          </span>
        )}
      </Container>
    </Container>
  );
};
