/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Container, Span } from "@common";
import { COLUMNS, HEADER_CUSTOMER_ORDER } from "./Table/columnView";
import { useEffect, useMemo, useState } from "react";
import {
  CSVLinkContent,
  IconContainer,
  TableWithPagination,
} from "@components";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch, RootState } from "@store";
import { IoMdArrowRoundBack, IoMdDownload } from "@icons";
import moment from "moment";
import { formatDateV2 } from "@utils";
import { ORDER_STATUS } from "@constant";

let ORDER_UNSUBSCRIBE: any = null;
export const CustomerOrderView = ({ storeId }: any) => {
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  const columns = useMemo(() => COLUMNS, []);
  const now = moment();

  const { storeName }: any = useSelector((state: RootState) => state.Supplier);

  const [orderData, setOrderData] = useState([] as any);
  const [csvData, setCsvData] = useState([] as any);

  const db = getFirestore();
  const listenToOrder = async () => {
    const q = query(
      collection(db, "store_supplier_order"),
      where("storeId", "==", storeId)
    );

    ORDER_UNSUBSCRIBE = onSnapshot(q, (doc) => {
      const listData = (doc?.docs || []).map((ref) => {
        const data = ref.data();
        return data;
      }) as any[];
      setOrderData(listData);
    });
  };

  dispatch.Table.setTableData({ table: "customerOrders", data: orderData });

  useEffect(() => {
    listenToOrder();

    return function () {
      try {
        if (ORDER_UNSUBSCRIBE) ORDER_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };
  }, []);

  useEffect(() => {
    dispatch.Cart.resetState();
  });

  const handleBack = () => {
    history.push(`/orders/customer-order`);
  };

  useEffect(() => {
    const newData = orderData
      ?.sort((a, b) => a?.createdAt - b?.createdAt)
      .map((data) => {
        return {
          ...data,
          customerName: `${data?.customer?.firstName}  ${data?.customer?.middleName} ${data?.customer?.lastName}`,
          storeName: `${data?.store?.name}`,
          storeContact: `${data?.store?.contactName} (${data?.store?.contactNumber})`,
          created: `${formatDateV2(data?.createdAt)}`,
          statusOrder: `${ORDER_STATUS[data?.status]}`,
        };
      });
    setCsvData(newData);
  }, [orderData]);

  return (
    <>
      <div>
        <Span color="grey">Supplier / </Span>
        <Span>Customer Order</Span>
      </div>

      <Container padding="p-4">
        <div className="flex justify-between items-center">
          <div>
            <Button
              width="none"
              bg="orange"
              padding="none"
              margin="none"
              onClick={handleBack}
            >
              <div className="flex p-2 team-addMember">
                <IoMdArrowRoundBack className="my-auto text-semibold" />
                <Span margin="mx-1" color="white" weight="semi">
                  Back
                </Span>
              </div>
            </Button>
            <Span color="orange" size="lg" weight="bold" margin="mx-2">
              {storeName}
            </Span>
          </div>

          <div className="exportOption">
            <IconContainer
              icon={<IoMdDownload className="exportOption-icon text-white" />}
              bg="orange"
              color="white"
            >
              <CSVLinkContent
                data={csvData}
                headers={HEADER_CUSTOMER_ORDER}
                filename={`Customer-Order-${storeName}-${now.format(
                  "MM/DD/YYYY hh:ss"
                )}`}
              />
            </IconContainer>
          </div>
        </div>
        <TableWithPagination
          columns={columns}
          data={orderData}
          tableClass="table-content"
          dataType="customer-order-view"
        />
      </Container>
    </>
  );
};
