import { Span } from "@common";
import classnames from "classnames";

type Props = {
  children: React.ReactNode;
  icon?: any;
  bg?: "orange";
  color?: "white";
};

let getBg = (bg: Props["bg"]) => {
  switch (bg) {
    case "orange":
      return "bg-primary-main";
    default:
      return "bg-white";
  }
};

let getColor = (color: Props["color"]) => {
  switch (color) {
    case "white":
      return "text-white";
    default:
      return "text-black";
  }
};

export const IconContainer = ({ icon, children, bg, color }: Props) => {
  return (
    <div
      className={classnames(
        "my-auto relative flex p-2 rounded-md",
        getBg(bg),
        getColor(color)
      )}
    >
      <Span position="absolute" size="xl">
        {icon}
      </Span>
      {children}
    </div>
  );
};
