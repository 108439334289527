/* eslint-disable jsx-a11y/alt-text */ 
import sales from '@assets/icon/sales.png'
import hourly from '@assets/icon/hourly.png'
import team from '@assets/icon/team.png'
import profile from '@assets/icon/profile.png'
import ecomm from '@assets/icon/ecomm.png'
import suppliers from '@assets/icon/suppliers.png' 

// active 
import salesActive from '@assets/icon/sales-active.png'
import hourlyActive from '@assets/icon/hourly-active.png'
import teamActive from '@assets/icon/team-active.png'
import profileActive from '@assets/icon/profile-active.png'
import ecommActive from '@assets/icon/ecomm-active.png' 
import suppliersActive from '@assets/icon/suppliers-active.png' 

import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io"; 

export const MenuData = [
    {
        title: 'Sales Overview', 
        path: '/sales-overview',
        icon: <img src={sales}/>, 
        iconActive: <img src={salesActive}/>, 
    },
    {
        title: 'Hourly Data', 
        path: '/sales-report',
        icon: <img src={hourly}/>, 
        iconActive: <img src={hourlyActive}/>, 
    },
    {
        title: 'Team Members', 
        path: '/team',
        icon: <img src={team}/>, 
        iconActive: <img src={teamActive}/>, 
    },
    {
        title: 'Ecommerce', 
        path: '/ecommerce/integration',
        subName: 'ecommerce', 
        icon: <img src={ecomm}/>, 
        iconActive: <img src={ecommActive}/>, 
        iconClosed: <IoIosArrowForward/>, 
        iconOpened: <IoIosArrowDown/>,
        subNav: [
            {
                title: 'Integration', 
                path: '/ecommerce/integration', 
            },
            {
                title: 'Pickup Location', 
                path: '/ecommerce/pickup-location',    
            },  
            {
                title: 'Orders', 
                path: '/ecommerce/order?tab=new',    
            },  
        ]
    },
    {
        title: 'Supplier', 
        path: '/supplier/setup',
        subName: 'supplier', 
        icon: <img src={suppliers}/>, 
        iconActive: <img src={suppliersActive}/>, 
        iconClosed: <IoIosArrowForward/>, 
        iconOpened: <IoIosArrowDown/>,
        subNav: [
            {
                title: 'Setup', 
                path: '/supplier/setup', 
            }, 
            {
                title: 'Choose Supplier', 
                path: '/supplier/choose-supplier', 
            },   
        ]
    }, 
    {
        title: 'Order', 
        path: '/orders/purchase',
        subName: 'supplier', 
        icon: <img src={suppliers}/>, 
        iconActive: <img src={suppliersActive}/>, 
        iconClosed: <IoIosArrowForward/>, 
        iconOpened: <IoIosArrowDown/>,
        subNav: [
            {
                title: 'Purchase Order', 
                path: '/orders/purchase', 
            }, 
            {
                title: 'Customer Order', 
                path: '/orders/customer-order', 
            },   
        ]
    }, 
    {
        title: 'Profile', 
        path: '/profile',
        icon: <img src={profile}/>, 
        iconActive: <img src={profileActive}/>, 
    },
]